import { useTranslation } from 'react-i18next'
import { HiArrowUturnRight } from 'react-icons/hi2'

export default function UserStatus({ isActiveUser, onResend }) {
  const { t } = useTranslation()

  return (
    isActiveUser ? <p>{t('active')}</p>
      : <button type ="button" className = "flex items-center gap-1 text-secondary whitespace-nowrap" onClick = {onResend}>
        <HiArrowUturnRight size = {20}/>{t('resendActivation')}
      </button>
  )
}
