import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEn from '../lang/translation_en'
import translationEl from '../lang/translation_el'

const startLng = localStorage.getItem('lang')

const resources = {
  en: {
    translation: translationEn,
  },
  el: {
    translation: translationEl,
  },
}

i18n
  .use(initReactI18next)
  .init({ fallbackLng: 'en', resources, lng: startLng || 'en' })

export default i18n
