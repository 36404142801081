import { useTranslation } from 'react-i18next'
import cn from '../../utils/cn'

export default function MainButton({
  children,
  text,
  name,
  value,
  type = 'button',
  className,
  onClick,
  isCancel = false,
  isText = false,
  textColor = 'pale',
  isOutline = false,
  disabled = false,
}) {
  const { t } = useTranslation()
  const basicStyle = 'font-normal py-2 px-4 flex items-center justify-center whitespace-nowrap'
  const outline = `bg-transparent text-${textColor} border-primary border rounded-xl`
  const cancel = `bg-transparent text-base-300 ${isText ? 'border-0 underline' : 'border-base-300 rounded-xl border-2'
    // eslint-disable-next-line indent
    }`
  const normal = `disabled:bg-gray-solitude disabled:text-gray-light disabled:border-gray-solitude disabled:cursor-not-allowed rounded-xl border-2 bg-primary hover:bg-secondary text-${textColor} hover:text-pale border-primary hover:border-secondary`

  return (
    <button
      type={type}
      name={name}
      value={value}
      onClick={onClick}
      className={cn(
        {
          [outline]: isOutline,
          [cancel]: isCancel && !isOutline,
          [normal]: !isCancel && !isOutline,
        },
        basicStyle,
        className,
      )}
      disabled={disabled}
    >
      {children}
      {t(text)}
    </button>
  )
}
