import { useState } from 'react'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import InputErrorMessage from './InputErrorMessage'

export default function Input({
  type,
  label,
  placeholder,
  value,
  onChange,
  error,
  errorMessage,
  name,
  labelStyle = 'text-gray-light',
}) {
  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState)
  }
  const inputClass = 'bg-white border border-gray-border text-gray-light sm:text-sm rounded-xl w-full p-2.5'

  return (
    <div>
      <label
        htmlFor={type}
        className={`block mb-2 text-sm font-medium ${labelStyle}`}
      >
        {label}
      </label>
      {type !== 'password' && (
        <input
          type={type}
          name={name}
          id={name}
          className={inputClass}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      )}
      {type === 'password' && (
        <div className="relative">
          <input
            type={showPassword ? 'text' : 'password'}
            name={name}
            id="password"
            onChange={onChange}
            className={inputClass}
            placeholder={placeholder}
            value={value}
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 flex items-center text-lg right-2"
            aria-label="Toggle password visibility"
          >
            {showPassword ? (
              <BsEyeSlash className="mr-1 -scale-x-flip text-gray-light" />
            ) : (
              <BsEye className="mr-1 text-gray-light" />
            )}
          </button>
        </div>
      )}
      {!!error && <InputErrorMessage text={errorMessage} />}
    </div>
  )
}
