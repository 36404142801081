import axiosInstance from '../../utils/axios-instances'

export const getUsers = async () => {
  try {
    const response = await axiosInstance.get('auth/users/')
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const getUser = async (userId) => {
  try {
    const response = await axiosInstance.get(`auth/users/${userId}`)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const createUser = async (user) => {
  try {
    const response = await axiosInstance.post('auth/users/', user)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const deleteUser = async (userId) => {
  try {
    await axiosInstance.delete(`auth/users/${userId}`)
  } catch (error) {
    throw error.response.data
  }
}

export const resendUserActivationEmail = async (email) => {
  try {
    const res = await axiosInstance.post('auth/resend-activation/', {
      email,
    })
    return res.data
  } catch (error) {
    return error.response ? error.response.data : error
  }
}

export const resetPasswordEmail = async (email) => {
  try {
    await axiosInstance.post('auth/forgot-password/', {
      email,
    })
  } catch (error) {
    throw error.response.data
  }
}
