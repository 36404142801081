import axios from 'axios'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axiosInstance from '../../utils/axios-instances'
import { deleteReport } from '../../services/reports/reports-service'

let counter = 0
export default function SingleFileUploadWithProgress({ file, id, setFiles }) {
  // const [isPaused, setIsPaused] = useState(false)
  const [isCancelled, setIsCancelled] = useState(false)
  const [isRetry, setIsRetry] = useState(false)
  const [uploadedFileId, setUploadedFileId] = useState(null)
  const [progress, setProgress] = useState({
    started: false,
    uploaded: 0,
    total: 0,
  })
  const [uploadFailed, setUploadFailed] = useState(false)
  const [isUploadCompleted, setIsUploadCompleted] = useState(false)
  const queryClient = useQueryClient()
  const controller = useMemo(() => new AbortController(), [])

  function formatBytes(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 Byte'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return `${Math.round(100 * (bytes / 1024 ** i)) / 100} ${sizes[i]}`
  }

  // eslint-disable-next-line consistent-return
  const uploadFile = useCallback(async () => {
    const formData = new FormData()
    formData.append('title', 'test')
    formData.append('file', file)
    formData.append('patient', '89a0963f-593e-4b7c-a9db-d472142b8412')
    formData.append('conclusion', 'Low hemoglobin')

    const options = {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.event.lengthComputable) {
          const uploadedBytes = progressEvent.loaded
          const totalBytes = progressEvent.total
          const formatedData = {
            uploaded: uploadedBytes,
            total: totalBytes,
          }
          setProgress((prevState) => ({ ...prevState, ...formatedData }))
        }
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      signal: controller.signal,
    }

    setProgress((prevState) => ({ ...prevState, started: true }))

    try {
      const response = await axiosInstance
        .post('/diagnostic-report/', formData, options)
      return response.data
    } catch (error) {
      if (error.name === 'CanceledError') {
        controller.abort()
      } else {
        setUploadFailed(true)
        toast.error(error)
      }
    }
  }, [file, id, controller])

  const { mutate } = useMutation({
    mutationFn: uploadFile,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries('reports')
      setIsUploadCompleted(true)
      setUploadedFileId(data.id)
      setIsRetry(false)
    },
    onError: () => {
      setIsRetry(true)
    },
  })
  const { mutate: deleteFile } = useMutation({
    mutationFn: deleteReport,
    onSuccess: async () => {
      await queryClient.invalidateQueries('reports')
      setFiles((prevState) => prevState.filter((_, index) => index !== id))
      setUploadedFileId(null)
    },
  })

  const upload = useCallback(async () => {
    mutate(file, setProgress)
  }, [])

  useEffect(() => {
    if (counter === 0) {
      upload()
      counter += 1
    } else {
      counter = 0
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCancel = () => {
    controller.abort()
    setIsCancelled(true)
  }

  const handleDelete = () => {
    deleteFile(uploadedFileId)
  }

  return (
    <div className="mt-3">
      {progress.started && (
        <>
          <p className="text-lg text-black">{file.name}</p>
          <div className="flex items-center">
            <div className="w-full mr-8">
              <progress
                id="progressBar"
                value={progress.uploaded}
                max={progress.total}
              >
                progressbar
              </progress>
            </div>
            <div className="flex gap-4">
              {/* replace all with icons and fix styling. Add retry functionality */}
              {isRetry
                && <button
                  type="button"
                  onClick={() => upload()}
                  disabled={isUploadCompleted}
                >
                  Retry
                </button>
              }
              {isCancelled || isUploadCompleted || uploadFailed ? (
                <button type="button" onClick={handleDelete}>
                  Delete
                </button>
              ) : (
                <button type="button" onClick={handleCancel}>
                  Cancel
                </button>
              )}
            </div>
          </div>
          <div className="text-[16px] ml-1">
            <span className="text-secondary">
              {formatBytes(progress.uploaded)}
            </span>
            <span className="text-gray-light">
              {' '}
              / {formatBytes(progress.total)}
            </span>
          </div>
        </>
      )}
    </div>
  )
}
