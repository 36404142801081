import React from 'react'

export default function FileInput({
  id, onChange, value, accept,
}) {
  return (
    <input
      id={id}
      type="file"
      onChange={onChange}
      value={value}
      accept={accept}
      className="hidden"
    />
  )
}
