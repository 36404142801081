import {
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react'

const useToggle = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState)
  const ref = useRef(null)

  const checkClickOutside = useCallback((e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsOpen(false)
    }
  }, [ref])

  useEffect(() => {
    document.addEventListener('mousedown', checkClickOutside)
    return () => {
      document.removeEventListener('mousedown', checkClickOutside)
    }
  }, [checkClickOutside])

  return { isOpen, setIsOpen, ref }
}

export default useToggle
