/* eslint-disable import/prefer-default-export */
import { toast } from 'react-toastify'
import { isAxiosError } from 'axios'
import {
  createPatient,
  deletePatient,
  updatePatient,
} from '../../../../services/patients/patients-service'

const createPatientAction = async (query, request) => {
  const data = await request.formData()

  const newPatient = {
    patient_first_name: data.get('firstName'),
    patient_last_name: data.get('lastName'),
    patient_phone: data.get('phone'),
    patient_email: data.get('email'),
    patient_dob: data.get('dateOfBirth') ? data.get('dateOfBirth') : null,
    patient_gender: data.get('gender'),
  }

  try {
    await createPatient(newPatient)
    toast.success('Patient has been created')
    query.invalidateQueries('patients')
    return null
  } catch (error) {
    if (error && error.response && error.response.status === 500) {
      toast.error('500 Internal Server Error')
    } else if (isAxiosError(error)) {
      toast.error(error.message)
    } else {
      toast.error(error.detail || error.email[0])
    }
    return null
  }
}

const updatePatientAction = async (query, request) => {
  const data = await request.formData()
  const patientId = data.get('id')

  const updatedPatient = {
    patient_first_name: data.get('firstName'),
    patient_last_name: data.get('lastName'),
    patient_phone: data.get('phone'),
    patient_email: data.get('email'),
    patient_dob: data.get('dateOfBirth') ? data.get('dateOfBirth') : null,
    patient_gender: data.get('gender'),
  }

  try {
    await updatePatient(patientId, updatedPatient)
    toast.success('Patient has been updated')
    query.invalidateQueries('patients')
    return null
  } catch (error) {
    if (error && error.response && error.response.status === 500) {
      toast.error('500 Internal Server Error')
    } else if (isAxiosError(error)) {
      toast.error(error.message)
    } else {
      toast.error(error.detail || error.email[0])
    }
    return null
  }
}

const deletePatientAction = async (query, request) => {
  try {
    const data = await request.formData()
    const id = data.get('patientId')
    await deletePatient(id)
    toast.success('Patient has been deleted')
    query.invalidateQueries('patients')
    return { response: { status: 'ok', path: '.' } }
  } catch (error) {
    if (error && error.response && error.response.status === 500) {
      toast.error('500 Internal Server Error')
    } else if (isAxiosError(error)) {
      toast.error(error.message)
    } else {
      toast.error(error.detail || error.email[0])
    }
    return null
  }
}

export const patientActions = (query) => async ({ request }) => {
  const method = request.method.toUpperCase()

  if (method === 'POST') {
    return createPatientAction(query, request)
  } if (method === 'PATCH') {
    return updatePatientAction(query, request)
  }
  if (method === 'DELETE') {
    return deletePatientAction(query, request)
  }
  toast.error('Unsupported request method')
  return null
}
