import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Form, useSubmit } from 'react-router-dom'
import MainButton from '../UI/MainButton'
import LoginInput from './Input'
import validationSchema from '../../utils/validation-schema'

export default function CreateCaseForm({ onClose }) {
  const [t] = useTranslation()

  const submit = useSubmit()

  const formik = useFormik({
    initialValues: {
      caseName: '',
      caseStatus: '',
      patient: '',
      caseDate: '',
    },
    validationSchema: validationSchema.createCase,
    onSubmit: async (values, actions) => {
      submit(values, { method: 'post' })
      actions.resetForm()
      onClose()
    },
  })

  return (
    <Form onSubmit={formik.handleSubmit} method="post" className="flex flex-col justify-between h-full">
      <div className="grid gap-4 md:grid-cols-2">
        <LoginInput
          type="text"
          name="caseName"
          label={t('caseName')}
          placeholder=""
          value={formik.values.caseName}
          onChange={formik.handleChange}
          error={!!(formik.touched.caseName && formik.errors.caseName)}
          errorMessage={formik.errors.caseName}
        />
        <LoginInput
          type="text"
          name="caseStatus"
          label={t('caseStatus')}
          placeholder=""
          value={formik.values.caseStatus}
          onChange={formik.handleChange}
          error={!!(formik.touched.caseStatus && formik.errors.caseStatus)}
          errorMessage={formik.errors.caseStatus}
        />
        <LoginInput
          type="date"
          name="caseDate"
          label={t('caseDate')}
          placeholder=""
          value={formik.values.caseDate}
          onChange={formik.handleChange}
          error={!!(formik.touched.caseDate && formik.errors.caseDate)}
          errorMessage={formik.errors.caseDate}
        />
        <LoginInput
          type="text"
          name="patient"
          label={t('patient')}
          placeholder=""
          value={formik.values.patient}
          onChange={formik.handleChange}
          error={!!(formik.touched.patient && formik.errors.patient)}
          errorMessage={formik.errors.patient}
        />
      </div>
      <div className="flex flex-col justify-end w-full gap-4 mt-9 md:flex-row">
        <MainButton
          text={t('cancel')}
          type="button"
          onClick={onClose}
          className="rounded-xl"
          isCancel
        />
        <MainButton text={t('create')} type="submit" name="intent" value="create" className="w-full md:max-w-[270px] rounded-xl" />
      </div>
    </Form>
  )
}
