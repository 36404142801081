import { Navigate, Outlet } from 'react-router-dom'
import userUtils from '../utils/users-utils'
import roles from './roles'

const RoleBaseAccessControl = ({ allowedRoles }) => {
  const authUser = userUtils.getUser()
  const isAllowed = allowedRoles.some((role) => role.includes(roles[authUser.role]))

  return isAllowed ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" />
  )
}

export default RoleBaseAccessControl
