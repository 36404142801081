import React from 'react'
import cn from '../../utils/cn'

export default function PlusButton({ onClick, className, label = '+' }) {
  return (
    <button
      type="button"
      className={
        cn('rounded-[4px] bg-primary text-xl text-white text w-6 h-6 flex items-center text-center', className)
      }
      onClick={onClick}
    >
      <p>{label}</p>
    </button>
  )
}
