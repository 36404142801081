import InputErrorMessage from './InputErrorMessage'

export default function SelectInput({
  name,
  hasLabel = true,
  label,
  onChange,
  value,
  children,
  error,
  errorMessage,
  setUserType,
  labelStyle = 'text-gray-light',
}) {
  return (
    <div className="w-full form-control">
      {hasLabel && (
        <label className={`mb-2 text-sm font-medium lock ${labelStyle}`}>
          <span className="label-text" />
          {label}
        </label>
      )}
      <select
        name={name}
        id={name}
        onChange={(e) => {
          onChange(e)
          if (setUserType) {
            setUserType(e.target.value)
          }
        }}
        value={value}
        className="block w-full p-3 bg-white border border-gray-border text-gray-light sm:text-sm rounded-xl focus:ring-primary focus:border-primary"
      >
        {children}
      </select>
      {!!error && <InputErrorMessage text={errorMessage} />}
    </div>
  )
}
