import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LuUserX } from 'react-icons/lu'
import { useNavigation } from 'react-router-dom'
import { HiDotsVertical } from 'react-icons/hi'
import { MdEdit } from 'react-icons/md'
import { FaTrash } from 'react-icons/fa'
import { RiRotateLockFill } from 'react-icons/ri'
import Table from '../../Table/Table'
import CreateUserForm from '../../Forms/CreateUserForm'
import ModalActionContent from '../../Modals/ModalActionContent'
import ModalWrapper from '../../Modals/ModalWrapper'
import Badge from '../../UI/Badge'
import UserStatus from './UserStatus'
import TableDropdownButtons from '../../Table/TableDropdownButtons'
import TableDropdownButton from '../../Table/TableDropdownButton'
import ToggleMenuProvider from '../../UI/ToggleMenuProvider'

export default function UsersTable({
  type = 'minimal',
  users,
  title,
  rounded,
}) {
  // !refactor modals states if necessary
  const [t] = useTranslation()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
  const [userId, setUserId] = useState(null)
  const [userEmail, setUserEmail] = useState(null)
  const [fieldName, setFieldName] = useState(null)
  const navigation = useNavigation()
  const isSubmitting = navigation.state === 'submitting'

  //! to fix: attached ref to the div with buttons doesn't work properly it close dropdown menu with buttons

  const handleDropdownMenu = (id, setIsOpen) => {
    setIsOpen()
    setUserId(id)
  }
  const deleteUserHandler = (id) => {
    setUserId(id)
    setShowDeleteModal(true)
  }

  const resendEmailHandler = (email, inputName) => {
    setUserEmail(email)
    setFieldName(inputName)
    setShowResetPasswordModal(true)
  }

  const resetPasswordHandler = (email, inputName) => {
    setUserEmail(email)
    setFieldName(inputName)
    setShowResetPasswordModal(true)
  }

  useEffect(() => {
    if (isSubmitting) {
      setShowDeleteModal(false)
    }
  }, [isSubmitting])

  const columns = [
    {
      Header: t('id'),
      accessor: 'id',
    },
    {
      Header: t('firstName'),
      accessor: 'first_name',
    },
    {
      Header: t('lastName'),
      accessor: 'last_name',
    },
    {
      Header: t('email'),
      accessor: 'email',
    },
    {
      Header: t('role'),
      Cell: ({ row: { original } }) => (
        <Badge text={original.role} />
      ),
    },
    {
      Header: t('status'),
      Cell: ({ row: { original } }) => <UserStatus isActiveUser={original.is_active} onResend={() => {
        resendEmailHandler(original.email, 'resend')
      }} />,
    },
    {
      Header: ' ',
      Cell: ({ row: { original } }) => (
        <ToggleMenuProvider className="relative flex items-center justify-end">
          {({ isOpen, setIsOpen }) => <>
            <button type="button" onClick={() => {
              setIsOpen(true)
            }} className="pl-3">
              <HiDotsVertical size={30} />
            </button>
            <TableDropdownButtons isOpen={isOpen}>
              <TableDropdownButton
                buttonText="edit"
                icon={<MdEdit size={21} className="text-gray-light shrink-0" />}
                onClick={() => {
                }}
              />
              <TableDropdownButton
                buttonText="resetPassword"
                disabled={!original.is_active}
                icon={<RiRotateLockFill size={21} className="text-yellow-500 shrink-0" />}
                onClick={() => {
                  resetPasswordHandler(original.email, 'sendResetPassword')
                }}
              />
              <TableDropdownButton
                buttonText="delete"
                onClick={() => deleteUserHandler(original.id)}
                icon={<FaTrash size={16} className="ml-1 text-red-500 shrink-0" />}
              />
            </TableDropdownButtons>
          </>}

        </ToggleMenuProvider>
      ),
    },
  ]

  return (
    <>
      <Table
        type={type}
        title={t(title)}
        columns={columns}
        data={users}
        pathKey="maintainerUsers"
        rounded={rounded}
        rowsPerPage={10}
        screen="CasesPage"
        hasAddButton
        buttonLabel="addNewUser"
        setShowModal={setShowCreateModal}
      />
      {showCreateModal && (
        <ModalWrapper onCloseModal={() => setShowCreateModal(false)} type="close" title="createNewUser">
          <div className="p-6">
            <CreateUserForm onClose={() => setShowCreateModal(false)} />
          </div>
        </ModalWrapper>
      )}
      {showDeleteModal && (
        <ModalWrapper onCloseModal={() => {
          setShowDeleteModal(false)
          setUserId(null)
        }} type="close">
          <div className="px-6 py-[3rem]">
            <ModalActionContent title="deleteAccount"
              description="areYouSureYouWantDeleteUser"
              buttonClassName="bg-red-500 border-red-500 hover:bg-red-500/90 md:max-w-[400px] w-full"
              buttonLabel="yesDeleteUser"
              buttonType="submit"
              buttonAction="delete"
              fieldName="userId"
              id={userId}
              icon={<LuUserX size={50} />}
              closeModalHandler={() => {
                setShowDeleteModal(false)
                setUserId(null)
              }} />
          </div>
        </ModalWrapper>
      )}
      {showResetPasswordModal && (
        <ModalWrapper onCloseModal={() => {
          setShowResetPasswordModal(false)
          setUserId(null)
          setUserEmail(null)
          setFieldName(null)
        }} type="close">
          <div className="px-6 py-[3rem]">
            <ModalActionContent title="activation"
              description="resendAccountActivationEmail"
              buttonLabel="sendMail"
              buttonType="submit"
              buttonAction="post"
              fieldName={fieldName ?? 'userId'}
              id={userEmail ?? userId}
              icon={<RiRotateLockFill size={60} />}
              closeModalHandler={() => {
                setShowResetPasswordModal(false)
                setUserId(null)
                setUserEmail(null)
                setFieldName(null)
              }} />
          </div>
        </ModalWrapper>
      )}
    </>
  )
}
