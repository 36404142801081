import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import illustrationSplashScreen from '../../assets/images/illustration_splash_screen.png'
import oncoscreen from '../../assets/images/oncoscreen-logo.png'

export default function SplashScreen() {
  const navigate = useNavigate()
  const [t] = useTranslation()

  return (
    <main className="flex bg-pale font">
      <div className="px-10 xl:px-[100px] 2xl:px-40 pt-28 xl:pt-[221px] flex flex-col gap-8 md:gap-16">
        <h2 className="text-defaultText font-[500] [font-size:_clamp(3rem,3.33vw,64px)]">
          {t('welcomeTo')}
        </h2>
        <div className="z-10">
          <img
            className="w-[430px] ratio-[1] -ml-3"
            src={oncoscreen}
            alt="oncoscreen logo"
          />
          <h1 className="font-[500] [font-size:_clamp(2rem,2.5vw,48px)]">
            {t('Clinical Decision Support System (cDSS)')}
          </h1>
        </div>
        <h3 className="z-10 text-lg md:text-2xl max-w-[600px] 2xl:max-w-[700px]">
          {t(
            'An AI-assisted DSS oriented to facilitate the work of medical experts and clinicians during their day-to-day activities when screening and treating citizens and patients for CRC.',
          )}
        </h3>
        <button
          type="button"
          className="w-full md:max-w-[300px] xl:max-w-[400px] mb-2.5 px-5 py-2.5
          bg-primary hover:bg-secondary focus:outline-none rounded-xl font-medium text-xl text-pale text-center disabled:bg-primary/80"
          onClick={() => navigate('/login')}
        >
          {t('getStarted')}
        </button>
      </div>
      <div className="hidden lg:block absolute aspect-[1.1] lg:w-96 xl:w-[510px] h-[540px] bg-[#F2DBE2] rounded-bl-5xl top-0 right-0" />
      <img
        alt="doctors"
        src={illustrationSplashScreen}
        className="pr-5 absolute w-[250px] md:w-[380px] lg:w-[760px] lg:h-[590px] aspect-[1.2] lg:bottom-0 right-0 z-0"
      />
    </main>
  )
}
