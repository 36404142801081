import React from 'react'
import cn from '../../utils/cn'

export default function StyledCheckbox({
  name, id, checked, onChange,
}) {
  return (
    <div className="relative" >
      <input
        name={name}
        id={id}
        type="checkbox"
        className="sr-only"
        checked={checked}
        onChange={onChange}
      />
      {/* Custom checkbox */}
      <div className={cn('w-6 h-6 border-2 rounded-lg transition duration-200 ease-in-out', {
        'bg-transparent border-gray-light': !checked,
        'bg-chantilly border-chantilly': checked,
      })} />
      {/* Custom checkmark */}
      <div className="absolute left-0.5 top-0.5 w-6 h-6 transition duration-200 ease-in-out">
        <svg
          className={cn('w-5 h-5 text-secondary pointer-events-none', {
            hidden: !checked,
          })}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      </div>
    </div >
  )
}
