import Preparation from './Preparation'
import Findings from './Findings'
import colonoscopyData from '../../dummy_data/ColonoscopyData'
import Procedure from './Procedure'

export default function Colonoscopy() {
  return (
    <div className="px-8 py-10 md:grid md:grid-cols-2 md:gap-8">
      <div className="col-span-2 pb-10 border-b md:mb-0 border-gray-border-2">
        <Preparation preparationDetails={colonoscopyData.preparationDetails} />
      </div>
      <div className="py-7 md:pr-10 md:py-0 md:row-start-2 md:col:col-start-1 md:border-r md:border-gray-border-2">
        <Procedure procedureDetails={colonoscopyData.procedureDetails} />
      </div>
      <div className="md:row-start-2 md:col-start-2">
        <Findings findings={colonoscopyData.findings} />
      </div>
    </div>
  )
}
