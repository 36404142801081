import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import CasesTable from '../../../components/CasesTable'
import Notifications from '../../../components/Notifications'
import { ReactComponent as DoctorsImg } from '../../../assets/images/doctors-pic.svg'
import userUtils from '../../../utils/users-utils'
import tokenUtils from '../../../utils/token-utils'
import axiosInstance from '../../../utils/axios-instances'

export default function MedicalStaffHomePage() {
  const [t] = useTranslation()
  const [casesData, setCasesData] = useState([])
  const authUser = userUtils.getUser()
  const welcomeMessage = `${authUser?.role === 'Doctor' ? t('Dr.') : ''} ${authUser?.first_name} ${authUser?.last_name}`
  const token = tokenUtils.getAccessTokenFromSession()

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axiosInstance.get('/cases/cases/')
        setCasesData([])
      } catch (error) {
        console.log('Error Fetching Data', error)
      }
    }

    fetchData()
  }, [token])

  return (
    <div className="flex flex-col mx-10 my-10 xl:gap-x-20 xl:flex-row gap-y-10 xl:gap-y-0">
      <div className="xl:ml-[88px] xl:py-20">
        <div className="xl:grid xl:grid-cols-2">
          <div className="mt-[3%] mb-8 col-span-1">
            <h2 className="font-[500px] text-center xl:text-left text-5xl xl:max-w-[270px] capitalize">
              {t('welcome')}{' '}
              <span className="whitespace-nowrap">{welcomeMessage}</span>
            </h2>
          </div>
          <div className="mb-10 xl:mb-[-6px] px-1 flex justify-center xl:justify-start xl:row-start-2 xl:col-span-2">
            <p className="text-xl xl:text-lg whitespace-nowrap">
              {t('welcomeBack')}
            </p>
            <div className="hidden xl:block">
              <DoctorsImg />
            </div>
          </div>
        </div>
        <Notifications />
      </div>
      <CasesTable casesData={casesData} title="yourCases" type="main" hasSearch={false} />
    </div>
  )
}
