/* eslint-disable no-underscore-dangle */
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { toast } from 'react-toastify'
import tokenUtils from './token-utils'

const apiUrl = process.env.REACT_APP_BASE_URL

const hasTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token)
    if (decoded && decoded.exp) {
      const currentTime = Date.now() / 1000
      if (decoded.exp < currentTime) {
        return true
      }
      return false
    }
    return true
  } catch (error) {
    console.error('Expiration', error)
    return true
  }
}

const axiosInstance = axios.create({
  baseURL: apiUrl,
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = tokenUtils.getAccessTokenFromSession()
    if (token) {
      const modifiedConfig = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      }
      return modifiedConfig
    }
    return config
  },
  (error) => Promise.reject(error),
)

export const responseInterceptor = ({
  logout,
  toastMessage,
}) => {
  axiosInstance.interceptors.response.use((response) => response, async (error) => {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const refreshToken = tokenUtils.getRefreshTokenFromSession()
      const isExpired = hasTokenExpired(refreshToken)
      if (isExpired) {
        try {
          const res = await axios.post(`${apiUrl}/jwt/refresh/`, { refreshToken })
          const { token } = res.data
          tokenUtils.refreshToken(token)
          originalRequest.headers.Authorization = `Bearer ${token}`
          return axiosInstance(originalRequest)
        } catch (tokenError) {
          console.error(tokenError)
        }
      } else {
        toast.warning(toastMessage)
        logout()
      }
    }
    return Promise.reject(error)
  })
}

export default axiosInstance
