import React from 'react'
import { useTranslation } from 'react-i18next'
import SectionTitle from '../UI/SectionTitle'
import BasicCard from '../Cards/BasicCard'
import Dot from '../UI/Dot'

export default function FirstResults({ size, earlyDiagnostic }) {
  const [t] = useTranslation()

  const tests = [
    {
      name: 'ONCO-VOC',
      result: earlyDiagnostic.Outcome,
      percent: earlyDiagnostic.Confidence,
    },
    { name: 'ONCO-NMR', result: true },
    { name: 'ONCO-CRISPR', result: true },
    { name: 'ONCO-CTC', result: false },
  ]

  return (
    <div className="flex flex-col items-center">
      <SectionTitle
        text="firstResults"
        size="xl"
        marginBottom="mb-4"
        styles={`${size} self-center`}
      />
      <div
        className="grid grid-cols-2 justify-center self-center gap-4 w-full"
      >
        {tests.map((obj) => (
          <BasicCard name={obj.name} key={obj.name} styles="min-h-[100px]">
            <div className="flex justify-center items-center min-h-[50px]">
              <div className="flex items-center justify-between gap-4">
                <Dot
                  bgColor={obj.result ? 'bg-positive' : 'bg-negative'}
                  shadow={obj.result ? 'shadow-positive' : 'shadow-negative'}
                />
                <p>
                  {obj.result ? t('positive') : t('negative')} {obj.percent}
                </p>
              </div>
            </div>
          </BasicCard>
        ))}
      </div>
    </div>
  )
}
