const colonoscopyData = {
/* eslint-disable */
  preparationDetails:
    "Prior to the procedure, a History and Physical was performed, and patient medications and allergies were reviewed. The patient's tolerance of previous anesthesia was also reviewed. The risks and benefits of the procedure and the sedation options and risks were discussed with the patient. All questions were answered, and informed consent was obtained. Prior Anticoagulants: The patient has taken no previous anticoagulant or antiplatelet agents. ASA Grade Assessment: I - A normal, healthy patient. After reviewing the risks and benefits, the patient was deemed in satisfactory condition to undergo the procedure.",
  procedureDetails: [
    {
      detail:
        'The colonoscope was introduced through the anus and advanced to the cecum, identified by appendiceal orifice and ileocecal valve.',
    },
    { detail: 'The colonoscopy was performed without difficulty.' },
    { detail: 'The patient tolerated the procedure well.' },
    {
      detail:
        'The quality of the bowel preparation was evaluated using the BBPS (Boston Bowel Preparation Scale) with scores of: Right Colon = 3, Transverse Colon = 3 and Left Colon = 3 (entire mucosa seen well with no residual staining, small fragments of stool or opaque liquid). The total BBPS score equals 9.',
    },
  ],
  findings: [
    {
      finding:
        'A 5 mm polyp was found in the rectum (benign-appearing lesion). The polyp was semi-pedunculated. The polyp was removed with a cold biopsy forceps. Resection and retrieval were complete. Estimated blood loss: None.',
    },
    {
      finding:
        'A 9 mm polyp was found in the sigmoid colon. The polyp was pedunculated. The polyp was removed with a saline injection-lift technique using a cold snare. Resection and retrieval were complete. Estimated blood loss: 1 ml.',
    },
    {
      finding:
        'Multiple large-mouthed diverticula were found in the descending colon.',
    },
    {
      finding:
        'Non-bleeding prolapsed internal hemorrhoids were found during retroflexion. The hemorrhoids were Grade II (internal hemorrhoids that prolapse but reduce spontaneously).',
    },
    { finding: 'The exam was otherwise normal throughout the examined colon.' },
  ],
/* eslint-enable */
}

export default colonoscopyData
