import React from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { CgLogOut } from 'react-icons/cg'
import useLogout from '../../hooks/useLogout'

export default function Drawer({ menuItems, setIsOpen }) {
  const [t] = useTranslation()
  const { logout } = useLogout()

  const renderMenu = () => menuItems.map((obj) => (
    <li key={obj.title} className="group-item mb-7">
      <NavLink
        to={obj.route}
        className="p-0 bg-transparent active:text-secondary"
        end
      >
        {({ isActive }) => (
          <div
            className={`${isActive
              ? 'text-secondary'
              : 'text-neutral-content md:text-neutral'
              // eslint-disable-next-line indent
              }`}
          >
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={obj.icon} size="sm" />
              {t(obj.title)}
            </div>
            <div
              className={`absolute top-10 w-full h-[8px] ${isActive ? '' : ''
                // eslint-disable-next-line indent
                }`}
            />
          </div>
        )}
      </NavLink>
    </li>
  ))

  return (
    <>
      <div className="fixed top-0 right-0 z-50 md:h-[100vh] w-full md:w-60 p-4 bg-neutral md:bg-gray-solitude md:bg-opacity-1 md:rounded-l-3xl">
        <div className="flex justify-end text-neutral-content md:text-neutral">
          <button className="text-xl" onClick={() => setIsOpen(false)}>
            ✕
          </button>
        </div>
        <ul className="items-center mt-10 text-xl menu text-primary-content md:mt-15 md:ml-10 md:items-start">
          {renderMenu()}
        </ul>
        <button type="button" className="flex items-center gap-2 mx-auto text-xl text-white md:text-defaultText md:ml-10 group-item mb-7" onClick={logout}>
          <CgLogOut size={25} />
          Logout
        </button>
      </div>
      <div className="bg-neutral bg-opacity-50 absolute min-w-full min-h-[100vh] md:min-h-[calc(100vh-64px)]" />
    </>
  )
}
