export const riskFactors = {
  gender: {
    value: 'Male',
  },
  age: {
    value: '55',
    selected: false,
  },
  bmi: {
    value: '35',
    selected: false,
  },
  geneticFactors: false,
  activityLevel: {
    value: 'Sedentary',
    selected: false,
  },
  smokingStatus: {
    value: 'Non smoker',
    selected: false,
  },
  alcoholConsumption: {
    value: 'Low',
    selected: false,
  },
}

export const clusteringApproach = {
  riskLevel: {
    value: '>4',
    selected: false,
  },
  confidence: {
    value: '>85%',
    selected: false,
  },
}

export const semiEmpiricalApproach = {
  riskLevel: {
    value: '>4',
    selected: false,
  },
  confidence: {
    value: '>85%',
    selected: false,
  },
}

export const firstResults = {
  oncoVOC: {
    value: true,
    selected: false,
  },
  oncoNMR: {
    value: true,
    selected: false,
  },
  oncoCRISPR: {
    value: false,
    selected: false,
  },
  oncoCTC: {
    value: false,
    selected: false,
  },
}
