export default function TableButton({ children, onClick }) {
  return (
    <button
      type="button"
      className="h-10 transition-all duration-200 w-7 hover:opacity-50"
      onClick={onClick}
    >
      {children}
    </button>
  )
}
