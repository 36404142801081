import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import Submenu from '../../../components/UI/Submenu'
import WhiteBox from '../../../components/UI/WhiteBox'
import navMenu from '../../../constants/nav-menu'
import LabTechnicianPatientCard from '../../../components/Cards/LabTechnicianPatientCard'
import ShowExcelFileButton from '../../../components/UI/ShowExcelFileButton'
import { patientsLab } from './LabTechnicianPatientsPage'

export default function LabTechnicianSinglePatientPage() {
  const { id } = useParams()
  const patient = patientsLab.find((patientLab) => patientLab.id === id)
  return (
    <div className="px-8 my-10">
      <div className="mb-10">
        <LabTechnicianPatientCard
          patientName={`${patient.name} ${patient.surname}`}
          id={id}
          dateCreated={patient.dateCreated}
          shadow
        />
      </div>
      <div className="relative">
        <Submenu menuItems={navMenu.labTechnicianPatientRoutes} />
        <ShowExcelFileButton />
      </div>
      <div>
        <WhiteBox roundness="rounded-b-3xl">
          <Outlet />
        </WhiteBox>
      </div>
    </div>
  )
}
