import { toast } from 'react-toastify'
import { deleteReport } from '../services/reports/reports-service'

// eslint-disable-next-line import/prefer-default-export
export const deleteAction = (queryClient) => async ({ request }) => {
  try {
    const data = await request.formData()
    const id = data.get('reportId')
    console.log(id)
    await deleteReport(id)
    toast.success('Report has been deleted')
    queryClient.invalidateQueries('reports')
    return { response: { status: 'ok', path: '.' } }
  } catch (error) {
    toast.error('Failed to delete report')
    return null
  }
}
