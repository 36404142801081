import { useTable, useGlobalFilter } from 'react-table'
import { useTranslation } from 'react-i18next'
import WhiteBox from '../UI/WhiteBox'
import RenderIf from '../UI/RenderIf'
import NoEntriesFoundMessage from '../UI/NoEntriesFoundMessage'
import cn from '../../utils/cn'

export default function LabResultsTable({
  minHeight = null,
  maxHeight = 'max-h-[550px]',
  columns,
  data,
}) {
  const [t] = useTranslation()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
  )

  return (
    <WhiteBox width="w-full 2xl:w-1/2" hasShadow={false}>
      <div className="border border-gray-solitude rounded-3xl py-6 h-[550px]">
        <div
          className={`overflow-auto min-[1100px]:overflow-x-visible print:overflow-visible mr-4 h-full
          ${minHeight ? ` ${minHeight}` : ''}
          ${maxHeight ? ` ${maxHeight}` : ''}`}
        >
          <table
            {...getTableProps()}
            className={cn('w-full border-collapse mx-6 py-2 z-1 print:min-w-[400px] print:max-w-[1100px] text-[#959292]')}
            style={{ maxWidth: 'calc(100% - 64px)' }}
          >
            <thead className="sticky top-0 bg-white">
              {headerGroups.map((headerGroup, idx) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      key={index}
                      style={{ width: 'auto' }}
                      {...column.getHeaderProps()}
                      className={`
                        px-2 text-left capitalize text-[#4D4D4D] text-md font-semibold border-b border-b-gray-line pb-5 whitespace-nowrap `}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row)
                return (
                  <tr
                    key={rowIndex}
                    className="px-8 transition-colors duration-200 hover:bg-[rgba(0,0,0,0.05)] border-b border-b-gray-line last:border-b-0 cursor-pointer text-sm"
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell, idx) => (
                      <td
                        key={idx}
                        {...cell.getCellProps()}
                        className="px-2 h-[50px] text-black"
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
          <RenderIf isTrue={rows.length === 0}>
            <div
              className="flex items-center justify-center h-[75%] py-8 lg:py-0"
            >
              <NoEntriesFoundMessage text="noEntriesFound" />
            </div>
          </RenderIf>
        </div>
      </div>
    </WhiteBox>
  )
}
