import React from 'react'
import InputErrorMessage from './InputErrorMessage'

export default function TextInput({
  name,
  hasLabel = true,
  label,
  type = 'text',
  placeholder,
  onChange,
  value,
  disabled = false,
  error,
  errorMessage,
}) {
  return (
    <div className="w-full max-w-xs form-control">
      {hasLabel && (
        <label className="px-1 py-1 text-xs font-semibold">
          <span className="label-text" />
          {label}
        </label>
      )}
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={disabled}
        className="py-1 pl-1 bg-transparent border-b-2 rounded-sm outline-none border-primary focus:border-b-2 disabled:text-gray-dark"
      />
      {!!error && <InputErrorMessage text={errorMessage} />}
    </div>
  )
}
