import Indicator from './Indicator'

export default function ThresholdIndicatorGraph({ value, lowThreshold = 0, highThreshold }) {
  const max = lowThreshold !== 0 ? lowThreshold + highThreshold : 1.5 * highThreshold
  const lowWidth = `${(lowThreshold / max) * 100}%`
  const normalWidth = `${(highThreshold / max) * 100}%`
  const valuePointerPosition = value > max ? '100%' : `${(value / max) * 100}%`

  return (
    <div className="w-full max-w-md mx-auto">
      <div className="relative h-6">
        {lowThreshold !== 0 && <div
          className="z-20 absolute bottom-0 left-0 h-full bg-goldenrod rounded-l-full overflow-hidden"
          style={{ width: lowWidth }}
        >
        </div>}
        <div
          className="z-10 absolute bottom-0 left-0 h-full bg-green-tea rounded-l-full overflow-hidden"
          style={{ width: normalWidth }}
        />
        <div
          className="w-full absolute bottom-0 left-0 h-full bg-red-vivid rounded-full overflow-hidden"
        />
        <Indicator position={lowWidth} value={lowThreshold} />
        <Indicator position={valuePointerPosition} value={value} isValueIndicator={true} />
        <Indicator position={normalWidth} value={highThreshold} />
      </div>
    </div>
  )
}
