import { useTranslation } from 'react-i18next'
import { RxDownload } from 'react-icons/rx'

export default function UploadFileButton({ onClick }) {
  const { t } = useTranslation()
  return <button type="button" onClick={onClick} className="text-lg text-secondary flex items-center justify-center gap-2 whitespace-nowrap">
    <RxDownload className="rotate-180" size={18} />
    {t('uploadFile')}
  </button>
}
