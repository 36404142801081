import { BiSolidEditAlt } from 'react-icons/bi'
import { FaTrash } from 'react-icons/fa'
import { IoEye } from 'react-icons/io5'
import TableButton from './TableButton'

export default function TableButtonGroup({ onView, onDelete, onEdit }) {
  return (
    <div className="flex justify-end gap-1 ml-auto">
      {onView
        && <TableButton onClick={onView}>
          <IoEye size={20} title="View" className="text-gray-light" />
        </TableButton>
      }
      {onEdit && (
        <TableButton onClick={onEdit}>
          <BiSolidEditAlt size={18} title="Edit" />
        </TableButton>
      )}
      {onDelete && (
        <TableButton onClick={onDelete}>
          <FaTrash size={16} className="text-red-500" title="Delete" />
        </TableButton>
      )}
    </div>
  )
}
