import { useTranslation } from 'react-i18next'

export default function InputErrorMessage({ text }) {
  const [t] = useTranslation()

  return (
    <p className="mt-[2px] ml-1 text-xs text-error md:whitespace-nowrap">
      {t(text)}
    </p>
  )
}
