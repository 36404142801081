import { useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Input from './Input'
import SelectInput from './SelectInput'
import MainButton from '../UI/MainButton'

export default function EditReportForm({ initialValues, closeModal }) {
  const [t] = useTranslation()
  const handleSubmit = (values) => {
    closeModal()
  }

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => handleSubmit(values),
  })

  const labelStyle = 'text-cyan-dark font-medium text-lg'
  const optionStyle = 'text-gray-light text-base'
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-2 gap-8">
        <div className="col-span-1">
          <Input
            type="default"
            label={t('fileName')}
            name="fileName"
            value={formik.values.fileName}
            onChange={formik.handleChange}
            error={!!formik.touched.fileName && formik.errors.fileName}
            errorMessage={formik.errors.fileName}
            labelStyle={labelStyle}
          />
        </div>
        <div className="col-span-1">
          <SelectInput
            name="category"
            label={t('category')}
            value={formik.values.cetegory}
            onChange={formik.handleChange}
            error={!!formik.touched.fileName && formik.errors.category}
            errorMessage={formik.errors.category}
            labelStyle={labelStyle}
          >
            <option className={optionStyle} value={null}>{t('selectValue')}</option>
            <option className={optionStyle} value="biopsy">{t('biopsy')}</option>
            <option className={optionStyle} value="colonoscopy">{t('colonoscopy')}</option>
            <option className={optionStyle} value="fit_test">{t('fitTest')}</option>
            <option className={optionStyle} value="imaging">{t('imaging')}</option>
            <option className={optionStyle} value="other">{t('other')}</option>
          </SelectInput>
        </div>
      </div>
      <div className="flex flex-col justify-end w-full gap-4 mt-9 md:flex-row">
        <MainButton
          text={t('cancel')}
          type="button"
          onClick={closeModal}
          className="rounded-xl"
          isCancel
        />
        <MainButton text={t('submit')} type="submit" className="w-full md:max-w-[200px] rounded-xl" />
      </div>
    </form>

  )
}
