import cn from '../../utils/cn'

export default function WhiteBox({
  children,
  width = 'w-full',
  otherStyle = '',
  roundness = 'rounded-3xl',
  hasShadow = true,
}) {
  return (
    <div
      className={cn(`block bg-white overflow-y-visible ${roundness} ${width}
         ${otherStyle}`, {
        'shadow-card p-0 m-0': hasShadow,
      })}
    >
      {children}
    </div>
  )
}
