import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Table from '../Table/Table'
import casesData from '../../dummy_data/CasesData'

export default function CasesUsedTable() {
  const [t] = useTranslation()
  const data = useMemo(() => casesData, [])

  const columns = useMemo(() => [
    {
      Header: t('caseName'),
      accessor: 'caseName',
    },
    {
      Header: t('id'),
      accessor: 'id',
    },
    {
      Header: t('dateCreated'),
      accessor: 'startDate',
    },
  ], [])

  return (
    <div className="pl-5">
      <Table
        title="casesUsed"
        type="default"
        minHeight="xl:min-h-[610px] 2xl:min-h-[580px] xl:h-[610px] 2xl:h-[580px]"
        columns={columns}
        data={data}
        hasPagination={false}
        hasTitleInsideWhiteBox={false}
      />
    </div>
  )
}
