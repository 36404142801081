/* eslint-disable indent */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styles from '../../css/components/UI/Submenu.module.css'

export default function Submenu({ menuItems }) {
  const [t] = useTranslation()

  const tab = 'px-10 py-4 whitespace-nowrap block'

  return (
    <div
      className={`${styles.menuScrollbar} ${styles.flowOver} overflow-auto mt-16 md:mt-0 bg-base-100 border-b-4 border-secondary`}
    >
      <ul className="flex text-xl bg-pink-rose rounded-t-3xl w-fit">
        {menuItems.map((obj) => (
          <li key={obj.title}>
            <NavLink
              className={({ isActive }) => `${tab} ${isActive
                ? 'text-pale bg-secondary rounded-t-3xl'
                : 'text-gray-light hover:text-gray-dark'
                }`
              }
              to={obj.route}
            >
              {t(obj.title)}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}
