import { useTranslation } from 'react-i18next'
import cn from '../../utils/cn'

export default function Badge({ text, className }) {
  const { t } = useTranslation()

  return (
   <span className = {cn(
     'px-4 bg-[#D14A762E] py-[5px] rounded-2xl whitespace-nowrap',
     { 'bg-[#804AD12E]': text === 'UserMaintainer' },
     { 'bg-[#E4D46573]': text === 'LabTechnician' },
     { 'bg-[#A5E9EE]': text === 'DoctorManager' },
     className,
   )}>{t(text)}</span>
  )
}
