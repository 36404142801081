import userUtils from '../../utils/users-utils'
import axiosInstance from '../../utils/axios-instances'

const getUserProfile = async () => {
  try {
    const response1 = await axiosInstance.get('auth/users/me/')
    const response2 = await axiosInstance.get(`profiles/${response1.data.id}`)
    const userData = { ...response1.data, ...response2.data }
    userUtils.saveUser(userData)
    return userData
  } catch (error) {
    throw error.response.data
  }
}

export default getUserProfile
