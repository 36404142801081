import { useTranslation } from 'react-i18next'
import cn from '../../utils/cn'

export default function HomePageCard({
  title, description, children, onClick, titleClassName,
}) {
  const { t } = useTranslation()
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      className="py-14 cursor-pointer items-center justify-center px-8 rounded-3xl flex flex-col shadow-card w-[264px] border border-transparent hover:shadow-lightPink hover:border-pink-dark transition-colors duration-200"
      onClick={onClick}
    >
      {children}
      <p className={cn('mt-8 text-xl text-center', titleClassName)}>{t(title)}</p>
      <p className="mt-2 text-center text-gray-500">{t(description)}</p>
    </div>
  )
}
