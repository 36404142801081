import React from 'react'
import cn from '../../utils/cn'

export default function Indicator({ position, value, isValueIndicator = false }) {
  return (
    <div
      className="absolute top-0 w-0 h-full"
      style={{ left: position }}
    >
      <div className={cn('relative h-full w-0', {
        'z-30': !isValueIndicator,
        'z-40': isValueIndicator,
      })}>
        <div className={cn('absolute left-1/2 w-0 h-full transform -translate-x-1/2', {
          'border-[3px] rounded-full h-[51px] border-russianBlack -top-3': isValueIndicator,
          'border border-dashed border-russianBlack h-10 -top-2': !isValueIndicator,
        })} />
        {!isValueIndicator && <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 text-center whitespace-nowrap">
          <span className="text-sm">{value.toFixed(1)}</span>
        </div>}
      </div>
    </div>
  )
}
