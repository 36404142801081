import React from 'react'
import RulesTable from '../../../components/Doctor/RulesTable'

export default function RulesPage() {
  const rules = [
    {
      id: 1,
      ruleName: 'Family History of Colorectal Cancer',
      createdBy: 'John Smith',
      dateCreated: '2022-01-01T00:00:00.000Z',
      status: true,
    },
    {
      id: 2,
      ruleName: 'History of Ulcerative Colitis',
      createdBy: 'Jane Doe',
      dateCreated: '2022-01-02T00:00:00.000Z',
      status: true,
    },
    {
      id: 3,
      ruleName: "History of Crohn's Disease",
      createdBy: 'Bob Johnson',
      dateCreated: '2022-01-03T00:00:00.000Z',
      status: true,
    },
    {
      id: 4,
      ruleName: 'Age above 50',
      createdBy: 'Maria Rodriguez',
      dateCreated: '2022-01-04T00:00:00.000Z',
      status: true,
    },
    {
      id: 5,
      ruleName: 'Family History of Familial Adenomatous Polyposis (FAP)',
      createdBy: 'David Lee',
      dateCreated: '2022-01-05T00:00:00.000Z',
      status: true,
    },
    {
      id: 6,
      ruleName: 'History of Inflammatory Bowel Disease (IBD)',
      createdBy: 'Emily Chen',
      dateCreated: '2022-01-06T00:00:00.000Z',
      status: false,
    },
  ]

  return (
    <div className="h-full p-5 sm:p-12">
      <RulesTable rules={rules} />
    </div>
  )
}
