import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import LabResultsTable from '../Table/LabResultsTable'
import cn from '../../utils/cn'
import liporpoteinData from '../../dummy_data/LipoproteinData'
import ShowGraphButton from '../UI/ShowGraphButton'
import MeasurementAnalytics from './MeasurementAnalytics'

export default function Lipoproteins() {
  const [t] = useTranslation()
  const [displayedMeasurement, setDisplayedMeasurement] = useState({})

  const columns = [
    {
      Header: t('parameter'),
      accessor: 'parameter',
      Cell: ({ row }) => <div className="flex w-[150px]"><span className="">{row.original.parameter}</span></div>,

    },
    {
      Header: t('result'),
      accessor: 'value',
      Cell: ({ row }) => <div className={cn('flex w-[100px]', { '': row.original.isNormal, 'font-semibold': row.original.isNormal === false })}><span>{row.original.value} {row.original.unit}</span></div>,
    },
    {
      Header: t('rangeOfModel95'),
      Cell: ({ row }) => <div className="w-[100px]"><span>{row.original.lowThreshold}-{row.original.highThreshold} {row.original.unit}</span></div>,
    },
    {
      Header: '',
      id: 'showGraph',
      Cell: ({ row }) => <div><ShowGraphButton onClick={() => setDisplayedMeasurement(row.original)} /></div>,
    },
  ]
  const data = useMemo(() => liporpoteinData, [])

  return (
    <div className="flex gap-8 p-6">
      <LabResultsTable columns={columns} data={data} />
      <MeasurementAnalytics data={displayedMeasurement} />
    </div>
  )
}
