import { BsTable } from 'react-icons/bs'
import { RiUserAddFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import oncoscreenLogo from '../../../assets/images/infinite-colon-logo.png'
import userUtils from '../../../utils/users-utils'
import CreateUserForm from '../../../components/Forms/CreateUserForm'
import CustomModal from '../../../components/Modals/ModalWrapper'
import HomePageCard from '../../../components/Cards/HomePageCard'

export default function MaintainerHomePage() {
  const authUser = userUtils.getUser()
  const greetings = 'Welcome back to your workplace. What would you like to do?'
  const pickupOptions = 'Pick up one of the following actions.'
  const fullName = `${authUser.first_name} ${authUser.last_name}`

  const [showModal, setShowModal] = useState(false)

  const modalHandler = () => {
    setShowModal(true)
  }

  const onCloseModal = () => {
    setShowModal(false)
  }

  return (
   <>
    <div className = "flex flex-col items-center gap-5 px-10 mt-7">
    <img
     src={oncoscreenLogo}
     alt="Oncoscreen logo"
     width={200}
     height= {150}
     />
    <h1 className = "text-[60px] text-center font-semibold text-defaultText">Welcome {fullName}</h1>
    <h2 className = "text-2xl text-center text-defaultText">{greetings}</h2>
    <h3 className = "text-xl text-center text-gray-500">{pickupOptions}</h3>
    <div className = "flex flex-col gap-8 mt-10 mb-10 md:flex-row">
    <Link to = {'users'}>
      <HomePageCard title = "userList" description = "viewAndEditExistingUsers"><BsTable size = {50} className = "text-secondary"/></HomePageCard>
    </Link>
    <button onClick = {modalHandler} type = "button">
     <HomePageCard title = "addNew" description = "createNewUser"><RiUserAddFill size = {50} className = "text-secondary" onClick = {modalHandler}/></HomePageCard>
    </button>
    </div>
     {showModal && (
       <CustomModal onCloseModal={onCloseModal} type = "close" title = "Create new user">
        <div className = "p-6"><CreateUserForm onClose={() => setShowModal(false)}/></div>
       </CustomModal>
     )}
   </div>
  </>
  )
}
