import { useTranslation } from 'react-i18next'

export default function TableDropdownButton({
  buttonText, icon, onClick, disabled,
}) {
  const { t } = useTranslation()

  return (
   <button type ="button" disabled={disabled} onClick = {onClick} className = "[&:not(:last-child)]:border-b grid grid-cols-[20px,1fr] z-[30] items-center justify-items-start px-4 pt-2 pb-2 text-gray-light border-b-[#F5F5F5] hover:bg-gray-100 transition-all duration-300 whitespace-nowrap gap-3 disabled:opacity-50">
    {icon}
    {t(buttonText)}
   </button>
  )
}
