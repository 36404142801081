import { getUsers, getUser } from '../../../../services/users/users-service'

export const usersQuery = () => ({
  queryKey: ['users'],
  queryFn: () => getUsers(),
})

export const userQuery = (id) => ({
  queryKey: ['user', id],
  queryFn: () => getUser(id),
})
