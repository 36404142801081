import React from 'react'
import FinalResult from './FinalResult'

export default function Finals() {
  return (
    <div className="px-8 pt-10 py-6 md:grid md:grid-cols-7 md:gap-8 xl:border-r xl:border-gray-border-2">
      <div className="col-span-3 pr-8 border-r border-r-gray-light">
        <FinalResult />
      </div>
    </div>

  )
}
