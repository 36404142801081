import useToggle from '../../hooks/useToggle'

export default function ToggleMenuProvider({ children, className }) {
  const { ref, isOpen, setIsOpen } = useToggle()

  return (
    <div ref={ref} className={className}>
      {children({ isOpen, setIsOpen })}
    </div>
  )
}
