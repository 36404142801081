import React from 'react'

export default function FormCheckbox({ text, value, onChange }) {
  return (
    <div className="form-control">
      <label htmlFor="checkbox" className="label cursor-pointer">
        <span className="label-text mr-2 text-base font-semibold">{text}</span>
        <input
          id="checkbox"
          type="checkbox"
          checked={value}
          className="checkbox border-2 border-base-300"
          onChange={onChange}
        />
      </label>
    </div>
  )
}
