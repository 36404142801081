const casesData = [
  {
    id: 'CCS001',
    caseName: 'Screening Case 001',
    patientName: 'John Doe',
    dateCreated: '2024-08-01',
    status: 'open',
  },
  {
    id: 'CCS002',
    caseName: 'Screening Case 002',
    patientName: 'Jane Smith',
    dateCreated: '2024-08-02',
    status: 'pending',
  },
  {
    id: 'CCS003',
    caseName: 'Screening Case 003',
    patientName: 'Emily Johnson',
    dateCreated: '2024-08-03',
    status: 'closed',
  },
  {
    id: 'CCS004',
    caseName: 'Screening Case 004',
    patientName: 'Michael Brown',
    dateCreated: '2024-08-04',
    status: 'open',
  },
  {
    id: 'CCS005',
    caseName: 'Screening Case 005',
    patientName: 'Linda Davis',
    dateCreated: '2024-08-05',
    status: 'pending',
  },
]

export default casesData
