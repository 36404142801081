import { LuClipboardEdit } from 'react-icons/lu'
import React, { useState } from 'react'
import userUtils from '../../../utils/users-utils'
import oncoscreenLogo from '../../../assets/images/infinite-colon-logo.png'
import HomePageCard from '../../../components/Cards/HomePageCard'
import IconPulseClipboard from '../../../components/UI/IconPulseClipboard'
import ModalUploadFile from '../../../components/Modals/ModalUploadFile'
import IconOncoCtc from '../../../components/UI/IconOncoCTC'

export default function LabTechnicianHomePage() {
  const authUser = userUtils.getUser()
  const greetings = 'Welcome back to your workplace. What would you like to do?'
  const pickupOptions = 'Pick up one of the following actions.'
  const fullName = `${authUser.first_name} ${authUser.last_name}`
  const [showUploadFileModal, setShowUploadFileModal] = useState(false)
  const [uploadDetails, setUploadDetails] = useState(null)

  const onCloseUploadFileModal = () => {
    setShowUploadFileModal(false)
    setUploadDetails(null)
  }

  const onUploadFile = (fileType) => {
    setUploadDetails({ fileType })
    setShowUploadFileModal(true)
  }

  return (
    <>
      {showUploadFileModal && <ModalUploadFile hasPatients uploadDetails={uploadDetails} onClose={onCloseUploadFileModal} />}

      <div className="flex flex-col items-center gap-5 px-10 mt-7">
        <img
          src={oncoscreenLogo}
          alt="Oncoscreen logo"
          width={185}
          height={150} />
        <h1 className="text-[60px] text-center font-semibold text-defaultText">Welcome {fullName}</h1>
        <h2 className="text-2xl text-center text-defaultText">{greetings}</h2>
        <h3 className="text-xl text-center text-gray-500">{pickupOptions}</h3>
        <div className="flex flex-col gap-8 mt-10 mb-10 md:flex-row">
          <button type="button" onClick={() => onUploadFile('oncoNmr')}>
            <HomePageCard title="oncoNmr" description="addNewFile">
              <IconPulseClipboard />
            </HomePageCard>
          </button>
          <button type="button" onClick={() => onUploadFile('oncoCtc')}>
            <HomePageCard title="oncoCtc" description="addNewFile" titleClassName="mt-2">
              <IconOncoCtc />
            </HomePageCard>
          </button>
        </div>
      </div>
    </>
  )
}
