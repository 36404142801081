import React from 'react'

export default function UnauthorizedPage() {
  return (
   <div className="flex justify-center mb-10">
    <div className="text-center mt-[10%] mb-10 w-1/2">
      <p className="mb-8 text-7xl">Unauthorized</p>
      <p className="text-2xl">Sorry, you don't have access to this page</p>
    </div>
  </div>
  )
}
