import { useLoaderData, Await } from 'react-router-dom'
import { Suspense } from 'react'
import useQueryData from '../../hooks/useQueryData'
import { casesQuery } from '../../pages/private_pages/doctor_manager_pages/queries/cases-queries'
import CasesDoctorManagerTable from './CasesDoctorManagerTable'

const dummyCases = [
  {
    id: 'CCS001',
    caseName: 'Screening Case 001',
    doctorName: 'Dr. Anderson',
    patientName: 'John Doe',
    dateCreated: '2024-08-01',
  },
  {
    id: 'CCS002',
    caseName: 'Screening Case 002',
    doctorName: 'Dr. Baker',
    patientName: 'Jane Smith',
    dateCreated: '2024-08-02',
  },
  {
    id: 'CCS003',
    caseName: 'Screening Case 003',
    doctorName: 'Dr. Carter',
    patientName: 'Emily Johnson',
    dateCreated: '2024-08-03',
  },
  {
    id: 'CCS004',
    caseName: 'Screening Case 004',
    doctorName: 'Dr. Davis',
    patientName: 'Michael Brown',
    dateCreated: '2024-08-04',
  },
  {
    id: 'CCS005',
    caseName: 'Screening Case 005',
    doctorName: 'Dr. Evans',
    patientName: 'Linda Davis',
    dateCreated: '2024-08-05',
  },
  {
    id: 'CCS006',
    caseName: 'Screening Case 006',
    doctorName: 'Dr. Foster',
    patientName: 'Robert Wilson',
    dateCreated: '2024-08-06',
  },
  {
    id: 'CCS007',
    caseName: 'Screening Case 007',
    doctorName: 'Dr. Gray',
    patientName: 'Susan Taylor',
    dateCreated: '2024-08-07',
  },
  {
    id: 'CCS008',
    caseName: 'Screening Case 008',
    doctorName: 'Dr. Hill',
    patientName: 'James Anderson',
    dateCreated: '2024-08-08',
  },
  {
    id: 'CCS009',
    caseName: 'Screening Case 009',
    doctorName: 'Dr. James',
    patientName: 'Jessica Thomas',
    dateCreated: '2024-08-09',
  },
  {
    id: 'CCS010',
    caseName: 'Screening Case 010',
    doctorName: 'Dr. Kelly',
    patientName: 'William Martinez',
    dateCreated: '2024-08-10',
  },
]

export default function CasesLoader() {
  // const { cases } = useLoaderData()
  // const { data: casesData } = useQueryData({ initialData: cases, query: casesQuery })

  return (
  // <Suspense fallback={<p>Loading...</p>}>
  //   <Await
  //     resolve={cases}
  //     errorElement= {<p>Failed load data</p>}
  //   >
  //   </Await>
  // </Suspense>
        <CasesDoctorManagerTable cases = {dummyCases} title = "allCases"/>
  )
}
