import { redirect } from 'react-router-dom'
import tokenUtils from '../../../utils/token-utils'

export default function checkAuthLoader() {
  const token = tokenUtils.getAccessTokenFromSession()

  if (!token) {
    return redirect('/')
  }
  return null
}
