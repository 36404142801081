import React from 'react'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import moment from 'moment'
import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { toast } from 'react-toastify'
import SpinnerTwo from '../UI/SpinnerTwo'
import ModalWrapper from './ModalWrapper'
import '@cyntler/react-doc-viewer/dist/index.css'
import {
  deleteReport,
  getDocDetails,
  getDocFile,
} from '../../services/reports/reports-service'
import MainButton from '../UI/MainButton'

const ModalDocumentViewer = ({ report, onCloseModal }) => {
  const queryClient = useQueryClient()
  const docId = report?.media?.[0]?.link?.reference?.split('/').at(-1)

  const { mutate: deleteDoc, isPending: isDeleting } = useMutation({
    mutationFn: () => deleteReport(report.id),
    onSuccess: async () => {
      toast.success('Report has been deleted')
      onCloseModal()
      queryClient.invalidateQueries('reports')
    },
    onError: () => {
      toast.error('Failed to delete report')
    },
  })

  const { data: docDetails, isLoading: isDocDetailsLoading } = useQuery({
    queryKey: ['document-details', docId],
    queryFn: () => getDocDetails(docId),
    enabled: !!docId && !isDeleting,
  })

  const { data: document, isLoading: isDocumentLoading } = useQuery({
    queryKey: ['document', docId],
    queryFn: () => getDocFile(docId),
    enabled: !!docId && !isDeleting,
  })

  const pdfBlob = new Blob([document], { type: 'pdf' })

  return (
    <ModalWrapper
      onCloseModal={onCloseModal}
      title="Report"
      classNameWrapper="max-w-[90%] w-full"
      classNameContent="h-[90vh] min-w-full sm:min-w-full md:min-w-full lg:min-w-full modal-middle"
    >
      {isDocumentLoading || isDocDetailsLoading ? (
        <SpinnerTwo />
      ) : (
        <div className="p-5">
          <div className="flex gap-5">
            <div className="w-2/3 overflow-auto  shadow-md ">
              <DocViewer
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: false,
                  },
                  pdfZoom: {
                    defaultZoom: 1.1,
                    zoomJump: 0.2,
                  },
                }}
                style={{ width: '100%', height: '67vh' }}
                documents={[
                  {
                    uri: window.URL.createObjectURL(pdfBlob),
                  },
                ]}
                pluginRenderers={DocViewerRenderers}
              />
            </div>
            <div className="w-1/3">
              <div className="flex flex-col">
                <h3 className="text-xl font-medium">Details</h3>
                <div className="flex flex-col gap-5 mt-3">
                  <div className="flex items-center gap-10">
                    <p className="text-gray-light w-[80px]">Date</p>
                    <p>{moment(docDetails?.issued).format('DD/MM/YYYY')}</p>
                  </div>
                  <div className="flex items-center gap-10">
                    <p className="text-gray-light w-[80px]">Name</p>
                    <p>Report</p>
                  </div>
                  <div className="flex items-center gap-10">
                    <p className="text-gray-light w-[80px]">Author</p>
                    <p>Doctor</p>
                  </div>
                  <div className="flex items-center gap-10">
                    <p className="text-gray-light w-[80px]">Category</p>
                    <p>Doctor Report</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 w-full">
                <h3 className="text-xl font-medium">Notes</h3>
                <textarea rows={8} placeholder="Keep your notes here" className="mt-2 outline-none w-full text-gray-light rounded-[10px] bg-pale p-2.5"/>
              </div>
            </div>
          </div>
          <div className="w-full h-[1px] bg-gray-solitude mt-8 mb-5" />
          <div className="flex items-center justify-between gap-5">
            <MainButton
              onClick={deleteDoc}
              type="button"
              isOutline
              text="Delete"
              className="w-[112px] border-red-vivid text-red-vivid"
            />
            <div className="flex items-center gap-5">
              <MainButton
                type="button"
                text="Edit"
                disabled
                onClick={onCloseModal}
                className="w-[112px]"
              />
              <MainButton
                type="button"
                text="Close"
                onClick={onCloseModal}
                className="w-[272px]"
              />
            </div>
          </div>
        </div>
      )}
    </ModalWrapper>
  )
}

export default ModalDocumentViewer
