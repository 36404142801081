import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ProfileCard from '../../../components/Cards/ProfileCard'
import WhiteBox from '../../../components/UI/WhiteBox'
import LangSelector from '../../../components/UI/LangSelector'
import userUtils from '../../../utils/users-utils'

export default function ProfilePage() {
  const [t] = useTranslation()
  const authUser = userUtils.getUser()

  const data = {
    firstName: authUser?.first_name || '',
    lastName: authUser?.last_name || '',
    email: authUser?.email || '',
    profileType: authUser?.role || '',
    specialty: authUser?.specialty || '',
    institution: authUser?.institution || '',
    photoUrl: null,
  }

  const [profile, setProfile] = useState({
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    profileType: data.profileType,
    specialty: data.specialty,
    institution: data.institution,
    photoUrl: data.photoUrl,
  })

  return (
    <div className="flex justify-center w-full mt-20 md:mt-12">
      <WhiteBox width="w-[95vw] md:w-1/2 max-w-[800px]">
        <ProfileCard profile={profile} setProfile={setProfile} />
        <div className="flex justify-center">
          <hr className="text-base-300 w-[95%] my-5" />
        </div>
        <div className="mx-5 mb-10">
          <p className="mb-5 font-semibold">{t('languageSettings')}</p>
          <div className="flex items-baseline">
            <span className="mt-4 mr-10">{t('selectLanguage')}</span>
            <LangSelector />
          </div>
        </div>
      </WhiteBox>
    </div>
  )
}
