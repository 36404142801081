import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { BsUpload } from 'react-icons/bs'
import SingleFileUploadWithProgress from './SingleFileUploadWithProgress'

export default function MultipleFileUploadField() {
  const [files, setFiles] = useState([])
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    const mappedAcc = acceptedFiles.map((file) => ({ file, errors: [] }))
    setFiles((curr) => [...curr, ...mappedAcc, ...rejectedFiles])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      // 'application/msword': ['.doc'],
      // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      // 'application/vnd.oasis.opendocument.text': ['.odt'],
      // 'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
      'application/pdf': ['.pdf'],
      // 'application/vnd.ms-excel': ['.xls'],
      // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    multiple: true,
  })

  return (
    <div>
      <div {...getRootProps()} className="px-24 py-12 border border-dashed border-gray-light rounded-[10px]">
        <input {...getInputProps()} />
        <div className={`${isDragActive ? 'bg-red bg-opacity-80' : ''}`}>
          <div className="flex flex-col items-center text-center">
            <BsUpload className="w-8 h-6 text-defaultText" />
            <p className="my-3 text-lg text-defaultText">Drag & Drop files here...</p>
            <p className="text-xs text-gray-light">Accepted File Types: PDF max size: 10MB</p>
            <div className="py-3 mt-6 text-xl w-fit bg-primary rounded-xl text-neutral-content hover:cursor-pointer px-7 whitespace-nowrap">Browse Files</div>
          </div>
        </div>
      </div>
      {files.map((fileWrapper, idx) => <SingleFileUploadWithProgress key={idx} id={idx} file={fileWrapper.file} setFiles={setFiles} />)}
    </div>
  )
}
