const glycoproteinData = [
  {
    parameter: 'Neu5Ac (a2,3) / X', value: 0.02, unit: 'mM', lowThreshold: 0, highThreshold: 0.28, isNormal: true,
  },
  {
    parameter: 'Neu5Ac (a2,6) / Z', value: 0.39, unit: 'mM', lowThreshold: 0, highThreshold: 0.29, isNormal: false,
  },
  {
    parameter: 'Gal/K', value: 0.48, unit: 'mM', lowThreshold: 0, highThreshold: 0.51, isNormal: true,
  },
  {
    parameter: 'Gal/L', value: 0.62, unit: 'mM', lowThreshold: 0, highThreshold: 0.5, isNormal: false,
  },
  {
    parameter: 'Gal/Ω', value: 0.11, unit: 'mM', lowThreshold: 0, highThreshold: 0.61, isNormal: true,
  },
  {
    parameter: 'Gal/M', value: 0.33, unit: 'mM', lowThreshold: 0, highThreshold: 0.4, isNormal: true,
  },
  {
    parameter: 'GlcNac/O', value: 0.37, unit: 'mM', lowThreshold: 0, highThreshold: 0.48, isNormal: true,
  },
  {
    parameter: 'GlcNac/O*', value: 0.45, unit: 'mM', lowThreshold: 0, highThreshold: 0.66, isNormal: true,
  },
  {
    parameter: "GlcNac/O'", value: 0.02, unit: 'mM', lowThreshold: 0, highThreshold: 0.34, isNormal: true,
  },
  {
    parameter: 'GlcNac/P', value: 0.29, unit: 'mM', lowThreshold: 0, highThreshold: 0.24, isNormal: false,
  },
  {
    parameter: 'GlcNac/P*', value: 0.5, unit: 'mM', lowThreshold: 0, highThreshold: 0.61, isNormal: true,
  },
  {
    parameter: "GlcNac/P'", value: 0.34, unit: 'mM', lowThreshold: 0, highThreshold: 0.36, isNormal: true,
  },
  {
    parameter: 'GlcNax/Y', value: 0.35, unit: 'mM', lowThreshold: 0, highThreshold: 0.37, isNormal: true,
  },
  {
    parameter: 'Man/E', value: 0.06, unit: 'mM', lowThreshold: 0, highThreshold: 0.56, isNormal: true,
  },
  {
    parameter: 'Man/F', value: 0.44, unit: 'mM', lowThreshold: 0, highThreshold: 0.53, isNormal: true,
  },
  {
    parameter: 'Fuc/Q', value: 0.42, unit: 'mM', lowThreshold: 0, highThreshold: 0.49, isNormal: true,
  },
  {
    parameter: 'HBGA H-type 1', value: 0.28, unit: 'mM', lowThreshold: 0, highThreshold: 0.36, isNormal: true,
  },
  {
    parameter: 'HBGA A-type 1', value: 0.18, unit: 'mM', lowThreshold: 0, highThreshold: 0.4, isNormal: true,
  },
  {
    parameter: 'HBGA B-type 1', value: 0.26, unit: 'mM', lowThreshold: 0, highThreshold: 0.3, isNormal: true,
  },
  {
    parameter: 'HBGA H-type 2', value: 0.11, unit: 'mM', lowThreshold: 0, highThreshold: 0.55, isNormal: true,
  },
  {
    parameter: 'HBGA A-type 2', value: 0.13, unit: 'mM', lowThreshold: 0, highThreshold: 0.37, isNormal: true,
  },
  {
    parameter: 'HBGA B-type 2', value: 0.42, unit: 'mM', lowThreshold: 0, highThreshold: 0.28, isNormal: false,
  },
  {
    parameter: 'LeY', value: 0.46, unit: 'mM', lowThreshold: 0, highThreshold: 0.57, isNormal: true,
  },
  {
    parameter: 'Leb', value: 0.05, unit: 'mM', lowThreshold: 0, highThreshold: 0.69, isNormal: true,
  },
  {
    parameter: 'LeX', value: 0.09, unit: 'mM', lowThreshold: 0, highThreshold: 0.52, isNormal: true,
  },
  {
    parameter: 'sLeX', value: 0.08, unit: 'mM', lowThreshold: 0, highThreshold: 0.39, isNormal: true,
  },
  {
    parameter: 'Lea', value: 0.42, unit: 'mM', lowThreshold: 0, highThreshold: 0.3, isNormal: false,
  },
  {
    parameter: 'sLea', value: 0.03, unit: 'mM', lowThreshold: 0, highThreshold: 0.4, isNormal: true,
  },
  {
    parameter: 'LeY', value: 0.05, unit: 'mM', lowThreshold: 0, highThreshold: 0.65, isNormal: true,
  },
  {
    parameter: 'Leb', value: 0.35, unit: 'mM', lowThreshold: 0, highThreshold: 0.4, isNormal: true,
  },
]

export default glycoproteinData
