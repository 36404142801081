import React from 'react'
import { useTranslation } from 'react-i18next'
import CheckboxWithSelectAndInputs from '../UI/CheckboxWithSelectAndInputs'

export default function RiskFactorInputs({ riskFactors, onChange }) {
  const rfs = Object.entries(riskFactors).map(([key, value]) => ({
    name: key,
    value,
  }))
  const [t] = useTranslation()
  const renderRiskFactors = () => rfs.map((rf) => <li key={rf.name} className="flex gap-2 mt-2">
    {rf.name === 'age' || rf.name === 'bmi' || rf.name === 'activityLevel'
      ? <CheckboxWithSelectAndInputs name={rf.name} id={`riskFactors.${rf.name}`} hasValueInput value={rf.value.value} checked={rf.value.selected} onChange={onChange} />
      : <CheckboxWithSelectAndInputs name={rf.name} id={`riskFactors.${rf.name}`} checked={rf.value} onChange={onChange} />
    }
  </li>)

  return (
    <div className="col-span-1 px-6 pt-4">
      <p className="font-medium text-xl pb-2 text-cyan-dark">{t('riskFactors')}</p>
      <div className="p-4 border border-gray-solitude rounded-3.5xl">
        <ul className="overflow-y-auto min-h-[200px]">
          {renderRiskFactors()}
        </ul>
      </div>
    </div>
  )
}
