import { useTranslation } from 'react-i18next'
import { BsSearch } from 'react-icons/bs'
import { useAsyncDebounce } from 'react-table'
import styles from '../../css/components/Table/Search.module.css'

export default function Search({ setFilter }) {
  const [t] = useTranslation()

  const handleChange = useAsyncDebounce(
    (val) => setFilter(val || undefined),
    500,
  )

  return (
    <div className="bg-white text-gray-dark min-w-[200px] md:max-w-[420px] w-full flex items-center border border-solid border-gray-solitude rounded-xl px-4">
      <BsSearch />
      <input
        type="text"
        placeholder={t('search')}
        onChange={(event) => {
          handleChange(event.target.value)
        }}
        className={`py-[5px] ml-4 outline-none w-full ${styles.search}`}
      />
    </div>
  )
}
