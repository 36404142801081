export default function Dropdown({ children, isOpen }) {
  const isOpenClasses = isOpen ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
  return (
    <div className={`${isOpenClasses} absolute bg-white top-[3rem] -right-11 w-[200px]`}>
      <div className="absolute w-4 h-4"/>
      {children}
    </div>

  )
}
