import {
  useMemo,
  useState,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { LuUserX } from 'react-icons/lu'
import { Link, useNavigation } from 'react-router-dom'
import moment from 'moment'
import TableButtonGroup from '../Table/TableButtonGroup'
import WhiteBox from '../UI/WhiteBox'
import ModalWrapper from '../Modals/ModalWrapper'
import ModalActionContent from '../Modals/ModalActionContent'
import Table from '../Table/Table'
import CreateRuleForm from '../Forms/CreateRuleForm'
import SwitchButton from '../UI/SwitchButton'

export default function RulesTable({
  rules,
  rounded,
}) {
  const [t] = useTranslation()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [ruleId, setRuleId] = useState(null)
  const [rule, setRule] = useState(null)
  const navigation = useNavigation()
  const isSubmitting = navigation.state === 'submitting'

  const editRuleHandler = (values) => {
    setShowCreateModal(true)
    setRule(values)
  }

  const closeModalHandler = () => {
    setShowCreateModal(false)
    setRule(null)
  }

  const deleteRuleHandler = (id) => {
    setRuleId(id)
    setShowDeleteModal(true)
  }

  useEffect(() => {
    if (isSubmitting) {
      setShowDeleteModal(false)
    }
  }, [isSubmitting])

  const columns = [
    {
      Header: t('id'),
      accessor: 'id',
      Cell: ({ row: { original } }) => <Link to={`${original.id}`} state={{ ...original }} > <span className="pointer">{original.id}</span></Link >,
    },
    {
      Header: t('ruleName'),
      accessor: 'ruleName',
    },
    { Header: t('createdBy'), accessor: 'createdBy' },
    {
      Header: t('dateCreated'),
      accessor: 'dateCreated',
      Cell: (row) => (
          <span>{moment(row.value).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      Header: t('status'),
      accessor: 'status',
      Cell: ({ row: { original } }) => (
        <SwitchButton id={original.id} value={original.status} padding="pb-[1px]" />
      ),
    },
    {
      Header: ' ',
      Cell: ({ row: { original } }) => (
        <TableButtonGroup
          onDelete={() => deleteRuleHandler(original.id)}
          onEdit={() => editRuleHandler(original)} />
      ),
    },
  ]

  const data = useMemo(() => rules, [rules])

  return (
    <>
      <WhiteBox>
        <Table
          type="minimal"
          title={t('allRules')}
          columns={columns}
          data={data}
          pathKey="rules"
          rounded={rounded}
          rowsPerPage={10}
          screen="CasesPage"
          clickHandler={editRuleHandler}
          setShowModal={setShowCreateModal}
          hasAddButton
          buttonLabel="createNewRule"
          hasImportButton
        />
        {showCreateModal && (
          <ModalWrapper onCloseModal={closeModalHandler}
            type="close" title="createNewRule">
            <div className="p-6">
              <CreateRuleForm onClose={closeModalHandler} rule={rule} rules={rules} />
            </div>
          </ModalWrapper>
        )}
        {showDeleteModal && (
          <ModalWrapper onCloseModal={() => setShowDeleteModal(false)} type="close">
            <div className="px-6 py-[3rem]">
              <ModalActionContent title="deleteRule"
                description="areYouSureYouWantDeleteRule"
                buttonClassName="bg-red-500 border-red-500 hover:bg-red-500/90 md:max-w-[400px] w-full"
                buttonLabel="yesDeleteRule"
                buttonType="submit"
                buttonAction="delete"
                fieldName="patientId"
                id={ruleId}
                icon={<LuUserX size={50} />}
                closeModalHandler={() => setShowDeleteModal(false)} />
            </div>
          </ModalWrapper>
        )}
      </WhiteBox>
    </>
  )
}
