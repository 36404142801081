import { useTranslation } from 'react-i18next'
import MainButton from './MainButton'
import cn from '../../utils/cn'

export default function ErrorMessage({
  status, buttonText, errorMessage, onClickHandler, className, icon,
}) {
  const { t } = useTranslation()

  return (
    <div className={cn('h-[100dvh] gap-5 flex flex-col text-center items-center justify-center', className)}>
      {icon}
      <h2 className="text-2xl ">{status ? `${status} - ` : ''} {t(errorMessage)}</h2>
      <MainButton onClick={onClickHandler} className="mt-3">{t(buttonText)}</MainButton>
    </div>
  )
}
