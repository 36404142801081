import axios from 'axios'
import tokenUtils from '../../utils/token-utils'
import getUserProfile from '../user_profile/user-service'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const login = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/jwt/create/`, data)

    const token = response.data
    tokenUtils.saveTokenInSession(token)
    return await getUserProfile()
  } catch (error) {
    throw error.response.data
  }
}

export const activateAccount = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/activate/`, data)
    return response
  } catch (error) {
    throw error.response
  }
}

export const forgotPassword = async (email) => {
  try {
    return await axios.post(`${BASE_URL}/auth/forgot-password/`, { email })
  } catch (error) {
    throw error.response
  }
}

export const resetPasswordConfirm = async (data) => {
  console.log('THIS TRIGGERED')
  try {
    return await axios.post(`${BASE_URL}/auth/reset-password/`, { ...data })
  } catch (error) {
    throw error.response
  }
}
