import PatientsLoader from '../../../components/DoctorManager/PatientsLoader'
import PatientsWithFilesTable from '../../../components/LabTechnician/PatientsWithFilesTable'

export const patientsLab = [
  {
    id: 'P001',
    name: 'Alice',
    surname: 'Johnson',
    email: 'alice.johnson@example.com',
    telephone: '+1234567890',
    dateCreated: '2024-09-01',
    dateOfBirth: '1990-05-15',
    gender: 'Female',
  },
  {
    id: 'P002',
    name: 'Bob',
    surname: 'Smith',
    email: 'bob.smith@example.com',
    telephone: '+1234567891',
    dateCreated: '2024-09-02',
    dateOfBirth: '1988-08-22',
    gender: 'Male',
  },
  {
    id: 'P003',
    name: 'Carol',
    surname: 'Davis',
    email: 'carol.davis@example.com',
    telephone: '+1234567892',
    dateCreated: '2024-09-03',
    dateOfBirth: '1995-03-30',
    gender: 'Female',
  },
  {
    id: 'P004',
    name: 'David',
    surname: 'Williams',
    email: 'david.williams@example.com',
    telephone: '+1234567893',
    dateCreated: '2024-09-04',
    dateOfBirth: '1987-11-12',
    gender: 'Male',
  },
  {
    id: 'P005',
    name: 'Emily',
    surname: 'Brown',
    email: 'emily.brown@example.com',
    telephone: '+1234567894',
    dateCreated: '2024-09-05',
    dateOfBirth: '1993-07-25',
    gender: 'Female',
  },
  {
    id: 'P006',
    name: 'Frank',
    surname: 'Miller',
    email: 'frank.miller@example.com',
    telephone: '+1234567895',
    dateCreated: '2024-09-06',
    dateOfBirth: '1985-09-18',
    gender: 'Male',
  },
  {
    id: 'P007',
    name: 'Grace',
    surname: 'Wilson',
    email: 'grace.wilson@example.com',
    telephone: '+1234567896',
    dateCreated: '2024-09-07',
    dateOfBirth: '1992-02-05',
    gender: 'Female',
  },
  {
    id: 'P008',
    name: 'Henry',
    surname: 'Moore',
    email: 'henry.moore@example.com',
    telephone: '+1234567897',
    dateCreated: '2024-09-08',
    dateOfBirth: '1990-12-10',
    gender: 'Male',
  },
  {
    id: 'P009',
    name: 'Ivy',
    surname: 'Taylor',
    email: 'ivy.taylor@example.com',
    telephone: '+1234567898',
    dateCreated: '2024-09-09',
    dateOfBirth: '1994-04-16',
    gender: 'Female',
  },
  {
    id: 'P010',
    name: 'Jack',
    surname: 'Anderson',
    email: 'jack.anderson@example.com',
    telephone: '+1234567899',
    dateCreated: '2024-09-10',
    dateOfBirth: '1989-06-29',
    gender: 'Male',
  },
]

export default function LabTechnicianPatientsPage() {
  return (
    <div className="h-full p-5 sm:p-12">
            {/* <PatientsLoader> */}
            {/*        {({ patientsData }) => <PatientsWithFilesTable patients={patientsData} title="allPatients"/>} */}
            {/* </PatientsLoader> */}
        <PatientsWithFilesTable patients={patientsLab} title="allPatients"/>
    </div>
  )
}
