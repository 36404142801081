import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Form, useSubmit } from 'react-router-dom'
import MainButton from '../UI/MainButton'
import LoginInput from './Input'
import validationSchema from '../../utils/validation-schema'
import SelectInput from './SelectInput'

export default function CreateUserForm({ onClose }) {
  const [t] = useTranslation()
  const [userType, setUserType] = useState('')

  const submit = useSubmit()

  const formik = useFormik({
    initialValues: {
      email: '',
      userType: '',
      firstName: '',
      lastName: '',
      specialty: '',
    },
    validationSchema: validationSchema.createUser,
    onSubmit: async (values, actions) => {
      submit(values, { method: 'post' })
      setUserType('')
      actions.resetForm()
    },
  })

  return (
    <Form onSubmit={formik.handleSubmit} method="post" className="flex flex-col justify-between h-full">
      <div className="grid gap-4 md:grid-cols-2">
        <LoginInput
          name="firstName"
          type="text"
          label={t('firstName')}
          placeholder=""
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={!!(formik.touched.firstName && formik.errors.firstName)}
          errorMessage={formik.errors.firstName}
        />
        <LoginInput
          type="text"
          name="lastName"
          label={t('lastName')}
          placeholder=""
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={!!(formik.touched.lastName && formik.errors.lastName)}
          errorMessage={formik.errors.lastName}
        />
        <LoginInput
          name="email"
          type="email"
          label={t('email')}
          placeholder=""
          value={formik.values.email}
          onChange={formik.handleChange}
          error={!!(formik.touched.email && formik.errors.email)}
          errorMessage={formik.errors.email}
        />
        <SelectInput
          name="userType"
          type="select"
          label={t('userType')}
          placeholder=""
          setUserType={setUserType}
          value={formik.values.userType}
          onChange={formik.handleChange}
          error={!!(formik.touched.userType && formik.errors.userType)}
          errorMessage={formik.errors.userType}
        >
          <option value="">{t('chooseOneOption')}</option>
          <option value="doctor">{t('doctor')}</option>
          <option value="labtechnician">{t('labTechnician')}</option>
          <option value="usermaintainer">{t('maintainer')}</option>
          <option value="doctormanager">{t('manager')}</option>
          <option value="nurse">{t('nurse')}</option>
        </SelectInput>
        {userType === 'doctor' && (
          <LoginInput
            type="text"
            name="specialty"
            label={t('specialty')}
            placeholder=""
            value={formik.values.specialty}
            onChange={formik.handleChange}
            error={!!(formik.touched.specialty && formik.errors.specialty)}
            errorMessage={formik.errors.specialty}
          />
        )}
      </div>
      <div className="flex flex-col justify-end w-full gap-4 mt-9 md:flex-row">
        <MainButton
          text={t('cancel')}
          type="button"
          onClick={onClose}
          className="rounded-xl"
          isCancel
        />
        <MainButton text={t('create')} type="submit" name="intent" value="create" className="w-full md:max-w-[270px] rounded-xl" />
      </div>
    </Form>
  )
}
