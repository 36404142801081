const liporpoteinData = [
  {
    parameter: 'TG', unit: 'mg/dL', lowThreshold: 53, highThreshold: 490, value: 45, isNormal: false,
  },
  {
    parameter: 'Chol', unit: 'mg/dL', lowThreshold: 140, highThreshold: 341, value: 150, isNormal: true,
  },
  {
    parameter: 'LDL-Chol', unit: 'mg/dL', lowThreshold: 55, highThreshold: 227, value: 60, isNormal: true,
  },
  {
    parameter: 'HDL-Chol', unit: 'mg/dL', lowThreshold: 35, highThreshold: 96, value: 100, isNormal: false,
  },
  {
    parameter: 'Apo-A1', unit: 'mg/dL', lowThreshold: 112, highThreshold: 217, value: 115, isNormal: true,
  },
  {
    parameter: 'Apo-A2', unit: 'mg/dL', lowThreshold: 24, highThreshold: 48, value: 50, isNormal: false,
  },
  {
    parameter: 'Apo-B100', unit: 'mg/dL', lowThreshold: 48, highThreshold: 160, value: 155, isNormal: true,
  },
  {
    parameter: 'LDL-Chol/HDL-Chol', unit: 'ratio', lowThreshold: 0.98, highThreshold: 4.08, value: 1.5, isNormal: true,
  },
  {
    parameter: 'Apo-B100/Apo-A1', unit: 'ratio', lowThreshold: 0.30, highThreshold: 1.07, value: 0.25, isNormal: false,
  },
  {
    parameter: 'Total Particle Number', unit: 'nmol/L', lowThreshold: 876, highThreshold: 2908, value: 3000, isNormal: false,
  },
  {
    parameter: 'VLDL Particle Number', unit: 'nmol/L', lowThreshold: 50, highThreshold: 473, value: 100, isNormal: true,
  },
  {
    parameter: 'IDL Particle Number', unit: 'nmol/L', lowThreshold: 36, highThreshold: 316, value: 350, isNormal: false,
  },
  {
    parameter: 'LDL Particle Number', unit: 'nmol/L', lowThreshold: 760, highThreshold: 2560, value: 1500, isNormal: true,
  },
  {
    parameter: 'LDL-1 Particle Number', unit: 'nmol/L', lowThreshold: 98, highThreshold: 567, value: 90, isNormal: false,
  },
  {
    parameter: 'LDL-2 Particle Number', unit: 'nmol/L', lowThreshold: 47, highThreshold: 427, value: 300, isNormal: true,
  },
  {
    parameter: 'LDL-3 Particle Number', unit: 'nmol/L', lowThreshold: 51, highThreshold: 499, value: 50, isNormal: false,
  },
  {
    parameter: 'LDL-4 Particle Number', unit: 'nmol/L', lowThreshold: 77, highThreshold: 577, value: 80, isNormal: true,
  },
  {
    parameter: 'LDL-5 Particle Number', unit: 'nmol/L', lowThreshold: 86, highThreshold: 615, value: 600, isNormal: true,
  },
  {
    parameter: 'LDL-6 Particle Number', unit: 'nmol/L', lowThreshold: 91, highThreshold: 815, value: 800, isNormal: true,
  },
  {
    parameter: 'VLDL TG', unit: 'mg/dL', lowThreshold: 21, highThreshold: 336, value: 320, isNormal: true,
  },
  {
    parameter: 'IDL TG', unit: 'mg/dL', lowThreshold: 5, highThreshold: 100, value: 10, isNormal: true,
  },
  {
    parameter: 'LDL TG', unit: 'mg/dL', lowThreshold: 12, highThreshold: 45, value: 40, isNormal: true,
  },
  {
    parameter: 'HDL TG', unit: 'mg/dL', lowThreshold: 7, highThreshold: 29, value: 20, isNormal: true,
  },
  {
    parameter: 'VLDL-Chol', unit: 'mg/dL', lowThreshold: 5, highThreshold: 77, value: 7, isNormal: true,
  },
  {
    parameter: 'IDL-Chol', unit: 'mg/dL', lowThreshold: 4, highThreshold: 50, value: 40, isNormal: true,
  },
  {
    parameter: 'LDL-Chol', unit: 'mg/dL', lowThreshold: 55, highThreshold: 227, value: 150, isNormal: true,
  },
  {
    parameter: 'HDL-Chol', unit: 'mg/dL', lowThreshold: 35, highThreshold: 96, value: 70, isNormal: true,
  },
  {
    parameter: 'VLDL Free Chol', unit: 'mg/dL', lowThreshold: 3, highThreshold: 33, value: 25, isNormal: true,
  },
  {
    parameter: 'IDL Free Chol', unit: 'mg/dL', lowThreshold: 1, highThreshold: 14, value: 5, isNormal: true,
  },
  {
    parameter: 'LDL Free Chol', unit: 'mg/dL', lowThreshold: 17, highThreshold: 63, value: 20, isNormal: true,
  },
  {
    parameter: 'HDL Free Chol', unit: 'mg/dL', lowThreshold: 7, highThreshold: 27, value: 18, isNormal: true,
  },
  {
    parameter: 'VLDL PL', unit: 'mg/dL', lowThreshold: 6, highThreshold: 68, value: 28, isNormal: true,
  },
  {
    parameter: 'IDL PL', unit: 'mg/dL', lowThreshold: 3, highThreshold: 33, value: 30, isNormal: true,
  },
  {
    parameter: 'LDL PL', unit: 'mg/dL', lowThreshold: 37, highThreshold: 121, value: 60, isNormal: true,
  },
  {
    parameter: 'HDL PL', unit: 'mg/dL', lowThreshold: 57, highThreshold: 136, value: 110, isNormal: true,
  },
  {
    parameter: 'HDL Apo-A1', unit: 'mg/dL', lowThreshold: 110, highThreshold: 222, value: 120, isNormal: true,
  },
  {
    parameter: 'HDL Apo-A2', unit: 'mg/dL', lowThreshold: 25, highThreshold: 48, value: 34, isNormal: true,
  },
  {
    parameter: 'VLDL Apo-B', unit: 'mg/dL', lowThreshold: 3, highThreshold: 26, value: 2, isNormal: false,
  },
  {
    parameter: 'IDL Apo-B', unit: 'mg/dL', lowThreshold: 2, highThreshold: 17, value: 15, isNormal: true,
  },
  {
    parameter: 'LDL Apo-B', unit: 'mg/dL', lowThreshold: 42, highThreshold: 141, value: 50, isNormal: true,
  },
  {
    parameter: 'VLDL-1 TG', unit: 'mg/dL', lowThreshold: 6, highThreshold: 212, value: 250, isNormal: false,
  },
  {
    parameter: 'VLDL-2 TG', unit: 'mg/dL', lowThreshold: 3, highThreshold: 67, value: 60, isNormal: true,
  },
  {
    parameter: 'VLDL-3 TG', unit: 'mg/dL', lowThreshold: 2, highThreshold: 49, value: 10, isNormal: true,
  },
  {
    parameter: 'VLDL-4 TG', unit: 'mg/dL', lowThreshold: 3, highThreshold: 28, value: 5, isNormal: true,
  },
  {
    parameter: 'VLDL-5 TG', unit: 'mg/dL', lowThreshold: 1, highThreshold: 7, value: 0.5, isNormal: false,
  },
  {
    parameter: 'VLDL-1 Chol', unit: 'mg/dL', lowThreshold: 1, highThreshold: 35, value: 20, isNormal: true,
  },
  {
    parameter: 'VLDL-2 Chol', unit: 'mg/dL', lowThreshold: 0, highThreshold: 15, value: 1, isNormal: true,
  },
  {
    parameter: 'VLDL-3 Chol', unit: 'mg/dL', lowThreshold: 0, highThreshold: 16, value: 10, isNormal: true,
  },
  {
    parameter: 'VLDL-4 Chol', unit: 'mg/dL', lowThreshold: 1, highThreshold: 15, value: 5, isNormal: true,
  },
  {
    parameter: 'VLDL-5 Chol', unit: 'mg/dL', lowThreshold: 0, highThreshold: 4, value: 0.5, isNormal: true,
  },
  {
    parameter: 'VLDL-1 Free Chol', unit: 'mg/dL', lowThreshold: 0, highThreshold: 13, value: 20, isNormal: false,
  },
  {
    parameter: 'VLDL-2 Free Chol', unit: 'mg/dL', lowThreshold: 0, highThreshold: 7, value: 0.5, isNormal: true,
  },
  {
    parameter: 'VLDL-3 Free Chol', unit: 'mg/dL', lowThreshold: 0, highThreshold: 8, value: 7, isNormal: true,
  },
  {
    parameter: 'VLDL-4 Free Chol', unit: 'mg/dL', lowThreshold: 0, highThreshold: 7, value: 1, isNormal: true,
  },
  {
    parameter: 'VLDL-5 FreeChol', unit: 'mg/dL', lowThreshold: 0, highThreshold: 2, value: 0.2, isNormal: true,
  },
  {
    parameter: 'VLDL-1 PL', unit: 'mg/dL', lowThreshold: 1, highThreshold: 32, value: 10, isNormal: true,
  },
  {
    parameter: 'VLDL-2 PL', unit: 'mg/dL', lowThreshold: 1, highThreshold: 15, value: 5, isNormal: true,
  },
  {
    parameter: 'VLDL-3 PL', unit: 'mg/dL', lowThreshold: 1, highThreshold: 14, value: 2, isNormal: true,
  },
  {
    parameter: 'VLDL-4 PL', unit: 'mg/dL', lowThreshold: 2, highThreshold: 13, value: 3, isNormal: true,
  },
  {
    parameter: 'VLDL-5 PL', unit: 'mg/dL', lowThreshold: 0, highThreshold: 5, value: 0.5, isNormal: true,
  },
  {
    parameter: 'LDL-1 TG', unit: 'mg/dL', lowThreshold: 3, highThreshold: 16, value: 20, isNormal: false,
  },
]

export default liporpoteinData
