import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import TextInput from './TextInput'
import ImageInput from './ImageInput'
import MainButton from '../UI/MainButton'

export default function ProfileForm({
  profile, setProfile, isEditing, setIsEditing,
}) {
  const [t] = useTranslation()

  const handleSubmit = (values) => {
    setProfile(values)
    setIsEditing(false)
  }

  const formik = useFormik({
    initialValues: profile,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const handleChange = (event) => {
    formik.handleChange(event)
    const file = event.currentTarget.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64 = reader.result
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex justify-end gap-4 px-2 my-3">
        <MainButton
          text="cancel"
          type="button"
          onClick={() => setIsEditing(false)}
          isCancel
        />
        <MainButton text="save" type="submit" className="min-w-[85px]" />
      </div>
      <div className="flex items-start gap-4 mt-2">
        <ImageInput
          handleChange={handleChange}
          photoUrl={profile.photoUrl}
          isEditing={isEditing}
        />
        <div className="grid w-full grid-cols-2 gap-4 px-3">
          <TextInput
            name="firstName"
            label={t('firstName')}
            placeholder={t('firstName')}
            onChange={formik.handleChange}
            value={formik.values.firstName}
            disabled
          />
          <TextInput
            name="lastName"
            label={t('lastName')}
            placeholder={t('lastName')}
            onChange={formik.handleChange}
            value={formik.values.lastName}
            disabled
          />
          <TextInput
            name="profileType"
            label={t('profileType')}
            placeholder={t('profileType')}
            onChange={formik.handleChange}
            value={t(formik.values.profileType)}
            disabled
          />
          <TextInput
            name="email"
            type="email"
            label={t('email')}
            placeholder="user@example.com"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {profile.specialty && (
            <TextInput
              name="specialty"
              label={t('specialty')}
              placeholder={t('specialty')}
              onChange={formik.handleChange}
              value={formik.values.specialty}
              disabled
            />
          )}
          <TextInput
            name="institution"
            label={t('institution')}
            placeholder={t('institution')}
            onChange={formik.handleChange}
            value={formik.values.institution}
            disabled
          />
        </div>
      </div>
    </form>
  )
}
