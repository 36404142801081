import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'

export default function LoginSignupSubmit({ step = 1, isLogin = true, handleNextStepClick }) {
  const [t] = useTranslation()
  const navigate = useNavigate()

  const buttonCss = `${
    !isLogin && step === 2 ? 'w-3/4' : 'w-full mb-2.5'
  } px-5 py-2.5 bg-primary hover:bg-secondary focus:outline-none rounded-xl font-medium text-xl text-pale text-center disabled:bg-primary/80 `
  if (!isLogin) {
    if (step === 1) {
      return (
        <div>
          <button
            type="button"
            className={buttonCss}
            onClick={handleNextStepClick}
          >
            {`${t('next')}>`}
          </button>
          <p className="text-xs font-light text-center text-neutral">
            {`${t('alreadyHaveAccount')} `}
            <NavLink
              to="/login"
              className="font-medium hover:underline text-secondary"
            >
              {t('login')}
            </NavLink>
          </p>
        </div>
      )
    }
    if (step === 2) {
      return (
        <div className="flex flex-col gap-4">
          <button type="submit" className={`${buttonCss} w-full`}>
            {t('signUp')}
          </button>
          <button
            onClick={() => navigate(-1)}
            className="border rounded-xl w-full bg-gray-solitude focus:outline-none font-medium text-xl px-5 py-2.5 text-gray-light text-center"
          >{`<${t('back')}`}</button>
        </div>
      )
    }
    if (step === 3) {
      return (
        <div>
          <button type="submit" className={buttonCss}>
            {`${t('verifyAccount')}>`}
          </button>
          <p className="text-sm font-medium text-center underline text-secondary">
            <a href="https://www.google.com" target="_blank" rel="noreferrer">
              {t('resendCode')}
            </a>
          </p>
        </div>
      )
    }
  } else {
    return (
      <div>
        <button type="submit" className={buttonCss}>
          {t('logIn')}
        </button>
        {/* <p className="text-xs font-light text-center text-neutral">
          {`${t('noAccountYet')} `}
          <NavLink
            to="/signup"
            className="font-medium hover:underline text-secondary"
          >
            {t('signUp')}
          </NavLink>
        </p> */}
      </div>
    )
  }
}
