import React, { Suspense } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import SpinnerTwo from '../UI/SpinnerTwo'
import { riskScoreQuery, earlyDiagnosticVOCQuery } from '../../pages/loaders/pageLoaders'
import FirstResults from './FirstResults'
import FinalResult from './FinalResult'
import OncoRiste from './OncoRiste'
import ClusteringApproach from '../Forms/ClusteringApproach'
import SemiEmpiricalApproach from '../Forms/SemiEmpiricalApproach'

export default function EarlyDiagnostics() {
  // const { riskScore, earlyDiagnosticVOC } = useLoaderData()
  // const { data: riskScoreData } = useQuery({
  //   ...riskScoreQuery(),
  //   initialData: riskScore,
  // })

  // const { data: earlyDiagnosticVOCData } = useQuery({
  //   ...earlyDiagnosticVOCQuery(),
  //   initialData: earlyDiagnosticVOC,
  // })

  const size = 'w-full'

  return (
    <div className="px-8 pt-10 py-6 md:grid md:grid-cols-7 md:gap-8 xl:border-r xl:border-gray-border-2">
      <div className="md:col-span-4 flex flex-col items-center pl-2 pr-8 border-r border-r-gray-light">
        <OncoRiste />
        <div className="grid grid-cols-2 gap-x-8 mt-10">
          <ClusteringApproach />
          <SemiEmpiricalApproach />
        </div>
        <div className="flex">
        </div>
      </div>
      <div className="md:col-span-3">
        <div className="flex flex-col gap-y-[42px]">
          <FirstResults
              size={size}
              earlyDiagnostic={ {}}
          />
          <FinalResult/>
        </div>

        {/* <Suspense fallback={<SpinnerTwo />}> */}
        {/*  /!* eslint-disable *!/ */}
        {/*  <Await */}
        {/*    resolve={earlyDiagnosticVOC} */}
        {/*    errorElement={<p className="text-center text-error">Error fetching data...</p>} */}
        {/*  > */}
        {/*    /!* eslint-enable *!/ */}
        {/*    {() => ( */}
        {/*      <div className="flex flex-col gap-y-[42px]"> */}
        {/*        <FirstResults */}
        {/*          size={size} */}
        {/*          earlyDiagnostic={earlyDiagnosticVOCData ?? {}} */}
        {/*        /> */}
        {/*        <FinalResult /> */}
        {/*      </div> */}
        {/*    )} */}
        {/*  </Await> */}
        {/* </Suspense> */}
      </div>
    </div>
  )
}
