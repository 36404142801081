import { useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MultipleFileUploadField from './MultipleFileUploadField'

export default function UploadFileForm() {
  const [t] = useTranslation()
  const handleSubmit = () => { }
  const formik = useFormik({
    initialValues: '',
    onSubmit: (values) => handleSubmit(values),
  })

  return (
    <div className="pl-16 pr-5">
      <h2 className="text-xl text-defaultText font-medium">{t('uploadFiles')}</h2>
      <form onSubmit={formik.handleSubmit} className="mt-12">
        <MultipleFileUploadField />
      </form>
    </div>)
}
