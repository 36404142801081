import navMenu from '../constants/nav-menu'
import roles from '../routes/roles'

const chooseRoute = (profileType) => {
  let initialRoute
  switch (profileType) {
    case roles.Doctor:
      initialRoute = '/medical-staff'
      break
    case roles.Nurse:
      initialRoute = '/medical-staff'
      break
    case roles.DoctorManager:
      initialRoute = '/doctor-manager'
      break
    case roles.LabTechnician:
      initialRoute = '/lab-technician'
      break
    case roles.UserMaintainer:
      initialRoute = '/maintainer'
      break
    default:
      initialRoute = '/medical-staff'
  }

  return initialRoute
}

// todo: change menu items for labtechnichian, doctor manager
const getMenuItemsForRole = (profileType) => {
  switch (profileType) {
    case roles.Doctor:
      return navMenu.menuItemsMedicalStaff
    case roles.Nurse:
      return navMenu.menuItemsMedicalStaff
    case roles.DoctorManager:
      return navMenu.menuItemsDoctorManager
    case roles.LabTechnician:
      return navMenu.menuItemsLabTechnician
    case roles.UserMaintainer:
      return navMenu.menuItemsMaintainer
    default:
      return navMenu.menuItemsMedicalStaff
  }
}

// todo: change menu items for labtechnichian, doctor manager
const getMobileMenuItemsForRole = (profileType) => {
  switch (profileType) {
    case roles.Doctor:
      return navMenu.mobileMenuItemsMedicalStaff
    case roles.Nurse:
      return navMenu.mobileMenuItemsMedicalStaff
    case roles.DoctorManager:
      return navMenu.mobileMenuItemsDoctorManager
    case roles.LabTechnician:
      return navMenu.mobileMenuItemsLabTechnician
    case roles.UserMaintainer:
      return navMenu.mobileMenuItemsMaintainer
    default:
      return navMenu.mobileMenuItemsMedicalStaff
  }
}

const navUtils = {
  getMobileMenuItemsForRole, getMenuItemsForRole, chooseRoute,
}

export default navUtils
