import {
  useLocation,
  useSubmit,
  useActionData,
  useNavigate,
} from 'react-router-dom'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { MdErrorOutline } from 'react-icons/md'
import { BiError } from 'react-icons/bi'
import LoginSignupWrapper from '../../components/Forms/LoginSignupWrapper'
import validationSchema from '../../utils/validation-schema'
import ErrorMessage from '../../components/UI/ErrorMessage'
import Success from '../../components/UI/SuccessMessage'
import successImage from '../../assets/images/success_activation_account.svg'

export default function ActivateAccountPage() {
  const submit = useSubmit()
  const data = useActionData()
  const location = useLocation()
  const navigate = useNavigate()
  const { search } = location
  const searchParams = new URLSearchParams(search)
  const token = searchParams.get('token')
  const uid = searchParams.get('uid')

  if (!search || !token || !uid) {
    throw new Response('Page not found', { status: 404 })
  }

  const formik = useFormik({
    initialValues: {
      uid,
      token,
    },
    validationSchema: validationSchema.activateAccount,
    onSubmit: async (values) => {
      submit(values, { method: 'post' })
    },
  })

  useEffect(() => {
    formik.submitForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let content = null

  if (data) {
    if (data.status === 204 || data.status === 200) {
      content = (
        <Success title="yourAccountActivated" description=
          "youWillReceiveEmailWithInstructions">
          <img src={successImage} alt="" className="w-[100px] sm:w-[133px] sm:h-[146px]" />
        </Success>
      )
    } else if (data.status === 400) {
      content = (
        <ErrorMessage
          icon={<MdErrorOutline size={60} className="text-red-500" />}
          status={data.status}
          errorMessage={data.data?.uid || data.data?.token}
          className="h-[250px]"
          buttonText="goToLoginPage"
          onClickHandler={() => navigate('/login')}
        />
      )
    } else if (data.status === 403) {
      content = (
        <ErrorMessage
          icon={<BiError size={60} className="text-yellow-500" />}
          errorMessage="accountIsAlreadyActive"
          className="h-[250px]"
          buttonText="goToLoginPage"
          onClickHandler={() => navigate('/login')}
        />
      )
    }
    return <LoginSignupWrapper>{content}</LoginSignupWrapper>
  }
}
