/* eslint-disable indent */
import { useEffect, useState } from 'react'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'

export default function Pagination({
  pageSize,
  pageIndex,
  pageLength,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  pageOptions,
  gotoPage,
  setPageSize,
  backgroundColor = 'white',
  rounded = 'true',
}) {
  const [pageNum, setPageNum] = useState(pageIndex + 1)
  const pageNumName = pageOptions.length > 1 ? 'Pages' : 'Page'
  const borderClasses = 'border bg-white border-solid border-[#BEBEBE] rounded-md w-[30px] h-[30px] flex items-center justify-center hover:opacity-70 transition-opacity duration-200'
  const borderDisableClasses = 'disabled:border-[#E2E2E2]'

  // Keep the page number in sync after state changes
  useEffect(() => {
    gotoPage(pageNum - 1)
  }, [gotoPage, pageNum])

  const bgColor = `bg-${backgroundColor}`
  const roundness = `${rounded ? 'rounded-3xl' : ''}`
  const paginationArray = [5, 10, 20, 50, 100]

  return (
    <div className={`${bgColor} ${roundness}  flex justify-between items-center py-4 px-6 text-[#B1B1B1] flex-wrap`}>
      <p>
        {`${pageSize * pageIndex + 1} - ${pageSize * pageIndex + pageLength
          } of ${pageOptions.length} ${pageNumName}`}
      </p>
      <div className="flex items-center gap-[18px] flex-wrap">
        <div className="flex flex-wrap items-center gap-3">
          <p>You are on Page:</p>
          <input
            className={`${borderClasses} py-1 px-3 max-w-[70px] `}
            type="number"
            value={pageNum.toString()}
            min={1}
            max={pageOptions.length}
            step={1}
            onChange={(event) => {
              const page = event.target.value ? +event.target.value - 1 : 0
              setPageNum(+event.target.value)
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
          <select
            className={`${borderClasses} w-[50px] h-[30px] bg-white rounded-md outline-none`}
            style={{ backgroundColor: '#ffffff !important' }}
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {paginationArray.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="h-[30px] w-[2px] bg-[#E6E6E6]" />
        <div className="flex gap-2">
          <button
            className={`${borderClasses} ${borderDisableClasses}`}
            type="button"
            onClick={() => {
              setPageNum(pageIndex)
              previousPage()
            }}
            disabled={!canPreviousPage}
          >
            <BsChevronLeft
              className={`${!canPreviousPage ? 'text-[#E2E2E2]' : 'text-primary-text-dark'
                }`}
            />
          </button>
          <button
            className={`${borderClasses} ${borderDisableClasses}`}
            type="button"
            onClick={async () => {
              setPageNum(pageIndex + 2)
              nextPage()
            }}
            disabled={!canNextPage}
          >
            <BsChevronRight
              className={`${!canNextPage ? 'text-[#E2E2E2]' : 'text-primary-text-dark'
                }`}
            />
          </button>
        </div>
      </div>
    </div>
  )
}
