import { useTranslation } from 'react-i18next'
import { Form } from 'react-router-dom'
import ModalWrapper from './ModalWrapper'
import MainButton from '../UI/MainButton'
import SingleFileUploadField from '../Forms/SingleFileUploadField'
import useQueryData from '../../hooks/useQueryData'
import { patientsQuery } from '../../pages/private_pages/doctor_manager_pages/queries/patients-queries'
import ReactSelect from '../Forms/ReactSelect'

export default function ModalUploadFile({ uploadDetails, onClose, hasPatients = false }) {
  const { t } = useTranslation()

  const { data: patients, isLoading } = useQueryData({
    query: patientsQuery,
    enabled: hasPatients,
    select: (data) => data.map((patient) => ({
      value: patient.id,
      label: `${patient.patient_first_name} ${patient.patient_last_name}`,
    })),
  })

  return (
    <ModalWrapper onCloseModal={onClose} title={uploadDetails.fileType}>
      <Form className="p-6">
        <SingleFileUploadField />

        {hasPatients && (
          <div className="mt-10 border-b border-b-[#E5E7EB] pb-8">
            <h2 className="text-2xl font-medium">
              Select a Patient to link the file to
            </h2>
            <p className="my-2">Patient</p>
            {isLoading ? <p>Loading...</p> : <ReactSelect options={patients} placeholder="Search by patient Name or ID" />}
          </div>
        )}

        <div className="flex flex-col justify-end w-full gap-4 mt-9 md:flex-row">
          <MainButton
            text={t('cancel')}
            type="button"
            onClick={onClose}
            className="rounded-3xl"
            isCancel />
          <MainButton
            text={t('update')}
            type="submit"
            className="w-full md:max-w-[270px] rounded-3xl" />
        </div>
      </Form>
    </ModalWrapper>
  )
}
