import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import classes from '../../css/components/UI/SwitchButton.module.css'

export default function SwitchButton({ id, value, padding = 'pb-0' }) {
  // Local state to manage the switch value
  const [checked, setChecked] = useState(value)

  // Update local state when the prop value changes
  useEffect(() => {
    setChecked(value)
  }, [])

  // Handle the toggle event
  const handleToggle = () => {
    setChecked((prev) => !prev) // Update local state
  }
  return (
    <div className={`flex items-center ${padding} gap-2 w-[100px]`}>
      <div className="flex">
        <input
          className={classes.switchInput}
          type="checkbox"
          id={id}
          onClick={handleToggle}
          checked={checked}
        />
        <label className={classes.switchLabel} htmlFor={id}>
          Toggle
        </label>
      </div>
      <div>
        <span>{checked ? t('active') : t('inactive')}</span>
      </div>
    </div>
  )
}
