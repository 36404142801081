import { useTranslation } from 'react-i18next'
import { BsGraphUpArrow } from 'react-icons/bs'

export default function ShowGraphButton({ onClick }) {
  const { t } = useTranslation()
  return <button type="button" onClick={onClick} className="z-10 text-sm text-secondary flex items-center justify-center gap-2 whitespace-nowrap">
    <BsGraphUpArrow size={12} />
    {t('showGraph')}
  </button>
}
