import { useTranslation } from 'react-i18next'
import cn from '../../utils/cn'

export default function ModalWrapper({
  title, onCloseModal, children, classNameContent, classNameWrapper,
}) {
  const { t } = useTranslation()

  return (
    <>
      <div
        className={
          'fixed z-40 inset-0 bg-[rgba(0,0,0,0.40)] transition-opacity duration-300'
        }
        onClick={onCloseModal}
        onKeyDown={onCloseModal}
        role="button"
        tabIndex="0"
      />
      <div
          className={
          cn('absolute z-[100] transform -translate-x-1/2 left-1/2 -translate-y-1/2 top-1/2', classNameWrapper)
        }
      >
        <div
          className={cn(
            'modal-middle bg-white min-w-[400px] sm:min-w-[500px] md:min-w-[700px] lg:min-w-[1000px] p-0 overflow-visible rounded-[16px]',
            classNameContent,
          )}
        >
          {title && (
            <h3 className="text-xl text-white bg-secondary p-[1.5rem] rounded-t-[16px]">
              {t(title)}
            </h3>
          )}
          {children}
        </div>
      </div>
    </>
  )
}
