import React from 'react'

export default function Avatar({ children, width = 'w-24' }) {
  return (
    <div className="avatar">
      <div
        className={`rounded-full border-2 border-neutral overflow-hidden ${width}`}
      >
        {children}
      </div>
    </div>
  )
}
