import { getPatient, getPatients } from '../../../../services/patients/patients-service'

export const patientsQuery = () => ({
  queryKey: ['patients'],
  queryFn: () => getPatients(),
})

export const patientQuery = (patientId) => ({
  queryKey: ['patient', patientId],
  queryFn: () => getPatient(patientId),
})
