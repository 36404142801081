import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ApproachContainer from '../UI/ApproachContainer'
import CheckboxWithSelectAndInputs from '../UI/CheckboxWithSelectAndInputs'
import LabelledCheckbox from '../UI/LabelledCheckbox'

export default function ClusteringApproach() {
  const [t] = useTranslation()

  const data = {
    gender: false,
    age: false,
    bmi: false,
    activityLevel: false,
    smokingStatus: false,
    geneticFactors: false,
    alcoholConsumption: false,
  }

  const initialrisks = Object.entries(data).map(([key, value]) => ({
    name: key,
    value,
  }))

  const [riskFactors, setRiskFactors] = useState(initialrisks)
  const [isEditing, setIsEditing] = useState(false)

  const toggleEditing = useCallback(() => {
    setIsEditing((prevState) => !prevState)
  }, [])

  const handleChange = (event) => {
    console.log(riskFactors)
    setRiskFactors((prevState) => prevState.map((item) => (item.name === event.target.name ? { ...item, value: event.target.checked } : item)))
  }

  const renderRiskFactors = () => riskFactors.map((rf) => <li key={rf.name} className="flex gap-2 mt-2">
    <LabelledCheckbox name={rf.name} id={rf.name} checked={rf.value} onChange={handleChange} isEditing={isEditing} />
  </li>)

  return (<>
    <form className="text-defaultText">
      <ApproachContainer title="clusteringApproach" hasPlusButton isEditing={isEditing} toggleEditing={toggleEditing}>
        <div className="mt-4">
          <p className="font-medium text-xl pb-2">{t('riskFactorsToConsider')}</p>
          <ul className="overflow-y-scroll h-[200px]">
            {renderRiskFactors()}
          </ul>
        </div>
      </ApproachContainer >
    </form>
  </>
  )
}
