import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Form,
  useSearchParams,
  useSubmit,
} from 'react-router-dom'
import { useFormik } from 'formik'
import validationSchema from '../../utils/validation-schema'
import LoginSignupWrapper from '../../components/Forms/LoginSignupWrapper'
import LoginInput from '../../components/Forms/Input'

export default function ResetPasswordPage() {
  const { t } = useTranslation()
  const submit = useSubmit()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const uid = searchParams.get('uid')

  if (!token || !uid) {
    throw new Response('Page not found', { status: 404 })
  }

  const formik = useFormik({
    initialValues: {
      token,
      uid,
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema.resetPassword,
    onSubmit: (values) => {
      submit(values, { method: 'post' })
    },
  })

  return <LoginSignupWrapper isLogin>
    <div>
      <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-defaultText">
        {t('resetYourPassword')}
      </h1>
      <p className="text-gray-dark text-sm mt-1">{t('resetPasswordDescription')}</p>
    </div>
    <Form
      className="space-y-4 md:space-y-6 flex flex-col gap-4"
      onSubmit={formik.handleSubmit}
      method="post"
    >
      <div className="flex flex-col gap-4">
        <LoginInput
          name="password"
          type="password"
          label={t('password')}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={!!(formik.touched.password && formik.errors.password)}
          errorMessage={formik.errors.password} />
        <LoginInput
          name="confirmPassword"
          type="password"
          label={t('confirmPassword')}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={!!(formik.touched.confirmPassword && formik.errors.confirmPassword)}
          errorMessage={formik.errors.confirmPassword} />
      </div>
      <div className="flex flex-col gap-4">
        <button type="submit" className="w-full px-5 py-2.5 bg-primary hover:bg-secondary focus:outline-none rounded-xl font-medium text-xl text-pale text-center disabled:bg-primary/80">
          {t('resetPassword')}
        </button>
      </div>
    </Form>
  </LoginSignupWrapper>
}
