import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import Submenu from '../../../components/UI/Submenu'
import PatientCard from '../../../components/Cards/PatientCard'
import WhiteBox from '../../../components/UI/WhiteBox'
import navMenu from '../../../constants/nav-menu'
import Suggestions from '../../../components/Patient/Suggestions'
import casesData from '../../../dummy_data/CasesData'

export default function MedicalStaffPatientPage() {
  const { id } = useParams()

  const selectedCase = casesData.find((c) => c.id === id)

  return (
    <div className="px-8 my-10">
      <div className="mb-10 grid grid-cols-3 gap-8">
        <div className="col-span-1">
          <PatientCard profile={selectedCase} shadow={true} />
        </div>
        <div className="col-span-2">
          <Suggestions />
        </div>
      </div>
      <Submenu menuItems={navMenu.medicalStaffPatientRoutes} />
      <div>
        <WhiteBox roundness="rounded-b-3xl">
          <Outlet />
        </WhiteBox>
      </div>
    </div>
  )
}
