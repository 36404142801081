import React from 'react'
import { useTranslation } from 'react-i18next'
import SectionTitle from '../UI/SectionTitle'
import Dot from '../UI/Dot'

export default function Findings({ findings }) {
  const [t] = useTranslation()

  return (
    <>
      <SectionTitle text={t('findings')} size="xl" marginBottom="mb-2" />
      <div className="pr-4 overflow-auto">
        <ul className="text-justify">
          {findings.map((obj, index) => (
            <li key={index}>
              <div className="flex gap-x-2">
                <Dot />
                <p className="text-gray-light">{t(obj.finding)}</p>
              </div>
              <hr className="my-4 rounded-sm text-gray-light" />
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}
