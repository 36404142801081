import {
  useMemo,
  useState,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { BsClipboard2X } from 'react-icons/bs'
import { useNavigation } from 'react-router-dom'
import TableButtonGroup from '../Table/TableButtonGroup'
import WhiteBox from '../UI/WhiteBox'
import ModalWrapper from '../Modals/ModalWrapper'
import ModalActionContent from '../Modals/ModalActionContent'
import Table from '../Table/Table'
import CreateCaseForm from '../Forms/CreateCaseForm'

export default function CasesDoctorManagerTable({
  type = 'minimal',
  cases,
  title,
  rounded,
}) {
  const [t] = useTranslation()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [caseId, setCaseId] = useState(null)
  const navigation = useNavigation()
  const isSubmitting = navigation.state === 'submitting'

  const deleteCaseHandler = (id) => {
    setCaseId(id)
    setShowDeleteModal(true)
  }

  useEffect(() => {
    if (isSubmitting) {
      setShowDeleteModal(false)
    }
  }, [isSubmitting])

  const columns = [
    {
      Header: t('id'),
      accessor: 'id',
    },
    {
      Header: t('caseName'),
      accessor: 'caseName',
    },
    {
      Header: t('doctor'),
      accessor: 'doctorName',
    },
    {
      Header: t('patient'),
      accessor: 'patientName',
    },
    {
      Header: t('dateCreated'),
      accessor: 'dateCreated',
    },

    {
      Header: ' ',
      Cell: ({ row: { original } }) => (
        <TableButtonGroup
          onDelete={() => deleteCaseHandler(original.id)} />
      ),
    },
  ]

  const data = useMemo(() => cases, [cases])

  return (
    <>
      <WhiteBox>
        <Table
          type={type}
          title={title}
          columns={columns}
          data={data}
          pathKey="doctorManagerPatients"
          rounded={rounded}
          rowsPerPage={10}
          screen="CasesPage"
          hasAddButton
          buttonLabel="addNewCase"
          setShowModal={setShowCreateModal}
        />
        {showCreateModal && (
          <ModalWrapper onCloseModal={() => setShowCreateModal(false)} type="close" title="createNewCase">
            <div className="p-6">
              <CreateCaseForm onClose={() => setShowCreateModal(false)} />
            </div>
          </ModalWrapper>
        )}
        {showDeleteModal && (
          <ModalWrapper onCloseModal={() => setShowDeleteModal(false)} type="close">
            <div className="px-6 py-[3rem]">
              <ModalActionContent title="deleteCase"
                description="areYouSureYouWantDeleteCase"
                buttonClassName="bg-red-500 border-red-500 hover:bg-red-500/90 md:max-w-[400px] w-full"
                buttonLabel="yesDeleteCase"
                buttonType="submit"
                buttonAction="delete"
                fieldName="caseId"
                id={caseId}
                icon={<BsClipboard2X size={50} />}
                closeModalHandler={() => setShowDeleteModal(false)} />
            </div>
          </ModalWrapper>
        )}
      </WhiteBox>
    </>
  )
}
