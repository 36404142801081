import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactDatePicker from 'react-datepicker'
import { DateTime } from 'luxon'
import MainButton from '../UI/MainButton'
import 'react-datepicker/dist/react-datepicker.module.css'
import FormCheckbox from '../Forms/FormCheckbox'

export default function Filters({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  yourCases,
  setYourCases,
}) {
  const [t] = useTranslation()

  const createFilterUrl = (params) => {
    const queryString = Object.entries(params)
      .filter(
        (value) => value !== null && value !== false && value !== undefined,
      )
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    return queryString ? `?${queryString}` : ''
  }

  const handleApply = () => {
    const formatedStartDate = DateTime.fromJSDate(startDate).toFormat('dd-MM-yyyy')
    const formatedEndDate = DateTime.fromJSDate(endDate).toFormat('dd-MM-yyyy')
    const url = createFilterUrl({
      from: formatedStartDate !== 'Invalid DateTime' ? formatedStartDate : null,
      to: formatedEndDate !== 'Invalid DateTime' ? formatedEndDate : null,
      showMyCases: yourCases,
    })
  }
  const clearFilters = () => {
    setStartDate(null)
    setEndDate(null)
    setYourCases(false)
  }

  const spanCss = 'mr-4 w-[36px] font-semibold'
  const datepickerCss = 'pl-2 max-w-[160px] md:max-w-none'
  const containerCss = 'flex'

  return (
    <>
      <h2 className="mb-10 font-bold text-center text-md">
        {t('selectFilters')}
      </h2>
      <div className="flex flex-col sm:flex-row sm:justify-center sm:gap-2 md:gap-4">
        <div className={`${containerCss} mb-4 sm:mb-0`}>
          <span className={spanCss}>From:</span>
          <ReactDatePicker
            className={datepickerCss}
            isClearable
            selected={startDate}
            placeholderText={t('selectStartDate')}
            onChange={(date) => setStartDate(date)}
            showYearDropdown
          />
        </div>
        <div className={containerCss}>
          <span className={spanCss}>To:</span>
          <ReactDatePicker
            className={datepickerCss}
            isClearable
            selected={endDate}
            placeholderText={t('selectEndDate')}
            onChange={(date) => setEndDate(date)}
            showYearDropdown
          />
        </div>
      </div>
      <hr className="h-[2px] bg-base-200 mt-8 mb-8 rounded-lg" />
      <div className="sm:ml-2 md:ml-6">
        <div className="flex justify-start">
          <div>
            <FormCheckbox
              text={t('yourCases')}
              value={yourCases}
              onChange={(e) => setYourCases(e.target.checked)}
            />
          </div>
        </div>
        <div className="absolute flex justify-end gap-x-4 right-8 bottom-8">
          <MainButton
            isText
            isCancel
            text={t('clearFilters')}
            onClick={() => clearFilters()}
          />
          <MainButton text={t('apply')} onClick={() => handleApply()} />
        </div>
      </div>
    </>
  )
}
