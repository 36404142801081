import React from 'react'
import { useTranslation } from 'react-i18next'
import CheckboxWithSelectAndInputs from '../UI/CheckboxWithSelectAndInputs'

export default function FirstResultInputs({ firstResults, onChange }) {
  const frs = Object.entries(firstResults).map(([key, value]) => ({
    name: key,
    value,
  }))
  const [t] = useTranslation()

  const renderFirstResults = () => frs.map((fr) => <li key={fr.name} className="flex gap-2 mt-2">
    <CheckboxWithSelectAndInputs name={fr.name} id={`firstResults.${fr.name}`} hasValueInput value={fr.value.value} type="onco" checked={fr.value.selected} onChange={onChange} />
  </li>)

  return (
    <div>
      <h3 className="font-medium text-xl pb-2 text-cyan-dark">{t('firstResults')}</h3>
      {renderFirstResults()}
    </div>
  )
}
