import { Navigate, Outlet } from 'react-router-dom'
import tokenUtils from '../utils/token-utils'
import userUtils from '../utils/users-utils'
import navUtils from '../utils/nav-utils'

const PublicRoutes = () => {
  const token = tokenUtils.getAccessTokenFromSession()
  const user = userUtils.getUser()
  const chosenRoute = navUtils.chooseRoute(user?.role)

  return (
    <>
    {!token && <Outlet />}
    {token && user && <Navigate to={chosenRoute} />}
    </>
  )
}

export default PublicRoutes
