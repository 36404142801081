import Select, { components } from 'react-select'
import { FiSearch } from 'react-icons/fi'

const DropdownIndicator = (props) => (
      <components.DropdownIndicator {...props}>
        <FiSearch className="text-gray-dark" />
      </components.DropdownIndicator>
)

export default function ReactSelect({ options = [], placeholder, ...props }) {
  (
         <Select
             options={options}
             isSearchable
             isClearable
             placeholder={placeholder}
             className="w-1/2"
             styles={
                    {
                      control: (base) => ({
                        ...base,
                        border: '1px solid #E5E7EB',
                        borderRadius: '12px',
                        height: '50px',
                        padding: '0 10px',
                        boxShadow: 'none',
                        '&:hover': {
                          border: '1px solid #D1D5DB',
                        },
                      }),

                      option: (base, { isFocused, isSelected }) => ({
                        ...base,
                        // eslint-disable-next-line no-nested-ternary
                        backgroundColor: isSelected ? '#F3F4F6' : isFocused ? '#F3F4F6' : 'white',
                        // eslint-disable-next-line no-nested-ternary
                        color: isSelected ? '#111827' : isFocused ? '#111827' : '#111827',
                      }),
                      placeholder: (base) => ({
                        ...base,
                        color: '#707070',
                      }),
                    }
             }
             components={{
               IndicatorSeparator: () => null,
               DropdownIndicator,
             }}

             {...props}

         />
  )
}
