import { defer } from 'react-router-dom'
import { getReports } from '../../services/reports/reports-service'

export const reportsQuery = () => ({
  queryKey: ['reports'],
  queryFn: () => getReports(),
})

export const reportsLoader = (queryClient) => () => defer({
  reports: queryClient.ensureQueryData(reportsQuery()),
})
