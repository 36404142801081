import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import { FaPencil } from 'react-icons/fa6'
import { FaSave } from 'react-icons/fa'
import BasicCard from '../Cards/BasicCard'
import PlusButton from './PlusButton'
import MainButton from './MainButton'
import cn from '../../utils/cn'

export default function ApproachContainer({
  hasPlusButton = false, hasInfoButton = false, title, children, isEditing = false, toggleEditing,
}) {
  const [t] = useTranslation()
  const [isVisible, setIsVisible] = useState(false)
  const toggleVisibility = () => setIsVisible((prev) => !prev)

  const itemHeight = 30
  const itemCount = 5
  return (
    <BasicCard
      name={t(title)}
      styles="min-h-[100px]"
      alignment="justify-start pl-6"
    >
      {hasInfoButton && (
        <div className="absolute text-red top-4 right-5">
          <IoIosInformationCircleOutline className="text-xl text-white" />
        </div>
      )}
      <div className="flex flex-col px-6">
        <div className="flex flex-col border-b border-b-gray-solitude">
          <div className="grid grid-cols-5 gap-y-3 gap-x-6 uppercase pb-4 pt-2 ">
            <p className="col-span-2">{t('riskLevel')}</p>
            <p className="col-span-2">
              4 - <span className="text-red-vivid">high</span>
            </p>
            {hasPlusButton && (
              <div className="col-span-1 place-content-end">
                {!isVisible && <PlusButton onClick={toggleVisibility} />}
                {isVisible && <PlusButton onClick={toggleVisibility} label="-" className="bg-[#9AE6C5]" />}
              </div>
            )}
            <p className="col-span-2">{t('confidence')}</p>
            <p className="col-span-2">55%</p>
          </div>
          <div
            className={cn(
              'max-h-0 overflow-hidden transition-all duration-300',
              {
                'pb-4': isVisible,
              },
            )}
            style={{
              maxHeight: isVisible ? `${itemHeight * itemCount}px` : '0',
            }}
          >
            <ul className="text-gray-light flex flex-col gap-1">
              <li>Risk level 3 - Confidence 42%</li>
              <li>Risk level 2 - Confidence 26%</li>
              <li>Risk level 1 - Confidence 21%</li>
              <li>No Samples: 1000</li>
              <li>No Features: 14</li>
            </ul>
          </div>
        </div>

        {children}
        <div className="self-end mt-4 pb-5 ">
          {/* preventDefault prevents refreshing of the page */}
          {!isEditing ? (
            <MainButton
              type="button"
              text={'edit'}
              isOutline={true}
              textColor="primary"
              onClick={toggleEditing}
            >
              <div className="mr-2">
                <FaPencil />
              </div>
            </MainButton>
          ) : (
            <MainButton
              type="button"
              text={'save'}
              isOutline={true}
              textColor="primary"
              onClick={toggleEditing}
            >
              <div className="mr-2">
                <FaSave />
              </div>
            </MainButton>
          )}
        </div>
      </div>
    </BasicCard>
  )
}
