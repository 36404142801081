import React from 'react'

export default function Dot({
  bgColor = 'bg-neutral', shadow = '', size = 'w-2.5 h-2.5', dotAligner = 'mt-[1px]',
}) {
  return (
    <div className="flex">
      <span
        className={`${bgColor} ${size} inline-block rounded-full ${shadow} ${dotAligner}`}
      />
    </div>
  )
}
