import React from 'react'
import { useTranslation } from 'react-i18next'
import Avatar from '../UI/Avatar'
import iconCaseName from '../../assets/images/icon-case-name-2x.png'
import WhiteBox from '../UI/WhiteBox'

export default function LabTechnicianPatientCard({
  patientName, id, dateCreated, size = 'w-full', shadow = false,
}) {
  const [t] = useTranslation()
  const flexer = 'flex items-baseline gap-2 text-xl'

  return (
    <WhiteBox width={size}>
      <div className="flex items-center justify-between gap-4 py-6 px-14">
        <Avatar width="w-14">
          <img
            src={iconCaseName}
            alt="Profile"
          />
        </Avatar>
        <div className="grid justify-between w-full grid-cols-2">
          <div>
            <p className="mb-2 text-2xl leading-7 uppercase">{t('nmrParameterTables')}</p>
            <div className={flexer}>
              <div>
                <p className="text-russianBlack">{patientName}</p>
              </div>
              <div className={`${flexer} border-l border-gray-light rounded-l-2 pl-2`}>
                <p className="text-russianBlack">{t('id')}:</p>
                <p className="text-gray-light">{id}</p>
              </div>
            </div>
          </div>
          <div className={`${flexer} justify-end`}>
            <p>{t('dateCreated')}:</p>
            <p className="text-gray-light">{dateCreated}</p>
          </div>
        </div>
      </div>
    </WhiteBox>
  )
}
