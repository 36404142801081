import { useState } from 'react'
import ProfileForm from '../Forms/ProfileForm'
import DoctorCard from './DoctorCard'

export default function ProfileCard({ profile, setProfile }) {
  const [isEditing, setIsEditing] = useState(false)

  return (
    <div className="p-4">
      {!isEditing ? (
        <DoctorCard profile={profile} setIsEditing={setIsEditing} />
      ) : (
        <ProfileForm
          profile={profile}
          setProfile={setProfile}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      )}
    </div>
  )
}
