import React, { useEffect, useState } from 'react'
import CasesTable from '../../../components/CasesTable'
import Filters from '../../../components/Table/Filters'
import Modal from '../../../components/Modals/Modal'
import tokenUtils from '../../../utils/token-utils'
import axiosInstance from '../../../utils/axios-instances'

export default function CasesPage() {
  const [showModal, setShowModal] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [yourCases, setYourCases] = useState(false)
  const [casesData, setCasesData] = useState([])

  const token = tokenUtils.getAccessTokenFromSession()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('/cases/cases/')
        setCasesData(response)
      } catch (error) {
        console.log('Error Fetching Data', error)
      }
    }
    fetchData()
  }, [token])

  return (
    <div className="pt-16 mx-20 my-10 md:pt-0">
      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <Filters
            setShowModal={setShowModal}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            yourCases={yourCases}
            setYourCases={setYourCases}
          />
        </Modal>
      )}
      <CasesTable
        type="minimal"
        screen="CasesPage"
        cases={casesData}
        title="allCases"
        setShowModal={setShowModal}
        hasSearch={true}
        rounded
      />
    </div>
  )
}
