import React from 'react'
import { useTranslation } from 'react-i18next'
import StyledCheckbox from './StyledCheckbox'

export default function LabelledCheckbox({
  name, id, checked, onChange, isEditing,
}) {
  const [t] = useTranslation()
  return (
    <label className="flex items-center cursor-pointer text-base col-span-1">
      {isEditing && <StyledCheckbox name={name} id={id} checked={checked} onChange={onChange} />}
      <span className={isEditing ? 'ml-2' : ''}>{t(name)}</span>
    </label>
  )
}
