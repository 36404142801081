/* eslint-disable import/prefer-default-export */
import { toast } from 'react-toastify'
import { isAxiosError } from 'axios'
import { createCase, deleteCase } from '../../../../services/cases/cases-service'

const createCaseAction = async (query, request) => {
  const data = await request.formData()

  const newCase = {
    case_status: data.get('caseStatus'),
    case_nickname: data.get('caseName'),
    patient: data.get('patient'),
    case_date: data.get('caseDate'),
  }

  try {
    await createCase(newCase)
    toast.success('Case has been created')
    query.invalidateQueries('cases')
    return null
  } catch (error) {
    if (error && error.response && error.response.status === 500) {
      toast.error('500 Internal Server Error')
    } else if (isAxiosError(error)) {
      toast.error(error.message)
    } else {
      toast.error(error.detail || 'Unexpected Error occurred')
    }
    return null
  }
}

const deleteCaseAction = async (query, request) => {
  try {
    const formData = await request.formData()
    const id = formData.get('caseId')
    await deleteCase(id)
    toast.success('Case has been deleted')
    query.invalidateQueries('cases')
    return { response: { status: 'ok', path: '.' } }
  } catch (error) {
    if (error && error.response && error.response.status === 500) {
      toast.error('500 Internal Server Error')
    } else if (isAxiosError(error)) {
      toast.error(error.message)
    } else {
      toast.error(error.detail || 'Unexpected Error occurred')
    }
    return null
  }
}

export const caseActions = (query) => async ({ request }) => {
  const method = request.method.toUpperCase()

  if (method === 'POST') {
    return createCaseAction(query, request)
  } if (method === 'DELETE') {
    return deleteCaseAction(query, request)
  }
  toast.error('Unsupported request method')
  return null
}
