import React from 'react'
import { useTranslation } from 'react-i18next'
import SectionTitle from '../UI/SectionTitle'

export default function Preparation({ preparationDetails }) {
  const [t] = useTranslation()

  return (
    <div>
      <SectionTitle
        text={t('preparationDetails')}
        size="xl"
        marginBottom="mb-2"
      />
      <div className="overflow-auto pr-4">
        <p className="text-justify text-gray-light">{preparationDetails}</p>
      </div>
    </div>
  )
}
