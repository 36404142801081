import React, {
  Suspense,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { LuUserX } from 'react-icons/lu'
import { Await, useLoaderData } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import UploadFileForm from '../Forms/UploadFileForm'
import ReportsTable from '../ReportsTable'
import Modal from '../Modals/Modal'
import reportData from '../../dummy_data/reportData'
import ModalWrapper from '../Modals/ModalWrapper'
import TableButtonGroup from '../Table/TableButtonGroup'
import EditReportForm from '../Forms/EditReportForm'
import ModalActionNoLoaders from '../Modals/ModalActionNoLoaders'
import { reportsQuery } from '../../loaders/reports/reports-loader'
import SpinnerTwo from '../UI/SpinnerTwo'
import ModalActionContent from '../Modals/ModalActionContent'
import ModalDocumentViewer from '../Modals/ModalDocumentViewer'

export default function Reports() {
  const [t] = useTranslation()
  const { reports } = useLoaderData()
  const { data } = useQuery({
    ...reportsQuery(),
    initialData: reports,
  })
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showViewDocument, setShowViewDocument] = useState(false)
  const [report, setReport] = useState(null)
  const [reportId, setReportId] = useState(null)

  const editRuleHandler = (values) => {
    setShowEditModal(true)
    setReport(values)
  }

  const viewHandler = (selectedReport) => {
    setShowViewDocument(true)
    setReport(selectedReport)
  }

  const closeEditModalHandler = () => {
    setShowEditModal(false)
    setReport(null)
  }

  const deleteRuleHandler = (id) => {
    setReportId(id)
    setShowDeleteModal(true)
  }

  const closeDeleteModalHandler = () => {
    setShowDeleteModal(false)
    setReportId(null)
  }

  const closeViewDocumentModalHandler = () => {
    setShowViewDocument(false)
    setReport(null)
  }

  const reportColumns = [
    {
      Header: t('typeFileName'),
      Cell: () => <span>Report.pdf</span>,
    },
    {
      Header: t('uploader'),
      Cell: () => <span>Doctor</span>,
    },
    {
      Header: t('category'),
      accessor: 'category[0].coding[0].code',
      Cell: (row) => <span>{t(row.value)}</span>,
    },
    {
      Header: t('date'),
      Cell: (row) => (
        <span>{moment(row.value).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      Header: ' ',
      Cell: ({ row: { original } }) => <TableButtonGroup
            onView={() => viewHandler(original)}
            onDelete={() => deleteRuleHandler(original.id)}
        />,
    },
  ]

  return (
    <>
      {showEditModal && (
        <ModalWrapper
          title={`${t('editReport')} ${report.id}: ${report.fileName.slice(0, -4)}`}
          onCloseModal={closeEditModalHandler}
        >
          <div className="text-defaultText text-sm p-8">
            <EditReportForm
              initialValues={report}
              closeModal={closeEditModalHandler}
            />
          </div>
        </ModalWrapper>
      )}
      {/* Uses Icon for user in delete method. This needs to change. This needs to change. Copy the component so it does not manke any use of loaders */}
      {showDeleteModal && (
        <ModalWrapper onCloseModal={closeDeleteModalHandler} type="close">
          <div className="px-6 py-[3rem]">
            <ModalActionContent
              title="deleteReport"
              description="areYouSureYouWantDeleteReport"
              buttonClassName="bg-red-500 border-red-500 hover:bg-red-500/90 md:max-w-[400px] w-full"
              buttonLabel="yesDeleteReport"
              buttonType="submit"
              buttonAction="delete"
              fieldName="reportId"
              id={reportId}
              icon={<LuUserX size={50} />}
              closeModalHandler={closeDeleteModalHandler}
            />
          </div>
        </ModalWrapper>
      )}
      {showViewDocument && <ModalDocumentViewer report={report} onCloseModal={closeViewDocumentModalHandler}/>}
      {showFilterModal && (
        <Modal onClose={() => setShowFilterModal(false)}>
          <div>
            <p className="text-defaultText text-sm text-center">
              There are no filters yet...
            </p>
          </div>
        </Modal>
      )}
      <div className="grid grid-cols-2 gap-8 py-[54px] px-6">
        <Suspense fallback={<SpinnerTwo/>}>
          <Await resolve={reports}>
            {() => <ReportsTable
                title="Reports History"
                data={data}
                columns={reportColumns}
                setShowModal={setShowFilterModal}
              />
            }
          </Await>
        </Suspense>
        <UploadFileForm />
      </div>
    </>
  )
}
