const IconPulseClipboard = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="36.021" height="48.029" viewBox="0 0 36.021 48.029">
            <g id="Group_2702" data-name="Group 2702" transform="translate(-1178.989 -489.85)">
                <path id="fd5f773e9cd35cb937fce0f73ae1671d"
                      d="M60,19H57.478a3.5,3.5,0,0,0-3.462-3H38.506a3.5,3.5,0,0,0-3.462,3H32.017A4.022,4.022,0,0,0,28,23.019V60.011a4.022,4.022,0,0,0,4.017,4.017H60a4.022,4.022,0,0,0,4.017-4.017V23.019A4.022,4.022,0,0,0,60,19Zm-23,.5a1.505,1.505,0,0,1,1.5-1.5H54.016a1.505,1.505,0,0,1,1.5,1.5v1a1.505,1.505,0,0,1-1.5,1.5H38.506a1.505,1.505,0,0,1-1.5-1.5ZM62.02,60.011A2.018,2.018,0,0,1,60,62.027H32.017A2.018,2.018,0,0,1,30,60.011V23.019A2.018,2.018,0,0,1,32.017,21h3.027a3.5,3.5,0,0,0,3.462,3H54.016a3.5,3.5,0,0,0,3.462-3H60a2.018,2.018,0,0,1,2.016,2.016ZM46.239,56.024h-.013a1,1,0,0,1-.9-.6l-2.928-6.754-1.42,3.712a1,1,0,0,1-.935.643H35.5a1,1,0,0,1,0-2H39.35L41.4,45.66a1,1,0,0,1,1.853-.04l3.018,6.961,2.413-5.189a1,1,0,0,1,1.777-.073l2.1,3.7h3.952a1,1,0,0,1,0,2H51.983a1,1,0,0,1-.87-.506L49.681,50l-2.534,5.45A1,1,0,0,1,46.239,56.024Zm5.775-25.015H40.007a1,1,0,0,1,0-2H52.014a1,1,0,0,1,0,2Zm0,6.5H40.007a1,1,0,0,1,0-2H52.014a1,1,0,0,1,0,2Z"
                      transform="translate(1150.989 473.85)" fill="#d14a76"/>
                <rect id="Rectangle_855" data-name="Rectangle 855" width="20" height="6"
                      transform="translate(1187 490.85)" fill="#d14a76"/>
            </g>
        </svg>
)

export default IconPulseClipboard
