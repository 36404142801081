import React from 'react'
import { useTranslation } from 'react-i18next'
import SectionTitle from '../UI/SectionTitle'
import Dot from '../UI/Dot'

export default function Procedure({ procedureDetails }) {
  const [t] = useTranslation()

  return (
    <div className="">
      <SectionTitle
        text={t('procedureDetails')}
        size="xl"
        marginBottom="mb-2"
      />
      <div className="pr-4 overflow-auto">
        <ul className="text-justify">
          {procedureDetails.map((obj, index) => (
            <li key={index}>
              <div className="flex gap-x-2">
                <Dot />
                <p className="text-gray-light">{obj.detail}</p>
              </div>
              <hr className="my-4 rounded-sm text-gray-light" />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
