import axiosInstance from '../../utils/axios-instances'

export const getCases = async () => {
  try {
    const response = await axiosInstance.get('cases/cases')
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const getCase = async (caseId) => {
  try {
    const response = await axiosInstance.get(`cases/cases/${caseId}`)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const createCase = async (caseDetails) => {
  try {
    const response = await axiosInstance.post('cases/cases/', caseDetails)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const deleteCase = async (caseId) => {
  try {
    await axiosInstance.delete(`cases/cases/${caseId}`)
  } catch (error) {
    throw error.response.data
  }
}
