import React from 'react'
import { useTranslation } from 'react-i18next'
import Avatar from '../UI/Avatar'
import patient from '../../assets/images/patient.png'
import WhiteBox from '../UI/WhiteBox'

export default function PatientCard({ profile, size = 'w-full', shadow = false }) {
  const [t] = useTranslation()
  const flexer = 'flex items-baseline gap-2 text-xl'

  return (
    <WhiteBox width={size}>
      <div className="flex items-center gap-4 p-6 h-[150px]">
        <Avatar width="w-14">
          <img src={patient} alt="Profile" />
        </Avatar>
        <div className="flex flex-col">
          <p className="mb-2 text-2xl leading-7 uppercase">
            {profile.caseName}
          </p>
          <div className={flexer}>
            <div className={flexer}>
              <p>{t('id')}:</p>
              <p className="text-gray-light">{profile.id}</p>
            </div>
            <div
                className={`${flexer} justify-end border-l border-gray-light rounded-l-2 pl-2`}
            >
              <p>{t('startDate')}:</p>
              <p className="text-gray-light">{profile.dateCreated}</p>
            </div>
          </div>
          <div
              className={`${flexer}  rounded-l-2 `}
          >
            <p>{t('patient')}:</p>
            <p className="text-gray-light">{profile.patientName}</p>
          </div>
        </div>

      </div>
    </WhiteBox>
  )
}
