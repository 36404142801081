import oncoscreen from '../../assets/images/infinite-colon-logo.png'

export default function LoginSignupWrapper({ children }) {
  return (
      <section className="max-h-full mb-10 bg-base-100">
        <div className="flex flex-col items-center justify-start px-6 py-8 mx-auto mt-8 sm:mt-32 lg:py-0">
          <div className="flex items-center mb-6 text-2xl">
            <img
              className="w-8 h-8 mr-2 min-w-[150px] min-h-[120px]"
              src={oncoscreen}
              alt="oncoscreen logo"
            />
          </div>
          <div className="w-full bg-white shadow rounded-xl md:mt-0 xl:p-0 max-w-[485px]">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">{children}</div>
          </div>
        </div>
      </section>
  )
}
