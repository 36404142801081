import React from 'react'
import { AiFillEdit } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import Avatar from '../UI/Avatar'
import doctor from '../../assets/images/doctor.png'
import MainButton from '../UI/MainButton'

export default function DoctorCard({ profile, setIsEditing }) {
  const { t } = useTranslation()

  return (
    <div className="px-2">
      <div className="flex justify-between mt-2">
        <h2 className="text-lg font-bold">{t('personalInfo')}</h2>
        <div className="flex justify-end">
          <MainButton text={t('edit')} onClick={() => setIsEditing(true)}>
            <AiFillEdit className="mr-2" />
          </MainButton>
        </div>
      </div>
      <div className="flex flex-col items-center gap-4 mt-2 sm:flex-row">
        <Avatar>
          <img
            src={profile.photoUrl ? profile.photoUrl : doctor}
            alt="Profile"
            width="100"
          />
        </Avatar>
        <div className = "self-start sm:self-center">
          <p className="text-2xl font-bold uppercase">{`${profile.firstName} ${profile.lastName}`}</p>
          <p className="mb-3 text-xl text-gray-light">
            {`${t(profile.profileType)}${profile.specialty ? ` (${profile.specialty})` : ''}`}
          </p>
          <div className="flex gap-2">
            <p>{t('email')}:</p>
            <p className="text-gray-light">{profile.email}</p>
          </div>
          <div className="flex gap-2">
            <p>{t('institution')}:</p>
            <p className="text-gray-light">{profile.institution}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
