/* eslint-disable import/prefer-default-export */
import { toast } from 'react-toastify'
import { isAxiosError } from 'axios'
import {
  createUser,
  deleteUser,
  resendUserActivationEmail,
  resetPasswordEmail,
} from '../../../../services/users/users-service'
import userUtils from '../../../../utils/users-utils'

const createUserAction = async (query, formData) => {
  const userType = formData.get('userType')

  const newUser = {
    first_name: formData.get('firstName'),
    last_name: formData.get('lastName'),
    email: formData.get('email'),
    role: userType,
    profile: { specialty: formData.get('specialty') },
    institution: formData.get('institution'),
  }
  try {
    await createUser(newUser)
    toast.success('User has been created')
    query.invalidateQueries('users')
    return null
  } catch (error) {
    if (error && error.response && error.response.status === 500) {
      toast.error('500 Internal Server Error')
    } else if (isAxiosError(error)) {
      toast.error(error.message)
    } else {
      toast.error(error.detail || error.email[0])
    }
    return null
  }
}

const deleteUserAction = async (query, request) => {
  try {
    const formData = await request.formData()
    const id = formData.get('userId')
    await deleteUser(id)
    toast.success('User has been deleted')
    query.invalidateQueries('users')
    return { response: { status: 'ok', path: '.' } }
  } catch (error) {
    if (error && error.response && error.response.status === 500) {
      toast.error('500 Internal Server Error')
    } else if (isAxiosError(error)) {
      toast.error(error.message)
    } else {
      toast.error(error.detail || error.email[0])
    }
    return null
  }
}

const resendUserActivationEmailAction = async (email) => {
  try {
    const res = await resendUserActivationEmail(email)
    if (res instanceof Error || (res && res.detail) || (res.status === 'error')) {
      toast.error(res.message)
    } else {
      toast.success('Activation email has been sent')
    }
    return { response: { status: 'ok', path: '.' } }
  } catch (error) {
    if (error && error.response && error.response.status === 500) {
      toast.error('500 Internal Server Error')
    } else if (isAxiosError(error)) {
      toast.error(error.message)
    } else {
      toast.error(error.detail || error.email[0])
    }
    return null
  }
}

const resetPasswordEmailAction = async (email) => {
  try {
    await resetPasswordEmail(email)
    toast.success('Reset Password email has been sent')
    return { response: { status: 'ok', path: '.' } }
  } catch (error) {
    if (error && error.response && error.response.status === 500) {
      toast.error('500 Internal Server Error')
    } else if (isAxiosError(error)) {
      toast.error(error.message)
    } else {
      toast.error(error.detail || error.email[0])
    }
    return null
  }
}

export const userAction = (query) => async ({ request }) => {
  const method = request.method.toUpperCase()
  if (method === 'POST') {
    const formData = await request.formData()

    if (formData.has('sendResetPassword')) {
      return resetPasswordEmailAction(formData.get('sendResetPassword'))
    }

    if (formData.has('resend')) {
      return resendUserActivationEmailAction(formData.get('resend'))
    }

    if (formData.has('resetPassword')) {
      const { token, uid, password } = userUtils.getUser()
      // These are not in the getUser...
      formData.append('token', token)
      formData.append('uid', uid)
      formData.append('password', password)

      formData.forEach((value, key) => {
        console.log(`${key}: ${value}`)
      })

      return resetPasswordEmailAction(formData.get('resetPassword'))
    }
    const { institution } = userUtils.getUser()
    formData.append('institution', institution)
    return createUserAction(query, formData)
  } if (method === 'DELETE') {
    return deleteUserAction(query, request)
  }
  toast.error('Unsupported request method')
  return null
}
