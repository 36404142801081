import { defer } from 'react-router-dom'
import { usersQuery, userQuery } from '../queries/users-queries'

export const usersLoader = (queryClient) => () => defer({
  users: queryClient.ensureQueryData(usersQuery()),
})

export const userLoader = (queryClient) => ({ params }) => defer({
  user: queryClient.ensureQueryData(userQuery(params.id)),
})
