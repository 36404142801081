import React from 'react'

export default function BurgerMenu({ isOpen, setIsOpen }) {
  const hamburgerLine = 'h-0.5 w-8 my-1 rounded-full transition ease transform duration-300'

  const handleClick = () => setIsOpen((prevState) => !prevState)

  return (
    <div className="xl:hidden">
      <button
        type="button"
        onClick={handleClick}
        className="flex 3xl:hidden flex-col h-12 w-12 justify-center items-center group cursor-pointer"
      >
        <div className={`${hamburgerLine} ${isOpen ? 'hidden' : 'bg-white'}`} />
        <div className={`${hamburgerLine} ${isOpen ? 'hidden' : 'bg-white'}`} />
        <div className={`${hamburgerLine} ${isOpen ? 'hidden' : 'bg-white'}`} />
      </button>
    </div>
  )
}
