import { useTranslation } from 'react-i18next'

export default function ErrorPage() {
  const [t] = useTranslation()

  return (
    <div className="h-[100dvh] flex items-center justify-center p-5">
      <div className="flex flex-col items-center my-20 text-center">
        <p className="mb-8 text-3xl">{t('404pageNotFound')}</p>
        <p className="text-xl text-gray-500 max-w-[700px]">{t('404errorPageMessage')}</p>
      </div>
    </div>
  )
}
