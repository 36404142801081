import React from 'react'
import { useTranslation } from 'react-i18next'
import { BsGraphUpArrow } from 'react-icons/bs'
import ThresholdIndicatorGraph from './ThresholdIndicatorGraph'
import WhiteBox from '../UI/WhiteBox'
import cn from '../../utils/cn'

export default function MeasurementAnalytics({
  data, minHeight = null, maxHeight = 'max-h-[550px]',
}) {
  const [t] = useTranslation()
  const isDataEmpty = () => data === null || data === undefined || (typeof data === 'object' && !Object.keys(data).length)

  const {
    value, highThreshold, lowThreshold, parameter, unit,
  } = data
  const titleMessage = value > highThreshold || value < lowThreshold ? 'outOfRange' : 'withinRange'

  let concentrationLevel

  if (value < lowThreshold) {
    concentrationLevel = 'low'
  } else if (value > highThreshold) {
    concentrationLevel = 'high'
  } else {
    concentrationLevel = 'normal'
  }

  return (
    <WhiteBox width="w-full 2xl:w-1/2" hasShadow={false}>
      <div className="border border-gray-solitude rounded-3xl pt-9 pb-7 h-[550px]">
        <div
          className={`overflow-auto min-[1100px]:overflow-x-visible print:overflow-visible mr-4 h-full
            ${minHeight ? ` ${minHeight}` : ''
            }${maxHeight ? ` ${maxHeight}` : ''}`}
        >
          {!isDataEmpty() ? <>
            <div className="flex flex-col justify-center gap-y-9">
              <div className="mx-auto text-center">
                <h2 className="text-russianBlack text-[32px] font-medium">{t(titleMessage)} {parameter}</h2>
                <p className="text-gray-light text-2xl">01/10/2023</p>
              </div>
              <div className={cn('mx-auto text-center', {
                'text-goldenrod': concentrationLevel === 'low',
                'text-green-tea': concentrationLevel === 'normal',
                'text-red-vivid': concentrationLevel === 'high',
              })} >
                <p className="text-5xl font-medium mb-3">{value} {unit}</p>
                <p className="text-4xl capitalize">{concentrationLevel}</p>
              </div>
              <div className="mb-8">
                <ThresholdIndicatorGraph value={value} lowThreshold={lowThreshold} highThreshold={highThreshold} />
              </div>
            </div>
            <div className="mx-auto px-[60px] mt-[70px]">
              <p className="mb-3 text-russianBlack text-xl font-medium">{t('extraInfo')}</p>
              <p className="text-gray-light">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.</p>
            </div>
          </>
            : <div className="flex flex-col justify-center items-center gap-y-8 px-[104px] h-full text-gray-light">
              <div>
                <BsGraphUpArrow size={52} />
              </div>
              <div className="text-2xl">
                <p>{t('SelectResultToShowGraph')}</p>
              </div>
            </div>}
        </div>
      </div>
    </WhiteBox >
  )
}
