import { useNavigate } from 'react-router-dom'

const useLogout = () => {
  const navigate = useNavigate()
  const logout = () => {
    sessionStorage.removeItem('accessToken')
    sessionStorage.removeItem('refreshToken')
    sessionStorage.removeItem('user')
    navigate('login')
  }

  return { logout }
}

export default useLogout
