import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import WhiteBox from '../../../components/UI/WhiteBox'
import SingleRuleCard from '../../../components/Cards/SingleRuleCard'
import {
  riskFactors,
  clusteringApproach,
  semiEmpiricalApproach,
  firstResults,
} from '../../../dummy_data/patientData'
import Dot from '../../../components/UI/Dot'
import CasesUsedTable from '../../../components/Doctor/CasesUsedTable'

export default function SingleRulePage() {
  const location = useLocation()
  const { t } = useTranslation()

  const renderlist = (title, data) => {
    const list = Object.entries(data).map(([key, value]) => ({
      key,
      values: value,
    })).map((item) => {
      let display
      if (title === 'firstResults') {
        const value = item.values.value ? 'positive' : 'negative'
        display = <div className="flex items-center justify-center gap-2">
          <Dot bgColor={`bg-${value}`} shadow={`shadow-${value}`} size="w-2.5 h-2.5" />
          <p className="text-gray-light">{t(value)}</p>
        </div>
      } else {
        display = <span className="text-gray-light">{item.values.value}</span>
      }

      return <li key={item.key} className={`flex first:mt-0 mt-4 ${title !== 'riskFactors' ? 'pl-2' : ''}`}> <p className={title === 'oncoVoc' ? 'mr-8' : 'mr-4'}>{t(item.key)}</p>{display}</li >
    })
    return <>
      <h3 className="text-xl font-medium">{t(title)}</h3>
      {title === 'riskFactors' ? <div className="mt-5 border border-gray-solitude rounded-3xl"><ul className="py-4 pl-4 pr-32">{list}</ul></div> : <ul className="py-4">{list}</ul>}
    </>
  }

  const profile = location.state
  return (
    <div className="my-16 mx-14">
      <div className="grid grid-cols-2">
        <div className="col-span-1"><SingleRuleCard profile={profile} /></div>
        <div className="col-span-1 flex justify-end items-center gap-4 text-xl mr-4">
          <div>
            <button className="w-28 h-[50px] rounded-xl bg-transparent text-red-vivid border border-red-vivid">{t('cancel')}</button>
          </div>
          <div>
            <button className="w-[272px] h-[50px] rounded-xl bg-primary text-pale">{t('editRule')}</button>
          </div>
        </div>
      </div>
      <div className="mt-6 text-defaultText">
        <WhiteBox>
          <div className="grid grid-cols-2 py-6 px-8">
            <div className="flex col-span-1 gap-x-10 border-r border-r-gray-solitude">
              <div>
                {renderlist('riskFactors', riskFactors)}
              </div>
              <div className="px-5">
                <div>{renderlist('clusteringApproach', clusteringApproach)}</div>
                {/* <div>{renderlist('semiEmpiricalApproach', semiEmpiricalApproach)}</div> */}
                <div>{renderlist('firstResults', firstResults)}</div>
              </div>
            </div>
            <CasesUsedTable />
          </div>
        </WhiteBox>
      </div>
    </div>
  )
}
