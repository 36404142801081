import { useNavigate, useRouteError } from 'react-router-dom'
import Error from './ErrorMessage'

export default function GlobalAppError() {
  const error = useRouteError()
  const navigate = useNavigate()

  let errorMessage = ''
  let buttonText = ''
  let onClickHandler

  if (error.status === 404) {
    errorMessage = 'The page you are looking for was not found.'
    buttonText = 'goToHomePage'
    onClickHandler = () => navigate('/')
  } else {
    errorMessage = 'An error occurred while loading the application.'
    buttonText = 'reloadPage'
    onClickHandler = () => navigate(0)
  }

  return (
    <Error
      status={error.status}
      errorMessage={errorMessage}
      buttonText={buttonText}
      onClickHandler={onClickHandler}
    />
  )
}
