import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from '../../utils/cn'
import LabelledCheckbox from './LabelledCheckbox'
import Dot from './Dot'

export default function CheckboxWithSelectAndInputs({
  name, id, checked, onChange, isEditing = true, hasValueInput = false, value, type = 'default',
}) {
  // type can be default, approach, or onco
  const [t] = useTranslation()
  const [inputValue, setInputValue] = useState({
    operator: value ? value[0] : null,
    number: value ? value.slice(1) : '',
  })

  const isDefaultSelect = type === 'approach' || (type === 'default' && (name === 'age' || name === 'bmi' || name === 'activityLevel'))
  const isOncoSelect = type === 'onco'
  const isApproachExclusive = type === 'approach' || (type === 'default' && (name === 'age' || name === 'bmi' || name === 'activityLevel'))

  const handleChange = (event, state) => {
    const {
      id: elementId, type: elementType, checked: elementChecked, value: elementValue,
    } = event.target
    if (elementType === 'checkbox') {
      onChange(elementId, elementChecked, 'checkbox')
    } else {
      // issue with select. Select must not be NULL. Currently it sets null[number]
      const concatinatedValue = `${state.operator}${state.number}`
      console.log(concatinatedValue)
      const data = type === 'onco' ? elementValue : concatinatedValue
      onChange(elementId, data, 'input')
    }
  }

  const handleOperatorChange = (e) => {
    setInputValue((prevState) => {
      const newState = { ...prevState, operator: e.target.value }
      handleChange(e, newState)
      return newState
    })
  }

  const handleInputChange = (e) => {
    setInputValue((prevState) => {
      const newState = { ...prevState, number: e.target.value }
      handleChange(e, newState)
      return newState
    })
  }

  const displayUnit = () => {
    let unit
    switch (name) {
      case 'riskLevel':
        unit = '%'
        break
      case 'confidence':
        unit = '%'
        break
      case 'age':
        unit = 'years'
        break
      case 'bmi':
        unit = 'kg/m2'
        break
      case 'activityLevel':
        unit = 'min'
        break
      default:
        unit = ''
    }
    return unit
  }

  const createId = () => {
    let newId
    if (id === 'riskFactors.gender' || id === 'riskFactors.geneticFactors' || id === 'riskFactors.alcoholConsumption' || id === 'riskFactors.smokingStatus') {
      newId = id
    } else {
      newId = `${id}.selected`
    }
    return newId
  }

  return (
    <div className={hasValueInput ? 'grid grid-cols-2 gap-2 pr-10 whitespace-nowrap w-full' : ''}>
      <LabelledCheckbox name={name} id={createId()} checked={checked} onChange={handleChange} isEditing={isEditing} />
      {hasValueInput && checked && <div className="flex gap-1 col-span-1">
        <select value={inputValue.operator} onChange={handleOperatorChange} className={cn('outline-none border border-gray-light focus:border-cyan-dark rounded-lg p-1', {
          'text-gray-light': inputValue === null,
          'text-cyan-dark': inputValue !== '',
        })}>
          {isDefaultSelect && <>
            <option value={null} className="text-gray-light">{t('select')}</option>
            <option value={'>'}>{`> ${t('greaterThan')}`}</option>
            <option value={'<'}>{`> ${t('lessThan')}`}</option>
            <option value={'-'}>{`> ${t('threshold')}`}</option>
          </>}
          {isOncoSelect && <>
            <option value={true}><Dot bgColor="bg-positive" shadow="shadow-positive" size="w-1 h-1" /> {t('positive')}</option>
            <option value={false}><Dot bgColor="bg-negative" shadow="shadow-negative" /> {t('negative')}</option>
          </>}
        </select>
        {isApproachExclusive && <div className="flex items-baseline">
          <input id={`${id}.value`} value={inputValue.number} className="w-[30px] border-b border-b-gray-light outline-none" onChange={handleInputChange} />
          <span className="inline-block">{displayUnit()}</span>
        </div>}
      </div>}
    </div >
  )
}
