/* eslint-disable indent */
export default function BasicCard({
  name = null, styles, children, rounded = true, shadow = false, alignment = 'justify-center',
}) {
  return (
    <div
      className={`card w-full bg-white border border-gray-solitude${rounded ? ' rounded-3xl' : ''
        }${shadow ? ' shadow-card' : ''}${styles ? ` ${styles}` : ''}`}
    >
      <div className="p-0 card-body">
        <div className={`flex ${alignment} min-h-12 bg-primary text-white text-xl ${rounded ? 'rounded-t-3xl' : ''
          } pt-3 pb-3`}>{name && <h2>
            {name}
          </h2>}</div>
        {children}
      </div>
    </div>
  )
}
