import { useNavigate } from 'react-router-dom'
import {
  useTable,
  useGlobalFilter,
  usePagination,
} from 'react-table'
import { BsFilter } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import Pagination from './Pagination'
import TableTitle from './TableTitle'
import WhiteBox from '../UI/WhiteBox'
import Search from './Search'
import MainButton from '../UI/MainButton'
import RenderIf from '../UI/RenderIf'
import NoEntriesFoundMessage from '../UI/NoEntriesFoundMessage'
import cn from '../../utils/cn'

export default function Table({
  title,
  hasAddButton = false,
  hasImportButton = false,
  buttonLabel,
  minHeight = null,
  maxHeight = null,
  columns,
  data,
  rowsPerPage = 5,
  type = 'default',
  dropdownOption,
  setDropdownOption,
  screen,
  setShowModal,
  rounded = true,
  pathKey,
  enabledNavigation = false,
  className,
  hasSearch,
  hasPagination = true,
  hasTitleInsideWhiteBox = true,
}) {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex, pageSize },
    setPageSize,
    gotoPage,
    rows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: rowsPerPage,
      },
    },
    useGlobalFilter,
    usePagination,
  )

  const choosePath = ({ key, rowData }) => {
    console.log(key, rowData.id)

    switch (key) {
      case 'medicalStaffCases':
        return `/medical-staff/cases/${rowData.id}/early-diagnostics`
      case 'doctorManagerPatients':
        return `/lab-technician/patients/${rowData.id}/lipoproteins`
      case 'rules':
        return `/rules/${rowData.id}`
      default:
        return ''
    }
  }

  const tableBody = hasPagination ? page : rows
  const renderTitleComponent = () => <TableTitle
    title={title}
    type={type}
    buttonLabel={buttonLabel}
    hasAddButton={hasAddButton}
    hasImportButton={hasImportButton}
    dropdownOption={dropdownOption}
    setDropdownOption={setDropdownOption}
    setGlobalFilter={setGlobalFilter}
    rounded={rounded}
    setShowModal={setShowModal}
    hasSearch={hasSearch}
  />

  const renderTable = () => <>
    {hasTitleInsideWhiteBox && renderTitleComponent()}
    <div
      className={`overflow-x-auto min-[1100px]:overflow-x-visible print:overflow-visible pb-6 ${minHeight ? ` ${minHeight}` : ''
        // eslint-disable-next-line indent
        }${maxHeight ? ` ${maxHeight}` : ''}`}
    >
      {screen === 'CasesPage' && hasSearch && (
        <div className="flex w-full px-5 pb-10 ">
          <Search setFilter={setGlobalFilter} />
          <div className="ml-2">
            <MainButton
              text={t('filters')}
              onClick={() => { }}
              isOutline
              textColor="primary"
            >
              <BsFilter className="mr-2 text-2xl" />
            </MainButton>
          </div>
        </div>
      )}
      <table
        {...getTableProps()}
        className={cn('w-full border-collapse mx-8 py-2 z-1 print:min-w-[400px] print:max-w-[1100px] text-[#959292]', className)}
        style={{ maxWidth: 'calc(100% - 64px)' }}
      >
        <thead>
          {headerGroups.map((headerGroup, idx) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  style={{ width: 'auto' }}
                  {...column.getHeaderProps()}
                  className={cn('px-2 2xl:px-0 text-left capitalize text-[#4D4D4D] text-lg font-semibold border-b border-b-gray-light pb-5 whitespace-nowrap', {
                    'text-gray-light': title === 'casesUsed',
                  })}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {tableBody.map((row, rowIndex) => {
            prepareRow(row)
            return (
              <tr
                key={rowIndex}
                className={cn('transition-colors duration-200 text-black hover:bg-[rgba(0,0,0,0.05)] border-b border-b-gray-line', {
                  'cursor-pointer': enabledNavigation,
                  'text-defaultText': title === 'casesUsed',
                })}
                {...row.getRowProps()}
              >
                {row.cells.map((cell, idx) => (
                  <td
                    key={idx}
                    {...cell.getCellProps()}
                    className="px-2 2xl:px-0 h-[50px]"
                    onClick={() => enabledNavigation
                      && navigate(
                        choosePath({
                          key: pathKey,
                          rowData: row.original,
                        }),
                      )
                    }
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
      <RenderIf isTrue={rows.length === 0}>
        <div
          className="flex items-center justify-center h-[75%] py-8 lg:py-0"
        >
          <NoEntriesFoundMessage text="noEntriesFound" />
        </div>
      </RenderIf>

    </div>

    {hasPagination && <Pagination
      pageLength={page.length}
      nextPage={nextPage}
      canNextPage={canNextPage}
      previousPage={previousPage}
      canPreviousPage={canPreviousPage}
      pageOptions={pageOptions}
      pageIndex={pageIndex}
      pageSize={pageSize}
      setPageSize={setPageSize}
      gotoPage={gotoPage}
    />
    }
  </>
  if (hasTitleInsideWhiteBox) {
    return <WhiteBox>{renderTable()}</WhiteBox>
  }
  return (
    <div >
      <TableTitle
        title={title}
        type={type}
        buttonLabel={buttonLabel}
        hasAddButton={hasAddButton}
        hasImportButton={hasImportButton}
        dropdownOption={dropdownOption}
        setDropdownOption={setDropdownOption}
        setGlobalFilter={setGlobalFilter}
        rounded={rounded}
        setShowModal={setShowModal}
        hasSearch={hasSearch}
        isInsideWhiteBox={hasTitleInsideWhiteBox}
      />
      <WhiteBox hasShadow={false} otherStyle="border border-gray-solitude pt-7">{renderTable()}</WhiteBox>
    </div >
  )
}
