import { useTranslation } from 'react-i18next'

export default function DropdownItem({ text, onClickHandler }) {
  const { t } = useTranslation()
  return (
    <button
      type="button"
      onClick={onClickHandler}
      className="flex items-center w-full gap-3 px-4 py-3 capitalize bg-white transition-colors duration-200 hover:bg-[rgba(0,0,0,0.05)] cursor-pointer border-b border-b-gray-line last:border-b-0 text-defaultText"
    >
      {t(text)}
    </button>
  )
}
