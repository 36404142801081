import * as yup from 'yup'

const validationSchema = {
  login: yup.object({
    email: yup.string().trim().email('invalidEmailFormat').required('emailRequired'),
    password: yup.string().trim().required('passwordRequired'),
  }),
  forgotPassword: yup.object({
    email: yup.string().trim().email('invalidEmailFormat').required('emailRequired'),
  }),
  resetPassword: yup.object({
    uid: yup.string().trim().required(),
    token: yup.string().trim().required(),
    password: yup.string().trim().required('passwordRequired'),
    confirmPassword: yup.string()
      .trim()
      .required('confirmPasswordRequired')
      .oneOf([yup.ref('password')], 'passwordsMustMatch'),
  }),
  updateProfile: yup.object({
    email: yup.string().trim().email('invalidEmailFormat').required('emailRequired'),
  }),
  createUser: yup.object({
    email: yup.string().trim().email('invalidEmailFormat').required('emailRequired'),
    userType: yup.string().trim().required('userTypeRequired'),
    firstName: yup.string().trim().required('firstNameRequired'),
    lastName: yup.string().trim().required('lastNameRequired'),
    specialty: yup
      .string()
      .when('userType', {
        is: 'doctor',
        then: () => yup.string().trim().required('doctorSpecialtyRequired'),
      }),
  }),
  createPatient: yup.object({
    email: yup.string().trim().email('invalidEmailFormat').required('emailRequired'),
    firstName: yup.string().trim().required('firstNameRequired'),
    lastName: yup.string().trim().required('lastNameRequired'),
    dateOfBirth: yup.string().trim().optional(),
    phone: yup.string().trim().required('phoneIsRequired'),
    gender: yup.string().trim().optional(),
  }),
  updatePatient: yup.object({
    id: yup.string().trim().optional(),
    email: yup.string().trim().email('invalidEmailFormat').required('emailRequired'),
    firstName: yup.string().trim().required('firstNameRequired'),
    lastName: yup.string().trim().required('lastNameRequired'),
    dateOfBirth: yup.string().trim().optional(),
    phone: yup.string().trim().required('phoneIsRequired'),
    gender: yup.string().trim().optional(),
  }),
  createCase: yup.object({
    caseName: yup.string().trim().required('caseNameRequired'),
    caseStatus: yup.string().trim().required('caseStatusRequired'),
    patient: yup.string().trim().required('patientRequired'),
    caseDate: yup.string().trim().required('caseDateRequired'),
  }),
  createRule: yup.object({
    ruleName: yup.string().trim().required('ruleNameRequired'),
  }),
  updateRule: yup.object({
    id: yup.string().trim().optional(),
    ruleName: yup.string().trim().required('ruleNameRequired'),
  }),
  activateAccount: yup.object({
    uid: yup.string().trim().required(),
    token: yup.string().trim().required(),
  }),
}

export default validationSchema
