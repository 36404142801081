import { useLoaderData, Await } from 'react-router-dom'
import { Suspense } from 'react'
import UsersTable from './UsersTable'
import useQueryData from '../../../hooks/useQueryData'
import { usersQuery } from '../../../pages/private_pages/maintainer_pages/queries/users-queries'

export default function UsersLoader() {
  const { users } = useLoaderData()
  const { data: usersData } = useQueryData({ initialData: users, query: usersQuery })

  return (
    <Suspense fallback={<p>Loading...</p>}>
      <Await
        resolve={users}
        errorElement={<p>Failed load data</p>}
      >
        <UsersTable users={usersData} title="allUsers" />
      </Await>
    </Suspense>
  )
}
