import { useNavigate } from 'react-router-dom'
import { FaUserDoctor } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'
import { BsChevronUp, BsChevronDown } from 'react-icons/bs'
import Dropdown from './Dropdown'
import DropdownItem from './DropdownItem'
import useToggle from '../../hooks/useToggle'
import userUtils from '../../utils/users-utils'
import navUtils from '../../utils/nav-utils'
import cn from '../../utils/cn'

export default function NavOptionsDropdown({ onOpenModal }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { ref, isOpen, setIsOpen } = useToggle()
  const handleDropdownMenu = () => setIsOpen((prevState) => !prevState)
  const user = userUtils.getUser()
  const chosenRoute = navUtils.chooseRoute(user?.role)

  return (
    <div ref={ref} className="relative z-40 items-center hidden h-full xl:flex">
      <button type="button" onClick={handleDropdownMenu} className = {cn('flex items-center gap-2 text-xl text-white', { 'text-secondary': isOpen })}>
        <FaUserDoctor/>
        {t('profile')}
        {isOpen ? <BsChevronUp/> : <BsChevronDown/> }
      </button>
      <Dropdown isOpen={isOpen}>
        <DropdownItem
          onClickHandler={() => {
            navigate(`${chosenRoute}/profile`)
            setIsOpen(false)
          }}
          text="Account settings"
        />
        <DropdownItem
          onClickHandler={onOpenModal}
          text="logout"
        />
      </Dropdown>
    </div>
  )
}
