import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import RootRouter from './components/RootRouter'
import LoginPage from './pages/public_pages/LoginPage'
import EarlyDiagnostics from './components/Patient/EarlyDiagnostics'
import Colonoscopy from './components/Patient/Colonoscopy'
import checkAuthLoader from './pages/public_pages/loaders/protectedPageLoader'
import {
  activateAccountAction,
  forgotPasswordAction,
  loginAction,
  resetPasswordConfirmAction,
} from './pages/public_pages/actions/authActions'
import 'react-toastify/dist/ReactToastify.css'
import SplashScreen from './pages/public_pages/SplashScreen'
import { diagnosticLoader } from './pages/loaders/pageLoaders'
import Reports from './components/Patient/Reports'
import MaintainerUsersPage from './pages/private_pages/maintainer_pages/MaintainerUsersPage'
import MaintainerHomePage from './pages/private_pages/maintainer_pages/MaintainerHomePage'
import RenderIf from './components/UI/RenderIf'
import MedicalStaffPatientPage from './pages/private_pages/medical_staff_pages/MadicalStaffPatientPage'
import RoleBaseAccessControl from './routes/RoleBaseAccessControl'
import roles from './routes/roles'
import PublicRoutes from './routes/PublicRoutes'
import CasesPage from './pages/private_pages/medical_staff_pages/CasesPage'
import ErrorPage from './pages/private_pages/shared_pages/ErrorPage'
import ProfilePage from './pages/private_pages/shared_pages/ProfilePage'
import UnauthorizedPage from './pages/private_pages/shared_pages/UnauthorizedPage'
import MedicalStaffHomePage from './pages/private_pages/medical_staff_pages/MedicalStaffHomePage'
import { usersLoader } from './pages/private_pages/maintainer_pages/loaders/users-loaders'
import { userAction } from './pages/private_pages/maintainer_pages/actions/users_actions'
import DoctorManagerHomePage from './pages/private_pages/doctor_manager_pages/DoctorManagerHomePage'
import DoctorManagerPatientsPage from './pages/private_pages/doctor_manager_pages/DoctorManagerPatientsPage'
import DoctorManagerCasesPage from './pages/private_pages/doctor_manager_pages/DoctorManagerCasesPage'
import { patientsLoader } from './pages/private_pages/doctor_manager_pages/loaders/patients-loaders'
import { patientActions } from './pages/private_pages/doctor_manager_pages/actions/patient-actions'
import { casesLoader } from './pages/private_pages/doctor_manager_pages/loaders/cases-loaders'
import { caseActions } from './pages/private_pages/doctor_manager_pages/actions/case-actions'
import ActivateAccountPage from './pages/public_pages/ActivateAccountPage'
import GlobalAppError from './components/UI/GlobalAppError'
import ForgotPasswordPage from './pages/public_pages/ForgotPasswordPage'
import ResetPasswordPage from './pages/public_pages/ResetPasswordPage'
import LabTechnicianHomePage from './pages/private_pages/lab_technician_pages/LabTechnicianHomePage'
import LabTechnicianPatientsPage from './pages/private_pages/lab_technician_pages/LabTechnicianPatientsPage'
import Lipoproteins from './components/LabTechnician/Lipoproteins'
import Metabolites from './components/LabTechnician/Metabolites'
import Glycoproteins from './components/LabTechnician/Glycoproteins'
import LabTechnicianSinglePatientPage from './pages/private_pages/lab_technician_pages/LabTechnicianSinglePatientPage'
import Finals from './components/Patient/Finals'
import RulesPage from './pages/private_pages/doctor_pages/RulesPage'
import SingleRulePage from './pages/private_pages/doctor_pages/SingleRulePage'
import { reportsLoader } from './loaders/reports/reports-loader'
import { deleteAction } from './actions/reports-action'

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 20000,
      },
    },
  })

  const router = (query) => createBrowserRouter([
    {
      path: '/',
      element: <PublicRoutes />,
      errorElement: <GlobalAppError />,
      children: [
        { index: true, element: <SplashScreen /> },
        {
          path: 'login',
          element: <LoginPage />,
          action: loginAction,
        },
        {
          path: 'activate',
          element: <ActivateAccountPage />,
          action: activateAccountAction,
        },
        {
          path: 'forgot-password',
          element: <ForgotPasswordPage />,
          action: forgotPasswordAction,
        },
        {
          path: 'reset-password',
          element: <ResetPasswordPage />,
          action: resetPasswordConfirmAction,
        },
        { path: '*', element: <ErrorPage /> },
      ],
    },
    {
      element: <RootRouter />,
      loader: checkAuthLoader,
      children: [
        {
          element: <RoleBaseAccessControl allowedRoles={[roles.Doctor, roles.Nurse]} />,
          children: [
            {
              element: <MedicalStaffHomePage />,
              index: true,
              path: 'medical-staff',
            },
            {
              path: 'medical-staff/profile',
              element: <ProfilePage />,
            },
            {
              path: 'medical-staff/cases',
              element: <CasesPage />,
            },
            {
              path: 'medical-staff/cases/:id',
              element: <MedicalStaffPatientPage />,
              children: [
                {
                  path: 'early-diagnostics',
                  element: <EarlyDiagnostics />,
                  // loader: ({ params }) => diagnosticLoader(queryClient, params.id),
                },
                { path: 'colonoscopy', element: <Colonoscopy /> },
                {
                  path: 'reports',
                  element: <Reports />,
                  loader: reportsLoader(queryClient),
                  action: deleteAction(queryClient),
                },
                { path: 'finals', element: <Finals /> },
              ],
            },
            {
              path: 'medical-staff/rules/',
              element: <RulesPage />,
            },
            {
              path: 'medical-staff/rules/:id',
              element: <SingleRulePage />,
            },
          ],
        },
        {
          element: <RoleBaseAccessControl allowedRoles={[roles.UserMaintainer]} />,
          children: [
            {
              element: <MaintainerHomePage />,
              index: true,
              action: userAction(query),
              path: 'maintainer',
            },
            {
              path: 'maintainer/profile',
              element: <ProfilePage />,
            },
            {
              path: 'maintainer/users',
              action: userAction(query),
              loader: usersLoader(query),
              element: <MaintainerUsersPage />,
            },
          ],
        },
        {
          element: <RoleBaseAccessControl allowedRoles={[roles.DoctorManager]} />,
          children: [
            {
              element: <DoctorManagerHomePage />,
              index: true,
              path: 'doctor-manager',
            },
            {
              element: <DoctorManagerPatientsPage />,
              path: 'doctor-manager/patients',
              // loader: patientsLoader(query),
              action: patientActions(query),
            },
            {
              element: <DoctorManagerCasesPage />,
              path: 'doctor-manager/cases',
              // loader: casesLoader(query),
              action: caseActions(query),
            },
            {
              path: 'doctor-manager/profile',
              element: <ProfilePage />,
            },
          ],
        },
        {
          element: <RoleBaseAccessControl allowedRoles={[roles.LabTechnician]} />,
          children: [
            {
              element: <LabTechnicianHomePage />,
              index: true,
              path: 'lab-technician',
            },
            {
              element: <LabTechnicianPatientsPage />,
              // loader: patientsLoader(query),
              path: 'lab-technician/patients',
            },
            {
              element: <LabTechnicianSinglePatientPage />,
              path: 'lab-technician/patients/:id',
              children: [
                { element: <Lipoproteins />, path: 'lipoproteins' },
                { element: <Metabolites />, path: 'metabolites' },
                { element: <Glycoproteins />, path: 'glycoproteins' },
              ],
            },
            {
              path: 'lab-technician/profile',
              element: <ProfilePage />,
            },
          ],
        },
        { path: '*', element: <ErrorPage /> },
        { path: 'unauthorized', element: <UnauthorizedPage /> },
      ],
    },
  ])

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ maxWidth: '450px', width: '100%' }}
      />
      <QueryClientProvider client={queryClient}>
        <RenderIf isTrue={process.env.NODE_ENV === 'development'}>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </RenderIf>
        <RouterProvider router={router(queryClient)} />
      </QueryClientProvider>
    </>
  )
}
