import React from 'react'
// import { CgScrollH } from 'react-icons/cg'
// import PrintButton from '../UI/PrintButton'
import { TbFileImport } from 'react-icons/tb'
import { useTranslation } from 'react-i18next'
import { BsFilter } from 'react-icons/bs'
import Search from './Search'
import cn from '../../utils/cn'
import MainButton from '../UI/MainButton'

export default function TableTitle({
  title,
  type,
  hasAddButton,
  hasImportButton,
  buttonLabel,
  dropdownOption,
  setDropdownOption,
  setGlobalFilter,
  setShowModal,
  rounded,
  numberOfResults,
  hasSearch = true,
  isInsideWhiteBox = true,
}) {
  const [t] = useTranslation()

  return (
    <div
      className={cn(
        'print:hidden bg-white flex flex-wrap md:flex-nowrap gap-3 justify-between',
        {
          'flex-col-reverse items-start md:flex-row bg-primary': type === 'main',
          'rounded-t-3xl': rounded,
          'items-center py-7 px-2 md:px-5 mb-8': isInsideWhiteBox,
          'items-top pb-2 mb-4': !isInsideWhiteBox,
        },
      )}
    >
      {title && <h2 className={cn('ml-2 text-2xl font-semibold', {
        'text-defaultText': type !== 'main' && title !== 'casesUsed',
        'text-white': type === 'main' && title !== 'casesUsed',
        'text-defaultText text-xl': title === 'casesUsed',
      })}>{t(title)}{title === 'casesUsed' ? ` (${numberOfResults || 0})` : ''}</h2>}
      {type === 'main' && hasSearch && <Search setFilter={setGlobalFilter} />}
      {
        type !== 'minimal' && type !== 'main' && (
          <>
            <div className="flex w-full gap-2 md:w-auto">
              {/* <PrintButton /> */}
              {/* <CgScrollH size={35} className="ml-auto md:hidden text-grey-pale" /> */}
              {type === 'select' && (
                <select
                  value={dropdownOption}
                  onChange={(event) => setDropdownOption && setDropdownOption(event.target.value)
                  }
                  className="w-full sm:w-max bg-white flex items-center gap-2 border-[1px] border-solid border-[#BEBEBE] rounded-lg text-sm text-pale px-1 h-[36px] outline-none"
                >
                  <option value="">Option 1</option>
                </select>
              )}
            </div>
            {title !== 'casesUsed' && hasSearch && <Search setFilter={setGlobalFilter} />}
            {title === 'casesUsed' && hasSearch && (
              <div className={`flex ${isInsideWhiteBox ? 'px-5' : 'pl-5'}`}>
                <Search setFilter={setGlobalFilter} />
                <div className="ml-2">
                  <MainButton
                    text={t('filters')}
                    onClick={() => { }}
                    isOutline
                    textColor="primary"
                  >
                    <BsFilter className="mr-2 text-2xl" />
                  </MainButton>
                </div>
              </div>
            )}
          </>
        )
      }
      {isInsideWhiteBox && <div className="flex justify-end gap-4">
        {hasImportButton
          && <button type="button" className="font-normal text-xl text-defaultText bg-gray-solitude py-2 px-4 flex items-center justify-center whitespace-nowrap border rounded-xl border-gray-dark">
            <TbFileImport className="text-black mr-2" /> {t('import')}
          </button>}
        {hasAddButton && <MainButton
          text={buttonLabel}
          textColor="primary"
          onClick={() => setShowModal(true)}
          className="w-full sm:max-w-[200px] text-white rounded-[12px]"
        > + </MainButton>}
      </div>}
    </div >
  )
}
