import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Form, useSubmit } from 'react-router-dom'
import MainButton from '../UI/MainButton'
import LoginInput from './Input'
import validationSchema from '../../utils/validation-schema'
import SelectInput from './SelectInput'
import CheckboxWithSelectAndInputs from '../UI/CheckboxWithSelectAndInputs'
import RiskFactorInputs from './RiskFactorInputs'
import FirstResultInputs from './FirstResultInputs'

export default function CreateRuleForm({ onClose, rule, rules }) {
  const [t] = useTranslation()
  const method = rule ? 'patch' : 'post'
  const submit = useSubmit()
  const riskFactors = {
    gender: false,
    age: {
      value: '',
      selected: false,
    },
    bmi: {
      value: '',
      selected: false,
    },
    geneticFactors: false,
    activityLevel: {
      value: '',
      selected: false,
    },
    alcoholConsumption: false,
    smokingStatus: false,
    type2Diabetes: false,
    crohnsDisease: false,
    ibd: false,
    dietFactors: false,
    carcinogenExposure: false,
  }

  const clusteringApproach = {
    riskLevel: {
      value: '',
      selected: false,
    },
    confidence: {
      value: '',
      selected: false,
    },
  }

  const semiEmpiricalApproach = {
    riskLevel: {
      value: '',
      selected: false,
    },
    confidence: {
      value: '',
      selected: false,
    },
  }

  const firstResults = {
    oncoVOC: {
      value: false,
      selected: false,
    },
    oncoNMR: {
      value: false,
      selected: false,
    },
    oncoCRISPR: {
      value: false,
      selected: false,
    },
    oncoCTC: {
      value: false,
      selected: false,
    },
  }

  const formik = useFormik({
    initialValues: {
      id: rule && rule.id ? rule.id : null,
      ruleName: rule && rule.name ? rule.name : null,
      createdBy: rule && rule.createdBy ? rule.createdBy : null,
      dateCreated: rule && rule.dateCreated ? rule.dateCreated : null,
      status: rule && rule.status ? rule.status : null,
      riskFactors: {
        gender: riskFactors && riskFactors.gender ? riskFactors.gender : false,
        age: {
          selected: riskFactors && riskFactors.age.selected ? riskFactors.age.selected : false,
          value: riskFactors && riskFactors.age.value ? riskFactors.age.value : null,
        },
        bmi: {
          selected: riskFactors && riskFactors.bmi.selected ? riskFactors.bmi.selected : false,
          value: riskFactors && riskFactors.bmi.value ? riskFactors.bmi.value : null,
        },
        activityLevel: {
          selected: riskFactors && riskFactors.activityLevel.selected ? riskFactors.activityLevel.selected : null,
          value: riskFactors && riskFactors.activityLevel.value ? riskFactors.activityLevel.value : null,
        },
        smokingStatus: riskFactors && riskFactors.smokingStatus ? riskFactors.smokingStatus : false,
        geneticFactors: riskFactors && riskFactors.geneticFactors ? riskFactors.geneticFactors : false,
        alcoholConsumption: riskFactors && riskFactors.alcoholConsumption ? riskFactors.alcoholConsumption : false,
        type2Diabetes: riskFactors && riskFactors.type2Diabetes ? riskFactors.type2Diabetes : false,
        crohnsDisease: riskFactors && riskFactors.crohnsDisease ? riskFactors.crohnsDisease : false,
        ibd: riskFactors && riskFactors.ibd ? riskFactors.ibd : false,
        dietFactors: riskFactors && riskFactors.dietFactors ? riskFactors.dietFactors : false,
        carcinogenExposure: riskFactors && riskFactors.carcinogenExposure ? riskFactors.carcinogenExposure : false,
      },
      clusteringApproach: {
        riskLevel: {
          selected: clusteringApproach && clusteringApproach.riskLevel.selected ? clusteringApproach.riskLevel.selected : false,
          value: clusteringApproach && clusteringApproach.riskLevel.value ? clusteringApproach.riskLevel.value : null,
        },
        confidence: {
          selected: clusteringApproach && clusteringApproach.confidence.selected ? clusteringApproach.confidence.selected : false,
          value: clusteringApproach && clusteringApproach.confidence.value ? clusteringApproach.confidence.value : null,
        },
      },
      semiEmpiricalApproach: {
        riskLevel: {
          selected: semiEmpiricalApproach && semiEmpiricalApproach.riskLevel.selected ? semiEmpiricalApproach.riskLevel.selected : false,
          value: semiEmpiricalApproach && semiEmpiricalApproach.riskLevel.value ? semiEmpiricalApproach.riskLevel.value : null,
        },
        confidence: {
          selected: semiEmpiricalApproach && semiEmpiricalApproach.confidence.selected ? semiEmpiricalApproach.confidence.selected : false,
          value: semiEmpiricalApproach && semiEmpiricalApproach.confidence.value ? semiEmpiricalApproach.confidence.value : null,
        },
      },
      firstResults: {
        oncoVOC: {
          selected: firstResults && firstResults.oncoVOC.selected ? firstResults.oncoVOC.selected : false,
          value: firstResults && firstResults.oncoVOC.value ? firstResults.oncoVOC.value : false,
        },
        oncoNMR: {
          selected: firstResults && firstResults.oncoNMR.selected ? firstResults.oncoNMR.selected : false,
          value: firstResults && firstResults.oncoNMR.value ? firstResults.oncoNMR.value : false,
        },
        oncoCRISPR: {
          selected: firstResults && firstResults.oncoCRISPR.selected ? firstResults.oncoCRISPR.selected : false,
          value: firstResults && firstResults.oncoCRISPR.value ? firstResults.oncoCRISPR.value : false,
        },
        oncoCTC: {
          selected: firstResults && firstResults.oncoCTC.selected ? firstResults.oncoCTC.selected : false,
          value: firstResults && firstResults.oncoCTC.value ? firstResults.oncoCTC.value : false,
        },
      },
    },
    validationSchema: method === 'post' ? validationSchema.createRule : validationSchema.updateRule,
    onSubmit: async (values, actions) => {
      submit(values, { method })
      actions.resetForm()
      onClose()
    },
  })

  const handleChange = (id, value, type) => {
    const [parent, field] = id.split('.')
    if (type === 'checkbox') {
      const inputName = field === 'gender' || field === 'geneticFactors' || field === 'alcoholConsumption' || field === 'smokingStatus' || field === 'type2Diabetes' || field === 'crohnsDisease' || field === 'ibd' || field === 'dietFactors' || field === 'carcinogenExposure' ? `${parent}.${field}` : `${parent}.${field}.selected`
      console.log(inputName)
      formik.setFieldValue(inputName, value)
      if (!value && inputName === parent[field]) { formik.setFieldValue(`${parent}.${field}.value`, null) }
    } else {
      formik.setFieldValue(`${parent}.${field}.value`, value)
    }
  }

  const renderApproach = () => {
    const approach = ['clusteringApproach', 'semiEmpiricalApproach']
    const checkboxes = ['riskLevel', 'confidence']

    return approach.map((item) => <div className="mb-2">
      <h3 className="font-medium text-xl pb-2 text-cyan-dark">{t(item)}</h3>
      {checkboxes.map((cb) => <div className="my-2" key={cb}>
        <CheckboxWithSelectAndInputs
          name={cb}
          id={`${item}.${cb}`}
          hasValueInput
          value={formik.values[item][cb].value}
          type="approach"
          checked={formik.values[item][cb].selected}
          onChange={handleChange}
        />
      </div>)}
    </div>)
  }

  const labelStyle = 'text-cyan-dark'

  return (
    <Form onSubmit={formik.handleSubmit} method={method} className="flex flex-col justify-between h-full">
      <div>
        <div className="grid gap-4 md:grid-cols-2">
          <LoginInput
            type="text"
            name="ruleName"
            label={t('ruleName')}
            labelStyle={labelStyle}
            placeholder=""
            value={formik.values.ruleName}
            onChange={formik.handleChange}
            error={!!(formik.touched.ruleName && formik.errors.ruleName)}
            errorMessage={formik.errors.ruleName}
          />
          <SelectInput
            name="copyAttributesFromAnotherRule"
            label={t('copyAttributesFromAnotherRule')}
            labelStyle={labelStyle}
            placeholder=""
            value={formik.values.rules}
            onChange={formik.handleChange}
            error={!!(formik.touched.rules && formik.errors.rules)}
            errorMessage={formik.errors.rules}>
            <option value="">{t('chooseOneOption')}</option>
            {rules.map((r) => <option value={r.id}>{r.ruleName}</option>)}
          </SelectInput>
        </div>
        <div className="mt-4 grid gap-4 md:grid-cols-2">
          {/* Risk Factors */}
          <RiskFactorInputs riskFactors={formik.values.riskFactors} onChange={handleChange} />
          {/* Second Column */}
          <div className="col-span-1 pt-4">
            <div>
              {renderApproach()}
              <div className="mt-2">
                <FirstResultInputs firstResults={formik.values.firstResults} onChange={handleChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-end w-full gap-4 mt-9 md:flex-row">
        <MainButton
          text={t('cancel')}
          type="button"
          onClick={onClose}
          className="rounded-xl"
          isCancel
        />
        <MainButton text={method === 'post' ? t('create') : t('edit')} type="submit" className="w-full md:max-w-[270px] rounded-xl" />
      </div>
    </Form>
  )
}
