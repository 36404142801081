import { t } from 'i18next'
import { CSVLink } from 'react-csv'
import { FaFilePdf } from 'react-icons/fa6'

export default function ShowExcelFileButton({ data = [], componentName = 'test' }) {
  return (
    <CSVLink
      data={data}
      filename={`${componentName}.csv`}
      className="flex items-center gap-x-2 text-neutral-content bg-primary absolute top-0 right-0 rounded-xl px-5 py-3"
      target="_blank"
    >
      <FaFilePdf /><span>{t('showExcelFile')}</span>
    </CSVLink>
  )
}
