const tokenUtils = {
  saveTokenInSession: (token) => {
    sessionStorage.setItem('accessToken', token.access)
    sessionStorage.setItem('refreshToken', token.refresh)
  },

  getAccessTokenFromSession: () => sessionStorage.getItem('accessToken'),

  getRefreshTokenFromSession: () => sessionStorage.getItem('refreshToken'),

  refreshToken: (token) => sessionStorage.setItem('accessToken', token),

  removeTokenFromSession: () => {
    sessionStorage.removeItem('accessToken')
    sessionStorage.removeItem('refreshToken')
  },
}

export default tokenUtils
