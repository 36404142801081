import React from 'react'
import { useTranslation } from 'react-i18next'
import SectionTitle from './UI/SectionTitle'
import WhiteBox from './UI/WhiteBox'
import notifications from '../dummy_data/NotificationData'

export default function Notifications() {
  const [t] = useTranslation()
  const distance = 'px-8'
  const hr = 'text-gray-light my-4 rounded-sm'

  return (
    <WhiteBox>
      <div className="max-h-[470px] xl:max-h-[300px] pb-4">
        <SectionTitle
          text={t('yourNotifications')}
          marginBottom="mb-4"
          styles={`bg-primary text-white rounded-t-3xl pt-4 pb-2 ${distance}`}
        />
        <div className={`xl:h-[400px] 2xl:h-[200px] overflow-auto ${distance}`}>
          <ul className="mt-6 text-justify">
            {notifications
              .filter((not, index) => index < 3)
              .map((not, index) => (
                <li key={index}>
                  <div className="flex mt-4 text-sm gap-x-20 xl:text-xs">
                    <p className="text-gray-light xl:overflow-hidden xl:max-h-8 xl:text-ellipsis xl:block xl:w-[320px] 2xl:w-[420px] xl:whitespace-nowrap">
                      {t(not.text)}
                    </p>
                    <p>{not.time}</p>
                  </div>
                  <hr className={hr} />
                </li>
              ))}
            <li>
              <span className="flex justify-end px-4 my-2 text-sm underline text-secondary">
                {t('viewAllUpdates')}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </WhiteBox>
  )
}
