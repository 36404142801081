import axiosInstance from '../../utils/axios-instances'

// eslint-disable-next-line import/prefer-default-export
export const getReports = async () => {
  const response = await axiosInstance.get('/diagnostic-report/')
  return response.data
}

export const getDocDetails = async (id) => {
  const response = await axiosInstance.get(`/diagnostic-report/${id}/get_document_reference/`)
  return response.data
}

export const getDocFile = async (id) => {
  const response = await axiosInstance.get(`/diagnostic-report/${id}/get_file/`, {
    responseType: 'blob',
  })
  return response.data
}

export const deleteReport = async (id) => axiosInstance.delete(`/diagnostic-report/${id}/`)
