/* eslint-disable import/prefer-default-export */
import { redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  activateAccount,
  forgotPassword,
  login,
  resetPasswordConfirm,
} from '../../../services/auth/auth-service'
import userUtils from '../../../utils/users-utils'
import tokenUtils from '../../../utils/token-utils'
import navUtils from '../../../utils/nav-utils'

export const loginAction = async ({ request }) => {
  const data = await request.formData()

  const userLoginData = {
    email: data.get('email'),
    password: data.get('password'),
  }

  try {
    await login(userLoginData)
    const user = userUtils.getUser()
    const chosenRoute = navUtils.chooseRoute(user?.role)
    return redirect(chosenRoute)
  } catch (error) {
    toast.error(error.detail)
    tokenUtils.removeTokenFromSession()
    return null
  }
}

export const activateAccountAction = async ({ request }) => {
  const data = await request.formData()

  const account = {
    uid: data.get('uid'),
    token: data.get('token'),
  }

  try {
    const response = await activateAccount(account)
    return response
  } catch (error) {
    return error
  }
}

export const forgotPasswordAction = async ({ request }) => {
  const formData = await request.formData()

  try {
    await forgotPassword(formData.get('email'))
    toast.success('Forgot Password email has been sent')
    return null
  } catch (error) {
    toast.error('Failed to sent email')
    return error
  }
}

export const resetPasswordConfirmAction = async ({ request }) => {
  const formData = await request.formData()
  const data = {
    uid: formData.get('uid'),
    token: formData.get('token'),
    password: formData.get('password'),
  }
  try {
    await resetPasswordConfirm(data)
    toast.success('Your Password has been reset successfully')
    return redirect('/login')
  } catch (error) {
    // eslint-disable-next-line no-prototype-builtins
    if (error?.data?.hasOwnProperty('password')) {
      toast.error(error.data?.password?.[0])
      return null
    }
    toast.error('Failed to reset password')
    return error
  }
}
