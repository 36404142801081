import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Table from './Table/Table'
import casesData from '../dummy_data/CasesData'

export default function CasesTable({
  type = 'minimal',
  title,
  setShowModal,
  screen,
  rounded,
  hasSearch,
}) {
  const [t] = useTranslation()

  const casesColumns = useMemo(() => [
    {
      Header: t('id'),
      accessor: 'id',
    },
    {
      Header: t('caseName'),
      accessor: 'caseName',
    },
    {
      Header: t('patient'),
      accessor: 'patientName',
    },
    {
      Header: t('status'),
      accessor: 'status',
    },
    {
      Header: t('dateCreated'),
      accessor: 'dateCreated',
    },
  ], [])

  const data = useMemo(() => casesData, [])

  return (
    <Table
      type={type}
      screen={screen}
      minHeight="xl:min-h-[610px] 2xl:min-h-[580px] xl:h-[610px] 2xl:h-[580px]"
      title={title}
      columns={casesColumns}
      data={data}
      setShowModal={setShowModal}
      pathKey="medicalStaffCases"
      enabledNavigation
      rounded={rounded}
      hasSearch={hasSearch}
    />
  )
}
