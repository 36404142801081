import React from 'react'
import { useTranslation } from 'react-i18next'
import SectionTitle from '../UI/SectionTitle'

export default function OncoRiste() {
  const [t] = useTranslation()

  return (
    <div className="flex flex-col" >
      <div className="mb-4">
        <SectionTitle
          text="Onco-RISTE"
          size="xl"
          marginBottom="mb-4"
        />
        <p className="text-gray-light text-justify">{t('oncoRisteDisclaimer')}</p>
      </div>
    </div >
  )
}
