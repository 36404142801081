import React from 'react'
import { useTranslation } from 'react-i18next'
import BasicCard from '../Cards/BasicCard'
import SectionTitle from '../UI/SectionTitle'
import Dot from '../UI/Dot'
import cn from '../../utils/cn'

export default function FinalResult({ result = true, percentage = 60 }) {
  const [t] = useTranslation()

  return (
    <div className="flex flex-col">
      <div className="mb-4">
        <SectionTitle
          text="finalResult"
          size="xl"
          marginBottom="mb-4"
        />
        <p className="text-gray-light text-justify">{t('finalResultDisclaimer')}</p>
      </div>
      <div
        className="flex justify-center self-center gap-4 w-full"
      >
        <BasicCard styles="h-[250px]">
          <div className="flex flex-col justify-center items-center gap-y-9 min-h-[50px] text-[32px] py-8">
            <div className="flex items-baseline justify-between gap-4">
              <Dot
                bgColor={result ? 'bg-positive' : 'bg-negative'}
                shadow={result ? 'shadow-positive' : 'shadow-negative'}
                size={'w-6 h-6'}
              />
              <p className={cn('uppercase', {
                'text-positive': result,
                'text-negative': !result,
              })}>
                {result ? t('positive') : t('negative')}
              </p>
            </div>
            <div className="flex justify-center gap-x-36">
              <p>{t('confidence')}</p>
              <p className="font-medium text-justify">{percentage}%</p>
            </div>
          </div>
        </BasicCard>
      </div>
    </div>)
}
