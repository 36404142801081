import { useQuery } from '@tanstack/react-query'

const useQueryData = ({
  initialData, query, enabled = true, select,
}) => {
  const { isLoading, data } = useQuery({
    ...query(),
    initialData,
    enabled,
    select,
  })

  return { isLoading, data }
}

export default useQueryData
