import { useTranslation } from 'react-i18next'
import { changeLanguage } from 'i18next'

export default function LangSelector() {
  const { i18n } = useTranslation()

  const handleLanguageChange = (event) => {
    changeLanguage(event.target.value)
    localStorage.setItem('lang', event.target.value)
  }

  return (
    <select
      value={i18n.language}
      onChange={handleLanguageChange}
      className="w-14 h-full text-center text-black bg-white rounded-xl py-2 outline-none border border-[#F5F5F5] shadow-langSelector"
    >
      <option value="en">EN</option>
      {/* <option value="el">ΕΛ</option> */}
      {/* <option value="zh">中文</option> */}
    </select>
  )
}
