const userUtils = {
  saveUser: (user) => {
    sessionStorage.setItem('user', JSON.stringify(user))
  },
  getUser: () => {
    const user = sessionStorage.getItem('user')
    return user ? JSON.parse(user) : null
  },
  removeUser: () => {
    sessionStorage.removeItem('user')
  },
}

export default userUtils
