import { LuClipboardEdit } from 'react-icons/lu'
import { Link } from 'react-router-dom'
import { FaBedPulse } from 'react-icons/fa6'
import oncoscreenLogo from '../../../assets/images/infinite-colon-logo.png'
import userUtils from '../../../utils/users-utils'
import HomePageCard from '../../../components/Cards/HomePageCard'

export default function DoctorManagerHomePage() {
  const authUser = userUtils.getUser()
  const greetings = 'Welcome back to your workplace. What would you like to do?'
  const pickupOptions = 'Pick up one of the following actions.'
  const fullName = `${authUser.first_name} ${authUser.last_name}`

  return (
   <>
    <div className = "flex flex-col items-center gap-5 px-10 mt-7">
    <img
     src={oncoscreenLogo}
     alt="Oncoscreen logo"
     width={185}
     height= {150}
     />
    <h1 className = "text-[60px] text-center font-semibold text-defaultText">Welcome {fullName}</h1>
    <h2 className = "text-2xl text-center text-defaultText">{greetings}</h2>
    <h3 className = "text-xl text-center text-gray-500">{pickupOptions}</h3>
    <div className = "flex flex-col gap-8 mt-10 mb-10 md:flex-row">
     <Link to = {'patients'}><HomePageCard title = "patients" description = "registerNewPatients"><FaBedPulse size = {50} className = "text-secondary"/></HomePageCard></Link>
     <Link to = {'cases'}><HomePageCard title = "cases" description = "createAndEditNewCases"><LuClipboardEdit size = {50} className = "text-secondary"/></HomePageCard></Link>
    </div>
   </div>
  </>
  )
}
