import { useTranslation } from 'react-i18next'
import cn from '../../utils/cn'

export default function SuccessMessage({
  title, description, className, children,
}) {
  const { t } = useTranslation()

  return (
    <div className={cn('py5 h-[300px] gap-5 flex flex-col text-center items-center justify-center', className)}>
      {children}
      <h2 className="text-2xl text-defaultText">{t(title)}</h2>
      <h3 className="text-gray-500">{t(description)}</h3>
    </div>
  )
}
