import {
  Form,
  useNavigate,
  useSubmit,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import React from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import LoginSignupWrapper from '../../components/Forms/LoginSignupWrapper'
import LoginInput from '../../components/Forms/Input'
import validationSchema from '../../utils/validation-schema'

export default function ForgotPasswordPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const submit = useSubmit()

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema.forgotPassword,
    onSubmit: (values) => {
      submit(values, { method: 'post' })
    },
  })

  return <LoginSignupWrapper isLogin>
    <div>
      <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-defaultText">
        {t('forgotPassword')}
      </h1>
      <p className="text-gray-dark text-sm mt-1">{t('forgotPasswordDescription')}</p>
    </div>
    <Form
      className="space-y-4 md:space-y-6 flex flex-col gap-5"
      onSubmit={formik.handleSubmit}
      method="post"
    >
      <LoginInput
        name="email"
        type="email"
        label={t('yourEmail')}
        value={formik.values.email}
        onChange={formik.handleChange}
        error={!!(formik.touched.email && formik.errors.email)}
        errorMessage={formik.errors.email} />
      <div className="flex flex-col gap-4">
        <button type="submit" className="w-full px-5 py-2.5 bg-primary hover:bg-secondary focus:outline-none rounded-xl font-medium text-xl text-pale text-center disabled:bg-primary/80">
          {t('sendEmail')}
        </button>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="w-full flex items-center justify-center gap-1 focus:outline-none text-defaultText text-center"
        ><FaChevronLeft />
          {`${t('backToSingIn')}`}
        </button>
      </div>
    </Form>
  </LoginSignupWrapper>
}
