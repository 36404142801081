import React from 'react'
import { AiOutlineCamera } from 'react-icons/ai'
import Avatar from '../UI/Avatar'
import FileInput from './FileInput'
import doctor from '../../assets/images/doctor.png'

export default function ImageInput({ handleChange, photoUrl, isEditing }) {
  return (
    <Avatar>
      <FileInput
        id="imageinput"
        onChange={(event) => handleChange(event)}
        accept=".jpg, .jpeg, .png"
      />
      {/* eslint-disable */}
      <label htmlFor="imageinput" className="absolute inset-0 flex items-center justify-center">
        <AiOutlineCamera className="z-10 text-white w-12 h-12" />
      </label>
      {/* eslint-enable */}
      <img
        id="profileImage"
        src={photoUrl || doctor}
        alt="Profile"
        width="100"
        className={isEditing ? 'blur-sm' : ''}
      />
    </Avatar>
  )
}
