import React from 'react'
import { useTranslation } from 'react-i18next'

export default function SectionTitle({
  text, styles, marginBottom = 'mb-10', size = '2xl',
}) {
  const [t] = useTranslation()

  return (
    <div className={styles}>
      <p
        className={`font-medium text-${size} ${marginBottom} antialiased tracking-wide`}
      >
        {t(`${text}`)}
      </p>
    </div>
  )
}
