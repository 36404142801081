import React from 'react'

const IconOncoCtc = () => (
      <svg className="-translate-y-4" id="Component_16_1" data-name="Component 16 – 1" xmlns="http://www.w3.org/2000/svg" width="41.256"
            height="69.584" viewBox="0 0 41.256 69.584">
            <path id="drop_-_Outline" data-name="drop - Outline"
                  d="M6.775-.748a.682.682,0,0,1,.577.316l1.742,2.72a2.862,2.862,0,1,1-4.635,0L6.2-.433A.682.682,0,0,1,6.775-.748ZM7.95,3.041,6.775,1.206,5.6,3.047l-.022.032,0,0a1.486,1.486,0,0,0-.291.884h0A1.494,1.494,0,0,0,6.775,5.459H6.8A1.494,1.494,0,0,0,8.268,3.966h0a1.486,1.486,0,0,0-.29-.882C7.968,3.069,7.959,3.055,7.95,3.041Z"
                  transform="translate(1.99 39.485)" fill="#d14a76" />
            <g id="Group_2736" data-name="Group 2736" transform="translate(0 0)">
                  <g id="Group_2701" data-name="Group 2701" transform="translate(0 28.983)">
                        <path id="Rectangle_919" data-name="Rectangle 919"
                              d="M1.845,1.845V5.536H15.687V1.845H1.845M0,0H17.532V7.382H0Z" transform="translate(0 0)"
                              fill="#d14a76" />
                        <path id="Union_10" data-name="Union 10"
                              d="M6.459,33.218c2.544,0,4.614-1.759,4.614-3.922V1.845H1.845V29.3c0,2.162,2.07,3.922,4.614,3.922m0,1.845C2.892,35.064,0,32.482,0,29.3V0H12.918V29.3C12.918,32.482,10.026,35.064,6.459,35.064Z"
                              transform="translate(2.307 5.536)" fill="#d14a76" />
                        <path id="Path_2651" data-name="Path 2651"
                              d="M-4.246,9.726a.918.918,0,0,1-.446-.116.922.922,0,0,1-.36-1.254A12.321,12.321,0,0,1-1.491,4.315a8.033,8.033,0,0,1,2.706-.78,3.126,3.126,0,0,0,1.826-.7A9.557,9.557,0,0,0,5.394.081.923.923,0,0,1,7.109.763a10.675,10.675,0,0,1-2.9,3.5,4.987,4.987,0,0,1-2.754,1.1,6.163,6.163,0,0,0-2.086.583,10.484,10.484,0,0,0-2.8,3.3A.923.923,0,0,1-4.246,9.726Z"
                              transform="translate(7.705 18.725)" fill="#d14a76" />
                  </g>
                  <path id="eyedropper"
                        d="M1.365,24.2v2.3A1.34,1.34,0,0,0,2.7,27.84H4.558a8.3,8.3,0,0,0,5.909-2.448L21.619,14.241l3.1,3.115L25.981,16.1l-3.105-3.118.071-.072h0l3.516-3.516a4.714,4.714,0,0,0,0-6.667l-.222-.222a4.714,4.714,0,0,0-6.667,0L16,6.079,13.117,3.186,11.857,4.441l2.9,2.906L3.813,18.287A8.3,8.3,0,0,0,1.365,24.2ZM20.83,3.762a2.936,2.936,0,0,1,4.153,0l.222.222a2.939,2.939,0,0,1,0,4.153l-3.41,3.41L17.42,7.172ZM16.068,8.546l4.366,4.365L16.59,16.754v-.027h-8.7Zm-9.96,9.96h8.73L9.21,24.134a6.537,6.537,0,0,1-4.652,1.927H3.143V24.2A6.537,6.537,0,0,1,5.07,19.544Z"
                        transform="matrix(0.966, 0.259, -0.259, 0.966, 14.652, -1.439)" fill="#d14a76" />
            </g>
      </svg>

)

export default IconOncoCtc
