import { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { LuLogOut } from 'react-icons/lu'
import { useTranslation } from 'react-i18next'
import MainNavigation from './UI/MainNavigation'
import ModalWrapper from './Modals/ModalWrapper'
import ModalActionContent from './Modals/ModalActionContent'
import useLogout from '../hooks/useLogout'
import { responseInterceptor } from '../utils/axios-instances'

export default function RootRouter() {
  const [showModal, setShowModal] = useState()
  const { logout } = useLogout()
  const { t } = useTranslation()

  const openModalHandler = () => {
    setShowModal(true)
  }

  const closeModalHandler = () => {
    setShowModal(false)
  }

  useEffect(() => {
    responseInterceptor({ logout, toastMessage: t('sessionHasExpired') })
  })

  return (
    <>
      <MainNavigation onOpenModal = {openModalHandler}/>
      <main>
        <Outlet />
      </main>
      {showModal && (
       <ModalWrapper onCloseModal={closeModalHandler} type = "close">
        <div className = "px-6 py-[3rem]">
         <ModalActionContent title = "logout"
           description = "areYouSureYouWantLogout"
           type = "logout"
           buttonLabel = "logout"
           icon = {<LuLogOut size = {50}/>}
           logoutHandler = {logout}
           closeModalHandler = {closeModalHandler}/>
        </div>
       </ModalWrapper>
      )}
    </>
  )
}
