import {
  faHome,
  faNotesMedical,
  faUserDoctor,
  faBedPulse,
  faClipboard,
  faList,
} from '@fortawesome/free-solid-svg-icons'

const menuItemsMedicalStaff = [
  { title: 'home', icon: faHome, route: 'medical-staff' },
  { title: 'cases', icon: faNotesMedical, route: 'medical-staff/cases' },
  { title: 'rules', icon: faList, route: 'medical-staff/rules/' },
]

const menuItemsLabTechnician = [
  { title: 'home', icon: faHome, route: 'lab-technician' },
  { title: 'patients', icon: faBedPulse, route: 'lab-technician/patients' },
]

const menuItemsMaintainer = [
  { title: 'home', icon: faHome, route: 'maintainer' },
]

const menuItemsDoctorManager = [
  { title: 'home', icon: faHome, route: 'doctor-manager' },
  { title: 'patients', icon: faBedPulse, route: 'doctor-manager/patients' },
  { title: 'cases', icon: faClipboard, route: 'doctor-manager/cases' },
]

const mobileMenuItemsMedicalStaff = [
  { title: 'home', icon: faHome, route: 'medical-staff' },
  { title: 'cases', icon: faNotesMedical, route: 'medical-staff/cases' },
  { title: 'rules', icon: faList, route: 'medical-staff/rules/' },
  { title: 'profile', icon: faUserDoctor, route: 'medical-staff/profile' },
]

const mobileMenuItemsLabTechnician = [
  { title: 'home', icon: faHome, route: 'lab-technician' },
  { title: 'patients', icon: faBedPulse, route: 'lab-technician/patients' },
  { title: 'profile', icon: faUserDoctor, route: 'lab-technician/profile' },
]

const mobileMenuItemsMaintainer = [
  { title: 'home', icon: faHome, route: 'maintainer' },
  { title: 'profile', icon: faUserDoctor, route: 'maintainer/profile' },
]

const mobileMenuItemsDoctorManager = [
  { title: 'home', icon: faHome, route: 'doctor-manager' },
  { title: 'patients', icon: faBedPulse, route: 'doctor-manager/patients' },
  { title: 'cases', icon: faClipboard, route: 'doctor-manager/cases' },
  { title: 'profile', icon: faUserDoctor, route: 'doctor-manager/profile' },
]

const profileRoutes = [
  { maintainer: 'maintainer/profile' },
  { medicalStaff: 'medical-staff/profile' },
  { medicalStaff: 'doctor-manager/profile' },
]

const medicalStaffPatientRoutes = [
  { title: 'earlyDiagnostics', route: 'early-diagnostics' },
  { title: 'reports', route: 'reports' },
  { title: 'finals', route: 'finals' }]

const labTechnicianPatientRoutes = [
  { title: 'lipoproteins', route: 'lipoproteins' },
  { title: 'metabolites', route: 'metabolites' },
  { title: 'glycoproteins', route: 'glycoproteins' },
]

const navMenu = {
  menuItemsMedicalStaff,
  mobileMenuItemsMedicalStaff,
  mobileMenuItemsMaintainer,
  menuItemsMaintainer,
  profileRoutes,
  mobileMenuItemsDoctorManager,
  menuItemsDoctorManager,
  menuItemsLabTechnician,
  mobileMenuItemsLabTechnician,
  medicalStaffPatientRoutes,
  labTechnicianPatientRoutes,
}

export default navMenu
