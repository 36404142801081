const metaboliteData = [
  {
    parameter: '2-Aminobutyric acid',
    value: 0.04,
    unit: 'mM',
    lod: 0.05,
    highThreshold: 0.10,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: '2-Hydroxybutyric acid',
    value: 0.18,
    unit: 'mM',
    lod: 0.15,
    highThreshold: 0.17,
    isNormal: false,
    lowThreshold: 0,
  },
  {
    parameter: '2-Oxoglutaric acid',
    value: 0.025,
    unit: 'mM',
    lod: 0.02,
    highThreshold: 0.03,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: '3-Hydroxybutyric acid',
    value: 0.27,
    unit: 'mM',
    lod: 0.02,
    highThreshold: 0.26,
    isNormal: false,
    lowThreshold: 0,
  },
  {
    parameter: 'Acetic acid',
    value: 0.05,
    unit: 'mM',
    lod: 0.01,
    highThreshold: 0.06,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Acetoacetic acid',
    value: 0.015,
    unit: 'mM',
    lod: 0.01,
    highThreshold: 0.02,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Acetone',
    value: 0.04,
    unit: 'mM',
    lod: 0.01,
    highThreshold: 0.06,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Alanine',
    value: 0.4,
    unit: 'mM',
    lod: 0.02,
    highThreshold: 0.64,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Asparagine',
    value: 0.06,
    unit: 'mM',
    lod: 0.05,
    highThreshold: 0.08,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Choline',
    value: 0.055,
    unit: 'mM',
    lod: 0.05,
    highThreshold: 0.06,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Citric acid',
    value: 0.2,
    unit: 'mM',
    lod: 0.03,
    highThreshold: 0.21,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Creatine',
    value: 0.06,
    unit: 'mM',
    lod: 0.01,
    highThreshold: 0.07,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Creatinine',
    value: 0.08,
    unit: 'mM',
    lod: 0.01,
    highThreshold: 0.14,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'D-Galactose',
    value: 0.09,
    unit: 'mM',
    lod: 0.11,
    highThreshold: 0.11,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Dimethylsulfone',
    value: 0.015,
    unit: 'mM',
    lod: 0.01,
    highThreshold: 0.02,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Ethanol',
    value: 0.5,
    unit: 'mM',
    lod: 0.1,
    highThreshold: 0.82,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Formic acid',
    value: 0.025,
    unit: 'mM',
    lod: 0.02,
    highThreshold: 0.03,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Glucose',
    value: 2.5,
    unit: 'mM',
    lod: 0.54,
    highThreshold: 6.08,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Glutamic Acid',
    value: 0.1,
    unit: 'mM',
    lod: 0.05,
    highThreshold: 0.24,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Glutamine',
    value: 0.5,
    unit: 'mM',
    lod: 0.02,
    highThreshold: 0.83,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Glycerol',
    value: 0.3,
    unit: 'mM',
    lod: 0.08,
    highThreshold: 0.44,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Glycine',
    value: 0.2,
    unit: 'mM',
    lod: 0.01,
    highThreshold: 0.44,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Histidin',
    value: 0.1,
    unit: 'mM',
    lod: 0.02,
    highThreshold: 0.16,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Isoleucine',
    value: 0.05,
    unit: 'mM',
    lod: 0.03,
    highThreshold: 0.11,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Lactic acid',
    value: 5,
    unit: 'mM',
    lod: 0.03,
    highThreshold: 7.14,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Leucine',
    value: 0.15,
    unit: 'mM',
    lod: 0.01,
    highThreshold: 0.20,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Lysine',
    value: 0.25,
    unit: 'mM',
    lod: 0.04,
    highThreshold: 0.29,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Methionine',
    value: 0.1,
    unit: 'mM',
    lod: 0.05,
    highThreshold: 0.13,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'N,N-Dimethylglycine',
    value: 0.01,
    unit: 'mM',
    lod: 0.01,
    highThreshold: 0.01,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Ornithine',
    value: 0.1,
    unit: 'mM',
    lod: 0.02,
    highThreshold: 0.16,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Phenylalanine',
    value: 0.05,
    unit: 'mM',
    lod: 0.03,
    highThreshold: 0.07,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Proline',
    value: 0.5,
    unit: 'mM',
    lod: 0.05,
    highThreshold: 0.59,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Pyruvic acid',
    value: 0.05,
    unit: 'mM',
    lod: 0.03,
    highThreshold: 0.07,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Sarcosine',
    value: 0.01,
    unit: 'mM',
    lod: 0.01,
    highThreshold: 0.01,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Succinic acid',
    value: 0.01,
    unit: 'mM',
    lod: 0.01,
    highThreshold: 0.01,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Threonine',
    value: 0.2,
    unit: 'mM',
    lod: 0.04,
    highThreshold: 0.24,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Trimethylamine-N-oxide',
    value: 0.07,
    unit: 'mM',
    lod: 0.08,
    highThreshold: 0.08,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Tyrosine',
    value: 0.07,
    unit: 'mM',
    lod: 0.03,
    highThreshold: 0.08,
    isNormal: true,
    lowThreshold: 0,
  },
  {
    parameter: 'Valine',
    value: 0.3,
    unit: 'mM',
    lod: 0.03,
    highThreshold: 0.35,
    isNormal: true,
    lowThreshold: 0,
  },
]

export default metaboliteData
