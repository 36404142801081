import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { BsUpload } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import SingleFileUploadWithProgress from './SingleFileUploadWithProgress'

export default function SingleFileUploadField() {
  const { t } = useTranslation()
  const [files, setFiles] = useState([])
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      const firstAcceptedFile = acceptedFiles[0]
      setFiles([{ file: firstAcceptedFile, errors: [] }])
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
    },
    multiple: false,
  })

  return (
    <div>
      <div className="flex items-baseline justify-between mb-6">
        <h2 className="text-2xl font-medium">{t('uploadFile')}</h2>
        <p className="text-xs text-gray-light">
          Accepted File types: XLS, …, max Size: 10MB
        </p>
      </div>
      <div
        {...getRootProps()}
        className="px-24 flex flex-col items-center justify-center border border-dashed border-gray-light rounded-[10px] h-[132px]"
      >
        <input {...getInputProps()} />
        <div className={`${isDragActive ? 'bg-red bg-opacity-80' : ''}`}>
          <div className="flex flex-col items-center text-center">
            <BsUpload className="w-8 h-6 text-defaultText" />
            <div className="text-lg flex items-center gap-1 ">
              <p className="my-3 text-defaultText">
                Drag & Drop your files here or
              </p>
              <button type="button" className="text-secondary underline">Browse Files</button>
            </div>
          </div>
        </div>
      </div>
      {files.map((fileWrapper, idx) => (
        <SingleFileUploadWithProgress
          key={idx}
          id={idx}
          file={fileWrapper.file}
          setFiles={setFiles} />
      ))}
    </div>
  )
}
