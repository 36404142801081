import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import WhiteBox from '../UI/WhiteBox'
import Table from '../Table/Table'
import ModalUploadFile from '../Modals/ModalUploadFile'
import UploadFileButton from '../UI/UploadFileButton'

export default function PatientsWithFilesTable({
  patients = [], type = 'minimal', title, rounded,
}) {
  const [t] = useTranslation()
  const [showUploadFileModal, setShowUploadFileModal] = useState(false)
  const [uploadDetails, setUploadDetails] = useState(null)

  const onUploadFile = (patientId, fileType) => {
    setUploadDetails({
      patientId,
      fileType,
    })
    setShowUploadFileModal(true)
  }

  const onCloseUploadFileModal = () => {
    setShowUploadFileModal(false)
    setUploadDetails(null)
  }

  const columns = [
    {
      Header: t('id'),
      accessor: 'id',
    },
    { Header: t('firstName'), accessor: 'name' },
    { Header: t('lastName'), accessor: 'surname' },
    {
      Header: t('dateCreated'),
      accessor: 'dateCreated',
      Cell: ({ value }) => <span>{moment(value).format('DD/MM/YYYY')}</span>,
    },
    {
      Header: 'ONCO NMR',
      Cell: ({ row: { original } }) => {
        if (original.id === 'P001' || original.id === 'P003') {
          return <span className="text-blue-600">Result.xlsx</span>
        }
        return <UploadFileButton
            onClick={(e) => {
              e.stopPropagation()
              onUploadFile(original.id, 'oncoNmr')
            }}
        />
      },

    },
    {
      Header: 'ONCO CTC',
      Cell: ({ row: { original } }) => {
        if (original.id === 'P001' || original.id === 'P003' || original.id === 'P006') {
          return <span className="text-blue-600">Result.xlsx</span>
        }

        return <UploadFileButton
          onClick={(e) => {
            e.stopPropagation()
            onUploadFile(original.id, 'oncoCtc')
          }}
        />
      },
    },
  ]

  const data = useMemo(() => patients, [patients])

  return (
    <>
      {showUploadFileModal && <ModalUploadFile uploadDetails={uploadDetails} onClose={onCloseUploadFileModal} />}
      <WhiteBox>
        <Table
          type={type}
          title={title}
          columns={columns}
          data={data}
          pathKey="doctorManagerPatients"
          rounded={rounded}
          rowsPerPage={10}
          screen="CasesPage"
          enabledNavigation={true}
        />
      </WhiteBox>
    </>
  )
}
